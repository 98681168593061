import { Box, MenuItem, Typography } from '@mui/material';
import { ReactElement, useEffect } from 'react';

import dayjs from 'dayjs';
import {
  TIME_12HRS_FORMAT,
  TIME_24HRS_FORMAT,
} from '../../../constans/DateRelatedContants';
import { RHFSelect } from './RHFSelect';
import { COLORS } from '../../../theme/pallete';
import { TYPOGRAPHY } from '../../../theme/typography';
import { useFormContext } from 'react-hook-form';

const availableTimes = [
  // AM times
  '12:00 am',
  '12:15 am',
  '12:30 am',
  '12:45 am',

  '1:00 am',
  '1:15 am',
  '1:30 am',
  '1:45 am',

  '2:00 am',
  '2:15 am',
  '2:30 am',
  '2:45 am',

  '3:00 am',
  '3:15 am',
  '3:30 am',
  '3:45 am',

  '4:00 am',
  '4:15 am',
  '4:30 am',
  '4:45 am',

  '5:00 am',
  '5:15 am',
  '5:30 am',
  '5:45 am',

  '6:00 am',
  '6:15 am',
  '6:30 am',
  '6:45 am',

  '7:00 am',
  '7:15 am',
  '7:30 am',
  '7:45 am',

  '8:00 am',
  '8:15 am',
  '8:30 am',
  '8:45 am',

  '9:00 am',
  '9:15 am',
  '9:30 am',
  '9:45 am',

  '10:00 am',
  '10:15 am',
  '10:30 am',
  '10:45 am',

  '11:00 am',
  '11:15 am',
  '11:30 am',
  '11:45 am',

  '12:00 pm',
  '12:15 pm',
  '12:30 pm',
  '12:45 pm',

  // PM times
  '1:00 pm',
  '1:15 pm',
  '1:30 pm',
  '1:45 pm',

  '2:00 pm',
  '2:15 pm',
  '2:30 pm',
  '2:45 pm',

  '3:00 pm',
  '3:15 pm',
  '3:30 pm',
  '3:45 pm',

  '4:00 pm',
  '4:15 pm',
  '4:30 pm',
  '4:45 pm',

  '5:00 pm',
  '5:15 pm',
  '5:30 pm',
  '5:45 pm',

  '6:00 pm',
  '6:15 pm',
  '6:30 pm',
  '6:45 pm',

  '7:00 pm',
  '7:15 pm',
  '7:30 pm',
  '7:45 pm',

  '8:00 pm',
  '8:15 pm',
  '8:30 pm',
  '8:45 pm',

  '9:00 pm',
  '9:15 pm',
  '9:30 pm',
  '9:45 pm',

  '10:00 pm',
  '10:15 pm',
  '10:30 pm',
  '10:45 pm',

  '11:00 pm',
  '11:15 pm',
  '11:30 pm',
  '11:45 pm',
];

const getNonAvailableTimes = (minTime: string | undefined, maxTime: string | undefined): string[] => {
  let nonAvailableTimes: string[] = [];
  if (minTime) {
    const minTimeIndex = availableTimes.indexOf(minTime);
    nonAvailableTimes = availableTimes.slice(0, minTimeIndex);
  }
  if (maxTime) {
    const maxTimeIndex = availableTimes.indexOf(maxTime);
    nonAvailableTimes = nonAvailableTimes.concat(availableTimes.slice(maxTimeIndex));
  }

  return nonAvailableTimes;
}

export const CustomTimePicker = ({
  name,
  minTime = undefined,
  maxTime = undefined,
  dataCy = undefined,
}: {
  name: string,
  minTime?: string;
  maxTime?: string;
  dataCy?: string;
}): ReactElement => {

  const { watch, setValue } = useFormContext();
  const selectedValue = dayjs(watch(name), TIME_24HRS_FORMAT).format(TIME_12HRS_FORMAT);
  const nonAvailableTimes = getNonAvailableTimes(minTime, maxTime);

  useEffect(() => {
    if(nonAvailableTimes.includes(selectedValue) && selectedValue !== 'none') {
      setValue(name, 'none');
    }
  }, [name, nonAvailableTimes, selectedValue, setValue])

  return (
    <Box
      sx={{
        minHeight: '48px',
        '.MuiFormControl-root': {
          // display: 'none',
          height: '48px',
        },
      }}
    >
      <RHFSelect
        name={name}
        dataCy={dataCy}
        // defaultValue={defaultTime || ''}
        valueMiddleware={(value: string): string =>
          value === 'none' || !value
            ? 'none'
            : dayjs(value, TIME_24HRS_FORMAT).format(TIME_12HRS_FORMAT)
        }
        onChangeMiddleware={(
          value: string,
          onChangeFn: (newValue: string) => void,
        ): void => {
          onChangeFn(dayjs(value, TIME_12HRS_FORMAT).format(TIME_24HRS_FORMAT));
        }}
        sx={{
          '.MuiFormControl-root': {
            display: 'none',
          },

          '.MuiSelect-select': {
            height: '48px!important',
            backgroundColor: COLORS.GREY_MEDIUM_5,
            padding: '0 0 0 10px!important',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '10px',
          },
        }}
      >
        <MenuItem key="none" value="none">
          <Typography
            sx={{
              color: COLORS.GREY_MEDIUM_1,
              ...TYPOGRAPHY.formField,
            }}
          >
            Select
          </Typography>
        </MenuItem>
        {availableTimes.map((time) => (
          <MenuItem
            disabled={nonAvailableTimes.includes(time)}
            key={time} 
            value={time}
          >
            {time}
          </MenuItem>
        ))}
      </RHFSelect>
    </Box>
  );
};
