import { Typography } from '@mui/material';
import { COLORS } from '../../../theme/pallete';
import React from 'react';
import { TYPOGRAPHY } from '../../../theme/typography';

const FieldLabel: React.FC<{ label: string }> = ({ label }) => {
  return (
    <Typography
      sx={{
        ...TYPOGRAPHY.tinyText,
        color: COLORS.GREY_MEDIUM_1,
        mb: '5px',
        height: '18px',
      }}
    >
      {label}
    </Typography>
  );
};

export default FieldLabel;
