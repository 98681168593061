import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { BookRideFieldNames, BookRideFormFields } from '../../types';

import { AddressTypes, LocationDetailsType } from '../../../../shared.types';
import { useFormContext } from 'react-hook-form';

import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { getLocationFromGooglePlacesId } from '../../../../utils/googlePlacesUtils';
import { createPortal } from 'react-dom';
import FullScreenLoader from './FullScreenLoader';
import { SchemaContext } from '../../../FormStateWrapper';

type SearchParamsType = {
  rideType?: string;
  fromAddressPlaceId: string;
  fromAddressDescription?: string;
  toAddressPlaceId?: string;
  toAddressDescription?: string;
  pickUpDate?: string;
  pickUpTime?: string;
  returnPickUpDate?: string;
  returnPickUpTime?: string;
  duration?: string;
};

const readUrlSearchParams = (
  searchParams: URLSearchParams,
): SearchParamsType => {
  const fromAddressDescription = searchParams.get('fromAddressDescription');
  const toAddressDescription = searchParams.get('toAddressDescription');

  return {
    rideType: searchParams.get('rideType') || undefined,
    fromAddressPlaceId: searchParams.get('fromAddressPlaceId') || undefined,
    fromAddressDescription: fromAddressDescription
      ? decodeURIComponent(fromAddressDescription)
      : undefined,
    toAddressPlaceId: searchParams.get('toAddressPlaceId') || undefined,
    toAddressDescription: toAddressDescription
      ? decodeURIComponent(toAddressDescription)
      : undefined,
    pickUpDate: searchParams.get('pickUpDate') || undefined,
    pickUpTime: searchParams.get('pickUpTime') || undefined,
    returnPickUpDate: searchParams.get('returnPickUpDate') || undefined,
    returnPickUpTime: searchParams.get('returnPickUpTime') || undefined,
    duration: searchParams.get('duration') || undefined,
  } as SearchParamsType;
};

type Step1BookRideFormFields = Pick<
  BookRideFormFields,
  | 'TRIP_TYPE'
  | 'PICKUP_ADDRESS'
  | 'DROPOFF_ADDRESS'
  | 'PICKUP_DATE'
  | 'PICKUP_TIME'
  | 'RETURN_PICKUP_DATE'
  | 'RETURN_PICKUP_TIME'
  | 'ESTIMATED_TIME_IN_HOURS'
>;

type OmittedSearchParamsType = Omit<
  SearchParamsType,
  'fromAddressDescription' | 'toAddressDescription'
>;

type KeyOfOmittedSearchParamsType = keyof OmittedSearchParamsType;

const WrapperParamsFromUrl: FC<PropsWithChildren> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const { schema } = useContext(SchemaContext);
  const urlQueryParams = readUrlSearchParams(searchParams);
  const [runOnce, setRunOnce] = useState(false);
  const [isStepOneValid, setStepOneValid] = useState<boolean>();
  const {
    setValue,
    formState: { touchedFields, dirtyFields },
  } = useFormContext();
  const match = useMatch('/pass/:code/*');
  const navigate = useNavigate();

  const theBigCall = useCallback(() => {
    let pickupLocation: LocationDetailsType;
    let destLocation: LocationDetailsType;
    const remapUrlQueryParamsKeys = async (
      obj: SearchParamsType,
    ): Promise<Step1BookRideFormFields> => {
      const pLocation = await getLocationFromGooglePlacesId(
        obj.fromAddressPlaceId,
        obj.fromAddressDescription as string,
        AddressTypes.PickUp,
      );
      if (pLocation) pickupLocation = pLocation;

      let dLocation: LocationDetailsType | null;
      if (obj.toAddressPlaceId && obj.toAddressDescription) {
        dLocation = await getLocationFromGooglePlacesId(
          obj.toAddressPlaceId,
          obj.toAddressDescription,
          AddressTypes.DropOff,
        );
        if (dLocation) destLocation = dLocation;
      }
      const modifiedObj = { ...obj };
      delete modifiedObj.fromAddressDescription;
      delete modifiedObj.toAddressDescription;

      const keyMap: Record<
        KeyOfOmittedSearchParamsType,
        keyof Step1BookRideFormFields
      > = {
        rideType: 'TRIP_TYPE',
        fromAddressPlaceId: 'PICKUP_ADDRESS',
        toAddressPlaceId: 'DROPOFF_ADDRESS',
        pickUpDate: 'PICKUP_DATE',
        pickUpTime: 'PICKUP_TIME',
        returnPickUpDate: 'RETURN_PICKUP_DATE',
        returnPickUpTime: 'RETURN_PICKUP_TIME',
        duration: 'ESTIMATED_TIME_IN_HOURS',
      };
      const toReturn = Object.fromEntries(
        Object.entries(modifiedObj).map(([key, value]) => [
          keyMap[key as KeyOfOmittedSearchParamsType] || key,
          key === 'fromAddressPlaceId'
            ? pLocation
            : key === 'toAddressPlaceId'
              ? dLocation
              : key === 'rideType'
                ? parseInt(value)
                : value,
        ]),
      ) as unknown as Step1BookRideFormFields;
      return toReturn;
    };

    const setStep1State = (): void => {
      const paramsValue = readUrlSearchParams(searchParams);

      async function setRideDataFromUrl(): Promise<void> {
        setValue(
          BookRideFieldNames.TRIP_TYPE,
          parseInt(paramsValue.rideType as string),
        );

        if (pickupLocation) {
          setValue(BookRideFieldNames.PICKUP_ADDRESS, pickupLocation);
        }

        if (destLocation) {
          setValue(BookRideFieldNames.DROPOFF_ADDRESS, destLocation);
        }

        setValue(BookRideFieldNames.PICKUP_DATE, paramsValue.pickUpDate);
        setValue(BookRideFieldNames.PICKUP_TIME, paramsValue.pickUpTime);

        if (paramsValue.returnPickUpDate)
          setValue(
            BookRideFieldNames.RETURN_PICKUP_DATE,
            paramsValue.returnPickUpDate,
          );
        if (paramsValue.returnPickUpTime)
          setValue(
            BookRideFieldNames.RETURN_PICKUP_TIME,
            paramsValue.returnPickUpTime,
          );
        if (paramsValue.duration)
          setValue(
            BookRideFieldNames.ESTIMATED_TIME_IN_HOURS,
            paramsValue.duration,
          );
      }

      if (pickupLocation) {
        if (paramsValue.rideType && paramsValue.fromAddressPlaceId)
          setRideDataFromUrl();
      }
    };

    const theCall = async (): Promise<void> => {
      let remapped: unknown;
      try {
        remapped = await remapUrlQueryParamsKeys(urlQueryParams);
      } catch (e) {
        const passId = match?.params.code;
        if (passId) window.location.href = `/pass/${passId}/step1`;
        else window.location.href = '/book-ride/step1';
        console.error(e);
      }

      if (remapped) {
        Promise.all(
          Object.keys((remapped as Step1BookRideFormFields) ?? {}).map(
            (key) => {
              return schema.validateAt(key, remapped);
            },
          ),
        )
          .then(() => {
            return true;
          })
          .catch((e) => {
            const passId = match?.params.code;
            if (passId) window.location.href = `/pass/${passId}/step1`;
            else window.location.href = '/book-ride/step1';
            console.log(e);
          });
      }
    };

    theCall().then(() => {
      setStep1State();
      setStepOneValid(true);
    });
  }, [match?.params.code, schema, searchParams, setValue, urlQueryParams]);

  useEffect(() => {
    if (window.location.pathname.includes('step1') && !window.location.search) {
      setStepOneValid(true);
      return;
    }

    const flowComesFromStep1 =
      Object.keys(dirtyFields).concat(Object.keys(touchedFields)).length > 0;

    if (!runOnce) {
      if (
        window.location.pathname.includes('step3') &&
        window.location.search
      ) {
        navigate('/book-ride/step2' + window.location.search);
        return;
      }
      setRunOnce(true);
      if (!flowComesFromStep1) {
        theBigCall();
      } else {
        setStepOneValid(true);
      }
    }
  }, [theBigCall, dirtyFields, touchedFields, runOnce, navigate]);

  if (!isStepOneValid) {
    return <>{createPortal(<FullScreenLoader />, document.body)}</>;
  }

  return <>{children}</>;
};

export default WrapperParamsFromUrl;
