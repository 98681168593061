import { Box, Typography } from '@mui/material';
import { LOGO_ALT, LOGO_IMAGE } from '../../../../shared.config';
import { TYPOGRAPHY } from '../../../../theme/typography';
import React from 'react';

const FullScreenLoader = (): React.ReactNode => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 3,
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@keyframes spin': {
          to: {
            transform: 'rotate(1turn)',
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          src={LOGO_IMAGE}
          alt={LOGO_ALT}
          style={{ width: '250px', height: '80px' }}
        />

        <img
          src="/assets/icons/loader-spinner.svg"
          alt="loader spinner"
          style={{
            width: '57px',
            height: '57px',
            marginTop: '25px',
            animation: 'spin 1s linear infinite',
          }}
        />

        <Typography
          sx={{
            ...TYPOGRAPHY.title2,
            mt: '25px',
          }}
        >
          Finding you the best ride
        </Typography>

        <Typography
          sx={{
            ...TYPOGRAPHY.paragraph,
          }}
        >
          This might take a minute..
        </Typography>
      </Box>
    </Box>
  );
};

export default FullScreenLoader;
