import { Typography } from '@mui/material';
import { TYPOGRAPHY } from '../../theme/typography';
import { COLORS } from '../../theme/pallete';
import React, { ReactElement } from 'react';

const OrDivider = (): ReactElement => {
  return (
    <Typography
      sx={{
        display: 'flex',
        alignItems: 'center',
        my: '20px',
        ...TYPOGRAPHY.tinyText,
        '&:before, &:after': {
          content: "''",
          width: '100%',
          height: '1px',
          backgroundColor: COLORS.GREY_LIGHT_1,
        },
      }}
    >
      <span
        style={{
          margin: '0 30px',
        }}
      >
        or
      </span>
    </Typography>
  );
};

export default OrDivider;
