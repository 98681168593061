import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { AuthProvider } from 'auth/JwtContext';
import Router from 'Router';
import { AppWhiteLabelingConfiguration } from 'shared.types';
import { ReactElement } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SnackbarProvider from './components/snackbar';
import { ProgramPassProvider } from 'pages/ProgramPassContext';
import { StripeProvider } from 'pages/StripeContext';

function App({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  whiteLabelingConfig,
}: {
  whiteLabelingConfig: AppWhiteLabelingConfiguration;
}): ReactElement {

  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <SnackbarProvider>
          <AuthProvider>
            <ProgramPassProvider>
              <StripeProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Router />
                </LocalizationProvider>
              </StripeProvider>
            </ProgramPassProvider>
          </AuthProvider>
        </SnackbarProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}

export default App;
