import { Button, SxProps } from '@mui/material';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { TYPOGRAPHY } from '../../theme/typography';
import { COLORS } from '../../theme/pallete';

interface Props {
  title: string;
  onClick?: () => void;
  type?: 'submit' | 'button';
  additionalSx?: SxProps;
  variant?: 'filled' | 'outlined' | 'disabled';
  to?: string;
  startIcon?: React.ReactNode;
  disabled?: boolean;
  disableRipple?: boolean;
}

type StyleTypes = { backgroundColor: string; color: string; border?: string };

const AppButton: React.FC<Props> = ({
  title,
  type,
  variant = 'filled',
  additionalSx: sx,
  onClick,
  to,
  startIcon,
  disabled = false,
  ...props
}) => {
  const navigator = useNavigate();

  const colorsByVariant = useMemo((): StyleTypes => {
    const filledStyles: StyleTypes = {
      ...TYPOGRAPHY.mainButton,
      backgroundColor: COLORS.BLACK_DARK_2,
      color: TYPOGRAPHY.mainButton.color,
    };
    const outlinedStyles: StyleTypes = {
      border: `1px solid ${COLORS.GREY_LIGHT_2}`,
      backgroundColor: 'transparent',
      color: 'black',
    };

    const disabledStyles: StyleTypes = {
      backgroundColor: COLORS.GREY_LIGHT_3,
      color: '#bebebe',
    };

    switch (variant) {
      case 'filled':
        return filledStyles;
      case 'outlined':
        return outlinedStyles;
      case 'disabled':
        return disabledStyles;
      default:
        return filledStyles;
    }
  }, [variant]);

  const { fontFamily, fontSize } = TYPOGRAPHY.mainButton;

  return (
    <Button
      onClick={to ? (): void => navigator(to) : onClick}
      variant="contained"
      type={type}
      disabled={disabled}
      sx={{
        width: '100%',
        height: '48px',
        borderRadius: '20px',
        padding: '12px',
        fontWeight: 600,
        textTransform: 'none',
        boxShadow: 'none!important',
        '&:hover': {
          backgroundColor:
            variant === 'outlined' ? COLORS.GREY_LIGHT_4 : COLORS.GREY_DARK_5,
          borderColor: variant === 'outlined' ? 'transparent' : '',
        },
        pointerEvents: variant === 'disabled' ? 'none' : 'auto',
        backgroundColor: colorsByVariant.backgroundColor,
        border: colorsByVariant.border,
        color: colorsByVariant.color,
        fontFamily,
        fontSize,
        ...sx,
      }}
      startIcon={startIcon}
      {...props}
    >
      {title && <span className="buttonText"> {title}</span>}
    </Button>
  );
};
export default AppButton;
