import { Box, Stack, Typography } from '@mui/material';
import { TYPOGRAPHY } from '../../../../theme/typography';
import EditButton from './EditButton';
import React, { ReactElement } from 'react';
import TripSchedule from '../../../../components/shared/TripSchedule';
import { useFormContext } from 'react-hook-form';
import { BookRideFieldNames, TripTypes } from '../../types';
import dayjs from 'dayjs';
import {
  TIME_12HRS_FORMAT,
  TIME_24HRS_FORMAT,
} from '../../../../constans/DateRelatedContants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProgramPassContext } from 'pages/ProgramPassContext';

const Trip = (): ReactElement => {
  const { watch } = useFormContext();
  const location = useLocation();

  const [
    tripType,
    pickupAddress,
    dropoffAddress,
    pickupDate,
    pickupTime,
    returnPickupDate,
    returnPickupTime,
    estimatedTimeInHours,
  ] = watch([
    BookRideFieldNames.TRIP_TYPE,
    BookRideFieldNames.PICKUP_ADDRESS,
    BookRideFieldNames.DROPOFF_ADDRESS,
    BookRideFieldNames.PICKUP_DATE,
    BookRideFieldNames.PICKUP_TIME,
    BookRideFieldNames.RETURN_PICKUP_DATE,
    BookRideFieldNames.RETURN_PICKUP_TIME,
    BookRideFieldNames.ESTIMATED_TIME_IN_HOURS,
  ]);

  const navigate = useNavigate();
  const { programPass } = useProgramPassContext();

  return (
    <Box
      sx={{
        p: '20px',
      }}
      data-cy="trip-card"
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography
          sx={{
            ...TYPOGRAPHY.title4,
          }}
          data-cy="trip-label"
        >
          Trip
        </Typography>
        <EditButton
          onClick={(): void =>
            navigate(
              `${programPass ? `/pass/${programPass.id}/step1` : '/book-ride/step1'}` +
                location.search,
            )
          }
          dataCy="edit-ride-details"
        />
      </Stack>

      <Box data-cy="summary-locations">
        <TripSchedule
          address1={pickupAddress?.address}
          date1={`${dayjs(pickupDate).format('MMM D, YYYY')} - ${dayjs(pickupTime, TIME_24HRS_FORMAT).format(TIME_12HRS_FORMAT)}`}
          address2={
            tripType === TripTypes.OneWay || tripType === TripTypes.RoundTrip
              ? dropoffAddress?.address
              : tripType === TripTypes.Hourly
                ? `${estimatedTimeInHours}hrs`
                : ''
          }
          address1DataCy="pickup-text"
          address2DataCy="dropoff-text"
        />

        {tripType === TripTypes.RoundTrip && (
          <>
            <Typography
              sx={{
                ...TYPOGRAPHY.title4,
                mt: '20px',
              }}
            >
              Trip 2
            </Typography>
            <TripSchedule
              key="caca"
              address1={dropoffAddress?.address}
              date1={`${dayjs(returnPickupDate).format('MMM D, YYYY')} - ${dayjs(returnPickupTime, TIME_24HRS_FORMAT).format(TIME_12HRS_FORMAT)}`}
              address2={pickupAddress?.address}
              address1DataCy="pickup-text"
              address2DataCy="dropoff-text"
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default Trip;
