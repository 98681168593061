import { Typography } from '@mui/material';
import { TYPOGRAPHY } from '../../../../theme/typography';
import React, { FC, ReactElement } from 'react';

interface Props {
  onClick: () => void;
  dataCy?: string;
}
const EditButton: FC<Props> = ({ onClick, dataCy }): ReactElement => {
  return (
    <Typography
      sx={{
        ...TYPOGRAPHY.paragraph,
        textDecoration: 'underline',
        cursor: 'pointer',
      }}
      onClick={onClick}
      data-cy={dataCy}
    >
      Edit
    </Typography>
  );
};

export default EditButton;
