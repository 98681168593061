import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { TYPOGRAPHY } from '../../../../theme/typography';

import UserInfo from './UserInfo';
import FlightInfo from './FlightInfo';
import SpecialRequests from './SpecialRequests';
import { COLORS } from '../../../../theme/pallete';
import VehicleClassInfo from './VehicleClassInfo';
import Trip from './Trip';
import Total from './Total';
import Payment from './payment/Payment';
import AppButton from '../../../../components/shared/AppButton';
import {
  BookRideFormFields,
  PassCoverageTypes,
  ProgramPaymentMethods,
  TripTypes,
} from '../../types';
import { useFormContext } from 'react-hook-form';
import { useMatch, useNavigate, useOutletContext } from 'react-router-dom';
import BusinessTravel from './BusinessTravel';
import { LoadingComponent } from '../../../../components/shared/LoadingComponent';
import { CheckoutSections } from './types';
import {
  arePreviousSectionsCompleted,
  cleanBusinessInformation,
  continueToNextSection,
  disableSection,
  enableSection,
  getSectionNumber,
  onSectionEdit,
  setSectionAsIncompleteAndFocus,
} from './sectionHelpers';
import { useAuthContext } from 'auth/useAuthContext';
import { RouterContextType } from 'pages/bookRide/bookRidePage';
import { useProgramPassContext } from 'pages/ProgramPassContext';
import PageNewRideAutoGratuity from './AutoGratuity/PageNewRideAutoGratuity';

const CheckoutStep3 = (): ReactElement => {
  const { watch, formState, setValue, trigger } = useFormContext<BookRideFormFields>();
  const [isFirstPageLoad, setIsFirstPageLoad] = useState(true);
  const [lastCheckEnabledSections, setLastCheckEnabledSections] = useState<string>();
  const [lastBusinessAccountId, setLastBusinessAccountId] = useState<string>();
  const match = useMatch('/pass/:code/*');
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const { checkoutSections: sections, setCheckoutSections: setSections } =
    useOutletContext<RouterContextType>();

  const { programPass } = useProgramPassContext();

  const showPaymentSection =
    !programPass || programPass.businessProgram.passCoverageType !== PassCoverageTypes.FullAmount;

  const [
    tripType,
    pickupAddress,
    dropoffAddress,
    passengersAmount,
    luggageAmount,
    pickupDate,
    pickupTime,
    returnPickupDate,
    returnPickupTime,
    estimatedTimeInHours,
    vehicleClass,
    businessTravelAccount,
    businessTravelProgram,
    programPaymentMethod,
  ] = watch([
    'TRIP_TYPE',
    'PICKUP_ADDRESS',
    'DROPOFF_ADDRESS',
    'PASSENGERS_AMOUNT',
    'LUGGAGE_AMOUNT',
    'PICKUP_DATE',
    'PICKUP_TIME',
    'RETURN_PICKUP_DATE',
    'RETURN_PICKUP_TIME',
    'ESTIMATED_TIME_IN_HOURS',
    'SERVICE_PROVIDER_VEHICLE_CLASS',
    'BUSINESS_TRAVEL_ACCOUNT',
    'BUSINESS_TRAVEL_PROGRAM',
    'PROGRAM_PAYMENT_METHOD',
  ]);

  const requireFlightInfo =
    pickupAddress?.airportCode ||
    (dropoffAddress?.airportCode && (tripType as TripTypes) === TripTypes.RoundTrip);

  const confirmationButtonText =
    programPaymentMethod === ProgramPaymentMethods.DirectlyBilled
      ? 'Book now'
      : showPaymentSection
        ? 'Pay and book ride'
        : 'Book ride';

  const isFormDataValid = useMemo(() => {
    return (
      ((tripType === TripTypes.OneWay &&
        pickupAddress &&
        dropoffAddress &&
        pickupDate &&
        pickupTime) ||
        (tripType === TripTypes.RoundTrip &&
          pickupAddress &&
          dropoffAddress &&
          pickupDate &&
          pickupTime &&
          returnPickupDate &&
          returnPickupTime) ||
        (tripType === TripTypes.Hourly &&
          pickupAddress &&
          estimatedTimeInHours &&
          pickupDate &&
          pickupTime)) &&
      passengersAmount &&
      luggageAmount &&
      vehicleClass
    );
  }, [
    dropoffAddress,
    estimatedTimeInHours,
    pickupAddress,
    pickupDate,
    pickupTime,
    returnPickupDate,
    returnPickupTime,
    tripType,
    luggageAmount,
    passengersAmount,
    vehicleClass,
  ]);

  useEffect(() => {
    const checkedSections = JSON.stringify(
      sections.filter((s) => s.isEnabled).map((s) => s.identifier),
    );
    if (lastCheckEnabledSections !== checkedSections || isFirstPageLoad) {
      setIsFirstPageLoad(false);
      setLastCheckEnabledSections(checkedSections);

      const incompleteSections = sections.filter((s) => s.isEnabled && !s.isCompleted);
      if (user && incompleteSections.length > 0) {
        continueToNextSection(sections, CheckoutSections.User, setSections);
      }
    }
  }, [sections, user, isFirstPageLoad, setValue, lastCheckEnabledSections, setSections]);

  useEffect(() => {
    if (!isFormDataValid) {
      const passId = match?.params.code;
      const urlRedirect = passId ? `/pass/${passId}/step1${location.search}` : '/book-ride/step1';
      navigate(urlRedirect);
    }
  }, [isFormDataValid, match?.params.code, navigate]);

  useEffect(() => {
    trigger('SPECIAL_REQUESTS'); // If the user doesn't touch the special requests field, it won't be validated and the form will be considered invalid
    if (
      businessTravelAccount &&
      !sections.find((s) => s.identifier === CheckoutSections.BusinessTravel && s.isEnabled)
    ) {
      cleanBusinessInformation(setValue);
      enableSection(CheckoutSections.BusinessTravel, setSections);
    } else if (
      !businessTravelAccount &&
      sections.find((s) => s.identifier === CheckoutSections.BusinessTravel && s.isEnabled)
    ) {
      cleanBusinessInformation(setValue);
      disableSection(CheckoutSections.BusinessTravel, setSections);
    } else if (businessTravelAccount && businessTravelAccount.id !== lastBusinessAccountId) {
      if (lastBusinessAccountId) cleanBusinessInformation(setValue);

      setLastBusinessAccountId(businessTravelAccount.id);
      setSectionAsIncompleteAndFocus(sections, CheckoutSections.BusinessTravel, setSections);
    }

    if (
      showPaymentSection &&
      !sections.find((s) => s.identifier === CheckoutSections.Payment && s.isEnabled)
    ) {
      enableSection(CheckoutSections.Payment, setSections);
    } else if (
      !showPaymentSection &&
      sections.find((s) => s.identifier === CheckoutSections.Payment && s.isEnabled)
    ) {
      disableSection(CheckoutSections.Payment, setSections);
    }

    if (
      requireFlightInfo &&
      !sections.find((s) => s.identifier === CheckoutSections.Flight && s.isEnabled)
    ) {
      enableSection(CheckoutSections.Flight, setSections);
    } else if (
      !requireFlightInfo &&
      sections.find((s) => s.identifier === CheckoutSections.Flight && s.isEnabled)
    ) {
      disableSection(CheckoutSections.Flight, setSections);
    }
  }, [
    businessTravelAccount,
    showPaymentSection,
    setValue,
    requireFlightInfo,
    sections,
    trigger,
    lastBusinessAccountId,
    setSections,
  ]);

  useEffect(() => {
    if (
      programPaymentMethod === 1 &&
      !businessTravelProgram?.isOrganizationPaymentMethodAvailableForExtraGratuity
    ) {
      setValue('EXTRA_GRATUITY.percentage', false);
      setValue('EXTRA_GRATUITY.percentage', null);
    }
  }, [
    programPaymentMethod,
    setValue,
    businessTravelProgram?.isOrganizationPaymentMethodAvailableForExtraGratuity,
    // trigger,
  ]);

  const displayGratuityControl = (): boolean => {
    if (businessTravelProgram && programPaymentMethod === 1 && !businessTravelProgram.isOrganizationPaymentMethodAvailableForExtraGratuity)
      return false;

    return true;
  };

  return (
    <>
      {!isFormDataValid && <LoadingComponent />}
      {isFormDataValid && (
        <Box>
          <Typography
            sx={{
              ...TYPOGRAPHY.title1,
            }}
          >
            Add details & book
          </Typography>
          <Typography
            sx={{
              ...TYPOGRAPHY.subtitle2,
              mb: '60px',
            }}
          >
            Enter your details and complete your booking now.
          </Typography>

          <Grid
            container
            columnSpacing="80px"
            rowSpacing={{
              xs: '20px',
              md: 0,
            }}
          >
            <Grid item xs={12} md={6}>
              <Stack spacing="30px">
                <UserInfo
                  number={getSectionNumber(sections, CheckoutSections.User)}
                  onContinue={(): void =>
                    continueToNextSection(sections, CheckoutSections.User, setSections)
                  }
                  isEditing={
                    sections.find((s) => s.identifier === CheckoutSections.User)?.isEditing ?? false
                  }
                  onEdit={(): void => onSectionEdit(CheckoutSections.User, setSections)}
                  displayEditButton={true}
                />
                <Divider />

                {sections.find((s) => s.identifier === CheckoutSections.Flight)?.isEnabled && (
                  <>
                    <FlightInfo
                      number={getSectionNumber(sections, CheckoutSections.Flight)}
                      onContinue={(): void =>
                        continueToNextSection(sections, CheckoutSections.Flight, setSections)
                      }
                      isEditing={
                        sections.find((s) => s.identifier === CheckoutSections.Flight)?.isEditing ??
                        false
                      }
                      onEdit={(): void => onSectionEdit(CheckoutSections.Flight, setSections)}
                      displayEditButton={arePreviousSectionsCompleted(
                        sections,
                        CheckoutSections.Flight,
                      )}
                    />

                    <Divider />
                  </>
                )}

                {sections.find((s) => s.identifier === CheckoutSections.BusinessTravel)
                  ?.isEnabled && (
                    <>
                      <BusinessTravel
                        number={getSectionNumber(sections, CheckoutSections.BusinessTravel)}
                        onContinue={(): void =>
                          continueToNextSection(
                            sections,
                            CheckoutSections.BusinessTravel,
                            setSections,
                          )
                        }
                        onEdit={(): void =>
                          onSectionEdit(CheckoutSections.BusinessTravel, setSections)
                        }
                        isEditing={
                          sections.find((s) => s.identifier === CheckoutSections.BusinessTravel)
                            ?.isEditing ?? false
                        }
                        displayEditButton={arePreviousSectionsCompleted(
                          sections,
                          CheckoutSections.BusinessTravel,
                        )}
                      />

                      <Divider />
                    </>
                  )}

                {sections.find((s) => s.identifier === CheckoutSections.Payment)?.isEnabled && (
                  <>
                    <Payment
                      number={getSectionNumber(sections, CheckoutSections.Payment)}
                      onContinue={(): void =>
                        continueToNextSection(sections, CheckoutSections.Payment, setSections)
                      }
                      onEdit={(): void => onSectionEdit(CheckoutSections.Payment, setSections)}
                      isEditing={
                        sections.find((s) => s.identifier === CheckoutSections.Payment)
                          ?.isEditing ?? false
                      }
                      displayEditButton={arePreviousSectionsCompleted(
                        sections,
                        CheckoutSections.Payment,
                      )}
                    />

                    <Divider />
                  </>
                )}

                <SpecialRequests
                  number={getSectionNumber(sections, CheckoutSections.SpecialRequests)}
                  onContinue={(): void =>
                    continueToNextSection(sections, CheckoutSections.SpecialRequests, setSections)
                  }
                  onEdit={(): void => onSectionEdit(CheckoutSections.SpecialRequests, setSections)}
                  isEditing={
                    sections.find((s) => s.identifier === CheckoutSections.SpecialRequests)
                      ?.isEditing ?? false
                  }
                  displayEditButton={arePreviousSectionsCompleted(
                    sections,
                    CheckoutSections.SpecialRequests,
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  border: `1px solid ${COLORS.GREY_LIGHT_1}`,
                  borderRadius: '10px',
                }}
              >
                <VehicleClassInfo />
                <Divider />
                <Trip />
                <Divider />

                {displayGratuityControl() && (
                  <>
                    <PageNewRideAutoGratuity />
                    <Divider />
                  </>
                )}

                <Total showPrices={showPaymentSection} />

                {formState.isValid && (
                  <Box p="20px">
                    <AppButton
                      title={formState.isSubmitting ? 'Loading...' : confirmationButtonText}
                      type="submit"
                      disabled={formState.isSubmitting}
                      data-cy="checkout-button"
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default CheckoutStep3;
