import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';
import { get } from 'lodash';
import { TYPOGRAPHY } from '../../../theme/typography';

interface Props {
  name: string; // Field name, including potential nested paths
}

const ErrorMessage: React.FC<Props> = ({ name }) => {
  const {
    formState: { errors },
  } = useFormContext();

  // Use lodash.get to access nested error messages safely
  const errorMessage = get(errors, `${name}.message`);

  return (
    <>
      {errorMessage && (
        <Typography
          sx={{
            mt: '10px',
            ...TYPOGRAPHY.tinyText,
          }}
          style={{
            color: 'red',
          }}
          data-cy="errorMessage"
        >
          {errorMessage as unknown as string}
        </Typography>
      )}
    </>
  );
};

export default ErrorMessage;
