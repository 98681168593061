import { Box, SxProps, Typography } from '@mui/material';
import { FC, ReactElement } from 'react';
import { FONT_FAMILY } from '../../theme/typography';
import { PRIVACY_PAGE_URL, TERM_PAGE_URL } from 'shared.config';

const AppFooter: FC<{ sx: SxProps }> = ({ sx }): ReactElement => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        backgroundColor: 'black',
      }}
    >
      <Box
        sx={{
          height: '90px',
          display: 'flex',
          alignItems: 'center',
          ...sx,
        }}
      >
        <Typography
          sx={{
            fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
            fontSize: '12px',
            color: 'white',
          }}
          data-cy="copyright-message"
        >
          © Kaptyn 2024 All Rights Reserved.
        </Typography>

        <Typography
          component="a"
          href={PRIVACY_PAGE_URL}
          target="_blank"
          sx={{
            fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
            fontSize: '12px',
            color: 'white',
            ml: 'auto',
            textDecoration: 'none',
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
          data-cy="privacy-policy"
        >
          Privacy Policy
        </Typography>

        <Typography
          component="a"
          href={TERM_PAGE_URL}
          target="_blank"
          sx={{
            fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
            fontSize: '12px',
            color: 'white',
            ml: '20px',
            textDecoration: 'none',
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
          data-cy="terms-of-use"
        >
          Terms & Use
        </Typography>
      </Box>
    </Box>
  );
};

export default AppFooter;
