import { Box, Grid, Typography } from '@mui/material';
import { COLORS } from '../../theme/pallete';
import { TYPOGRAPHY } from '../../theme/typography';
import TripSchedule from '../../components/shared/TripSchedule';
import AppButton from '../../components/shared/AppButton';
import React, { ReactElement } from 'react';

const MyTripItem = (): ReactElement => {
  return (
    <Box
      key="tab 1"
      sx={{
        border: `1px solid ${COLORS.GREY_MEDIUM_1}`,
        borderRadius: '10px',
        p: '25px',
      }}
    >
      <Typography
        sx={{
          ...TYPOGRAPHY.tinyText,
          fontWeight: '600',
          letterSpacing: '1px',
        }}
      >
        RES-1C4FKC | Wednesday Mar 27 2024 - 09:24 am | Tesla S | $67.98
      </Typography>
      <Grid container spacing="50px">
        <Grid item xs={12} md="auto">
          <Typography
            sx={{
              ...TYPOGRAPHY.title3,
            }}
          >
            Wed, Mar 27 at 11:30 AM
          </Typography>
        </Grid>
        <Grid item xs={12} md="auto">
          <TripSchedule
            address1=" Aeropuerto Internacional Harry Reid (LAS), 5757 Wayne Newton
            Boulevard, Las Vegas, NV 89119"
            date1=" Mar 26, 2024 - 2:30PM"
            address2=" Aeropuerto Internacional Harry Reid (LAS), 5757 Wayne Newton
            Boulevard, Las Vegas, NV 89119"
          />
        </Grid>
        <Grid item xs={12} md="auto">
          <Typography
            sx={{
              ...TYPOGRAPHY.paragraph,
            }}
          >
            Additional info
          </Typography>

          <Typography
            sx={{
              ...TYPOGRAPHY.paragraph,
              mt: '10px',
            }}
          >
            Service
          </Typography>
          <Typography
            sx={{
              ...TYPOGRAPHY.paragraph,
              color: COLORS.GREY_MEDIUM_4,
            }}
          >
            Tesla S
          </Typography>

          <Typography
            sx={{
              ...TYPOGRAPHY.paragraph,
              mt: '10px',
            }}
          >
            Payment
          </Typography>
          <Typography
            sx={{
              ...TYPOGRAPHY.paragraph,
              color: COLORS.GREY_MEDIUM_4,
            }}
          >
            $67.98
          </Typography>
        </Grid>
      </Grid>
      <AppButton
        title="Cancel reservation"
        variant="outlined"
        additionalSx={{
          width: 'fit-content',
          mt: '20px',
        }}
      />
    </Box>
  );
};

export default MyTripItem;
