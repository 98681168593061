import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Box, Divider, MenuItem, TextField, Typography } from '@mui/material';
import {
  ServiceTypes,
  useBaseService,
} from '../../../../../../services/useBaseService';
import { ENDPOINTS } from '../../../../../../services/Endpoints';
import {
  BookRideFieldNames,
  PaymentMethodResponseByKaptyn,
} from '../../../../types';
import useModal from '../../../../../../HookComponents/useModal';
import { Elements } from '@stripe/react-stripe-js';
import LoggedInUserPaymentCheckoutForm from './LoggedInUserPaymentCheckoutForm';
import { useFormContext } from 'react-hook-form';
import { COLORS } from '../../../../../../theme/pallete';
import { TYPOGRAPHY } from '../../../../../../theme/typography';
import { LoadingComponent } from 'components/shared/LoadingComponent';
import { useAuthContext } from 'auth/useAuthContext';
import { useStripeContext } from 'pages/StripeContext';

interface Props {
  onContinue: () => void;
  loggedInUserPaymentMethods?: PaymentMethodResponseByKaptyn[];
  setLoggedInUserPaymentMethods: React.Dispatch<
    React.SetStateAction<PaymentMethodResponseByKaptyn[] | undefined>
  >;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoggedInUserPayment: FC<Props> = ({
  onContinue,
  loggedInUserPaymentMethods,
  setLoggedInUserPaymentMethods,
  isLoading,
  setIsLoading,
}): ReactElement => {
  const { post } = useBaseService({ type: ServiceTypes.PaymentAPI });
  const { stripePromise } = useStripeContext();

  const { setValue, watch } = useFormContext();
  const [clientSecret, setClientSecret] = useState<string>();

  const { Modal, open, openModal, closeModal } = useModal();

  const { user } = useAuthContext();

  const payment = watch('PAYMENT');

  const setClientSecretFromIntent = useCallback(async () => {
    setIsLoading(true);
    try {
      const gottenClientSecret = await post(
        ENDPOINTS.CreatePaymentIntentForLoggedInUser,
        true,
      );
      setClientSecret((gottenClientSecret as string) ?? '');
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  }, [post, setIsLoading]);

  useEffect(() => {
    if (!isLoading && !clientSecret && !user) {
      setClientSecretFromIntent();
    }
  }, [setClientSecretFromIntent, clientSecret, isLoading, user]);

  const addAndSelectPaymentMethod = (
    paymentMethod: PaymentMethodResponseByKaptyn,
  ): void => {
    let newPaymentMethods;
    if (loggedInUserPaymentMethods)
      newPaymentMethods = [...loggedInUserPaymentMethods, paymentMethod];
    else newPaymentMethods = [paymentMethod];
    setLoggedInUserPaymentMethods(newPaymentMethods);
    setValue(BookRideFieldNames.PAYMENT, {
      payment_method_by_kaptyn: newPaymentMethods.find((pM) => {
        return pM.id === paymentMethod.id;
      }),
    });
    onContinue();
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setValue(BookRideFieldNames.PAYMENT, {
      payment_method_by_kaptyn: loggedInUserPaymentMethods?.find((pM) => {
        return pM.id === e.target.value;
      }),
    });
    onContinue();
  };

  const internalOpenModal = useCallback(async () => {
    await setClientSecretFromIntent();
    openModal();
  }, [openModal, setClientSecretFromIntent]);

  if (isLoading) return <LoadingComponent verticalMargin={15} />;

  return (
    <>
      <Modal isOpen={open} onClose={closeModal} title="Add Payment method">
        <>
          {clientSecret && (
            <Elements
              stripe={stripePromise}
              key={clientSecret}
              options={{
                clientSecret,
                appearance: {
                  theme: 'stripe',
                  variables: {
                    colorPrimary: '#0570de',
                    colorBackground: '#F7F7F8',
                    colorText: 'black',
                    colorDanger: '#df1b41',
                    // fontFamily: 'chromatic-pro-regular, sans-serif',
                    spacingUnit: '2px',
                    borderRadius: '4px',
                    // See all possible variables below
                  },
                  rules: {
                    '.Label': {
                      opacity: '1',
                      color: '#67676f',
                      fontSize: '12px',
                      marginTop: '10px',
                      marginBottom: '5px',
                    },
                    '.Input': {
                      padding: '19px 10px',
                      border: 'none',
                      outline: 'none',
                    },
                    '.Input::placeholder': {
                      color: 'rgba(165,165,165,0.87)',
                    },
                  },
                },
              }}
            >
              <LoggedInUserPaymentCheckoutForm
                clientSecret={clientSecret}
                closeModal={closeModal}
                addAndSelectPaymentMethod={addAndSelectPaymentMethod}
              />
            </Elements>
          )}
        </>
      </Modal>
      <Typography
        sx={{
          ...TYPOGRAPHY.tinyText,
          mt: '10px',
          mb: '5px',
        }}
      >
        Payment method
      </Typography>
      <Box
        sx={{
          ul: {
            height: '200px!important',
          },
        }}
        data-cy="payment-method-select"
      >
        <TextField
          select
          onChange={onChange}
          value={
            payment?.payment_method_by_kaptyn?.id &&
              loggedInUserPaymentMethods?.find(
                (pm) => pm.id === payment?.payment_method_by_kaptyn?.id,
              )
              ? payment?.payment_method_by_kaptyn?.id
              : ''
          }
          sx={{
            width: '100%',
            backgroundColor: COLORS.GREY_LIGHT_4,
            border: 'none',
            borderRadius: '10px',
            overflow: 'hidden',
            mb: '15px',
            fieldset: {
              border: 'none',
            },
            ul: {
              height: '200px!important',
            },
          }}
        >
          <MenuItem
            key="none"
            value="none"
            sx={{
              padding: 0,
            }}
          ></MenuItem>
          {loggedInUserPaymentMethods?.map((lUPM) => (
            <MenuItem
              key={lUPM.id}
              value={lUPM.id}
              data-cy={`payment-method-select-item${lUPM.id}`}
            >
              ***{lUPM.number}
            </MenuItem>
          ))}
          {loggedInUserPaymentMethods &&
            loggedInUserPaymentMethods.length > 0 && <Divider />}
          <Box
            sx={{
              padding: '10px',
            }}
          >
            <Typography
              sx={{
                ...TYPOGRAPHY.tinyText,
              }}
            >
              Add payment method
            </Typography>
            <Typography
              sx={{
                cursor: 'pointer',
              }}
              onClick={internalOpenModal}
              data-cy="add-payment-method-button"
            >
              <img
                src="/assets/icons/icon_creditCard.svg"
                alt="credit card image"
                style={{
                  marginRight: '10px',
                  marginTop: '10px',
                }}
              />
              Credit or debit card
            </Typography>
          </Box>
        </TextField>
      </Box>
    </>
  );
};

export default LoggedInUserPayment;
