import { UseFormSetValue } from 'react-hook-form';
import { CheckoutSectionType, CheckoutSections } from './types';
import { BookRideFormFields } from 'pages/bookRide/types';

export const initialSectionValues: CheckoutSectionType[] = [
  {
    identifier: CheckoutSections.User,
    isEditing: true,
    isEnabled: true,
    isCompleted: false,
  },
  {
    identifier: CheckoutSections.Flight,
    isEditing: false,
    isEnabled: false,
    isCompleted: false,
  },
  {
    identifier: CheckoutSections.BusinessTravel,
    isEditing: false,
    isEnabled: false,
    isCompleted: false,
  },
  {
    identifier: CheckoutSections.Payment,
    isEditing: false,
    isEnabled: true,
    isCompleted: false,
  },
  {
    identifier: CheckoutSections.SpecialRequests,
    isEditing: false,
    isEnabled: true,
    isCompleted: true,
  },
];

export const getSectionNumber = (
  sectionsState: CheckoutSectionType[],
  identifier: CheckoutSections,
): number => {
  const enabledSections = sectionsState.filter((s) => s.isEnabled);
  let sectionNumber = 1;
  for (let i = 0; i < enabledSections.length; i++) {
    if (enabledSections[i].identifier === identifier) {
      return sectionNumber;
    }
    sectionNumber++;
  }

  throw new Error(`Section ${identifier} not found`);
};

export const getNextIncompleteSection = (
  sectionsState: CheckoutSectionType[],
  identifier: CheckoutSections,
): CheckoutSections | undefined => {
  const currentSectionIndex = sectionsState.findIndex(
    (section) => section.identifier === identifier,
  );
  const nextSection = sectionsState
    .slice(currentSectionIndex + 1)
    .find((section) => section.isEnabled && !section.isCompleted);
  return nextSection?.identifier;
};

export const continueToNextSection = (
  sectionsState: CheckoutSectionType[],
  identifier: CheckoutSections,
  setSections: React.Dispatch<React.SetStateAction<CheckoutSectionType[]>>,
): void => {
  setSectionAsCompleted(sectionsState, identifier, setSections);

  const nextSection = getNextIncompleteSection(sectionsState, identifier);
  const isAlreadyEditing = sectionsState.find(
    (section) => section.identifier === nextSection,
  )?.isEditing;

  const enabledSections = sectionsState.filter((section) => section.isEnabled);
  const areAllSectionsCompleted = enabledSections.every(
    (enabledSection) => enabledSection.isCompleted,
  );

  if (!areAllSectionsCompleted && !isAlreadyEditing) {
    if (nextSection) {
      setSections((prev) => {
        return prev.map((section) => {
          if (section.identifier === nextSection) {
            return {
              ...section,
              isEditing: true,
              displayEditButton: true,
            };
          }
          return {
            ...section,
            isEditing: false,
          };
        });
      });
    } else {
      setSections((prev) => {
        return prev.map((section) => {
          return {
            ...section,
            isEditing: false,
          };
        });
      });
    }
  }
};

export const onSectionEdit = (
  identifier: CheckoutSections,
  setSections: React.Dispatch<React.SetStateAction<CheckoutSectionType[]>>,
): void => {
  setSections((prev) => {
    return prev.map((section) => {
      if (section.identifier === identifier) {
        return {
          ...section,
          isEditing: true,
          isCompleted: false,
        };
      }
      return {
        ...section,
        isEditing: false,
      };
    });
  });
};

export const enableSection = (
  identifier: CheckoutSections,
  setSections: React.Dispatch<React.SetStateAction<CheckoutSectionType[]>>,
): void => {
  setSections((prev) => {
    return prev.map((section) => {
      if (section.identifier === identifier) {
        return {
          ...section,
          isEnabled: true,
        };
      }
      return section;
    });
  });
};

export const disableSection = (
  identifier: CheckoutSections,
  setSections: React.Dispatch<React.SetStateAction<CheckoutSectionType[]>>,
): void => {
  setSections((prev) => {
    return prev.map((section) => {
      if (section.identifier === identifier) {
        return {
          ...section,
          isEnabled: false,
        };
      }
      return section;
    });
  });
};

const setSectionAsCompleted = (
  sectionsState: CheckoutSectionType[],
  identifier: CheckoutSections,
  setSections: React.Dispatch<React.SetStateAction<CheckoutSectionType[]>>,
): void => {
  const isAlreadyCompleted = sectionsState.find(
    (section) => section.identifier === identifier,
  )?.isCompleted;

  if (!isAlreadyCompleted) {
    setSections((prev) => {
      return prev.map((section) => {
        if (section.identifier === identifier) {
          return {
            ...section,
            isCompleted: true,
          };
        }
        return section;
      });
    });
  }
};

export const setSectionAsIncompleteAndFocus = (
  sectionsState: CheckoutSectionType[],
  identifier: CheckoutSections,
  setSections: React.Dispatch<React.SetStateAction<CheckoutSectionType[]>>,
): void => {
  const isAlreadyIncomplete =
    sectionsState.find((section) => section.identifier === identifier)?.isCompleted === false;

  if (!isAlreadyIncomplete) {
    setSections((prev) => {
      return prev.map((section) => {
        if (section.identifier === identifier) {
          return {
            ...section,
            isCompleted: false,
            isEditing: true,
          };
        }
        return {
          ...section,
          isEditing: false,
        };
      });
    });
  }
};

export const finishEditing = (
  identifier: CheckoutSections,
  setSections: React.Dispatch<React.SetStateAction<CheckoutSectionType[]>>,
): void => {
  setSections((prev) => {
    return prev.map((section) => {
      if (section.identifier === identifier) {
        return {
          ...section,
          isEditing: false,
        };
      }
      return section;
    });
  });
};

export const cleanBusinessInformation = (setValue: UseFormSetValue<BookRideFormFields>): void => {
  setValue('PROGRAM_PAYMENT_METHOD', undefined);
  setValue('BUSINESS_TRAVEL_PROGRAM', undefined);
  setValue('PAYMENT', undefined);
};

export const arePreviousSectionsCompleted = (
  sectionsState: CheckoutSectionType[],
  identifier: CheckoutSections,
): boolean => {
  const enabledSections = sectionsState.filter((section) => section.isEnabled);
  const index = enabledSections.findIndex((section) => section.identifier === identifier);
  const previousEnabledSections = enabledSections.slice(0, index);
  return previousEnabledSections.every((section) => section.isCompleted);
};
