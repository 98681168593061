import { ServiceProviderVehicleClassType, TripTypes } from './pages/bookRide/types';

export type UserType = {
  user_id: string;
  access_token: string;
  exp: number;
  email_verified: boolean;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  rider_in_accounts: string;
};

export type ActionMapType<M extends { [index: string]: unknown }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type AppWhiteLabelingConfiguration = {
  logoUrl: string;
  accountId: string;
  primaryColor: string;
};

export type LocationDetailsType = {
  addressType: AddressTypes;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  countryCode: string;
  airportCode?: string;
  flightNumber?: string;
  airlineId?: string;
  latitude: number;
  longitude: number;
  googlePlacesId: string;
};

export enum AddressTypes {
  PickUp = 0,
  DropOff = 1,
}

export type RegionType = {
  id: number;
  name: string;
  timezone: string;
};

export interface VehicleClassesBasePoolType<DataType = unknown> {
  serviceProviderId: string;
  tripType: TripTypes;
  pickupAddress: string;
  dropoffAddress?: string;
  pickupDate: string;
  pickupTime: string;
  returnPickupDate?: string;
  returnPickupTime?: string;
  passengersAmount: number;
  luggageAmount: number;
  estimatedTimeInHours?: string;
  data: DataType;
}
export type VehicleClassesToQuotePoolType = VehicleClassesBasePoolType<
  ServiceProviderVehicleClassType[]
>;

export type VehicleClassesPoolType = VehicleClassesBasePoolType<ServiceProviderVehicleClassType>;

export type FieldsByCurrTripTypeType = Omit<
  VehicleClassesBasePoolType,
  'serviceProviderId' | 'data' | 'passengersAmount' | 'luggageAmount'
>;

export type SurchargesList = {
  description: string;
  amount: number;
};

export type legsType = {
  total: number;
  subTotal: number;
  surchargesList: SurchargesList[];
  tip: number;
  tax: number;
};

export type LegData = {
  label: string;
  value: string | number;
  isTitle?: boolean;
  isVisible?: boolean;
  valueDataCy?: string;
};

export const SurchargeNames = {
  AutoGratuity: 'Auto Gratuity',
};
