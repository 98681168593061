import React, { FC, ReactElement } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { TYPOGRAPHY } from '../../theme/typography';
import { COLORS } from '../../theme/pallete';

interface Props {
  address1: string;
  date1: string;
  address2: string;
  address1DataCy?: string;
  address2DataCy?: string;
}

const TripSchedule: FC<Props> = ({
  address1,
  date1,
  address2,
  address1DataCy,
  address2DataCy,
}): ReactElement => {
  return (
    <Box>
      <Stack
        direction="row"
        alignItems="stretch"
        justifyContent="stretch"
        spacing="12px"
        mt="25px"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '20px',
          }}
        >
          <img
            src="/assets/images/outlinedBoxCenteredPoint.png"
            alt="outlinedBoxCenteredPoint"
            style={{
              objectFit: 'contain',
            }}
          />
          <Box
            sx={{
              backgroundColor: 'black',
              width: '1px',
              flex: 'auto 1 1',
              margin: '0 auto',
            }}
          />
        </Box>
        <Box
          sx={{
            paddingBottom: '30px',
          }}
        >
          <Typography
            sx={{
              ...TYPOGRAPHY.subtitle2,
              display: 'block',
            }}
            data-cy={address1DataCy}
          >
            {address1}
          </Typography>
          <Typography
            sx={{
              ...TYPOGRAPHY.subtitle2,
              color: COLORS.GREY_MEDIUM_1,
              display: 'block',
            }}
          >
            {date1}
          </Typography>
        </Box>
      </Stack>
      <Stack
        direction="row"
        alignItems="stretch"
        justifyContent="stretch"
        spacing="12px"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '20px',
          }}
        >
          <img
            src="/assets/images/filledBoxCenteredPoint.png"
            alt="outlinedBoxCenteredPoint"
            style={{
              objectFit: 'contain',
            }}
          />
        </Box>
        <Box>
          <Typography
            sx={{
              ...TYPOGRAPHY.subtitle2,
              display: 'block',
            }}
            data-cy={address2DataCy}
          >
            {address2}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default TripSchedule;
