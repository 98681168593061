import { loadStripe, Stripe } from "@stripe/stripe-js";
import React from "react";
import { STRIPE_PUBLISHABLE_KEY } from "shared.config";

let stripeKey = STRIPE_PUBLISHABLE_KEY;
if (!stripeKey)
    stripeKey = 'pk_test_BLABLABLABLABLABLABLAL';

const stripePromise = loadStripe(stripeKey);

type StripeContextType = {
    stripePromise: Promise<Stripe | null>;
};

const StripeContext: React.Context<StripeContextType> = React.createContext({
    stripePromise: stripePromise,
});

export const useStripeContext = (): StripeContextType => React.useContext(StripeContext);

export const StripeProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
    return <StripeContext.Provider value={{ stripePromise }}>{children}</StripeContext.Provider>;
}