import { UserType } from "shared.types";

export const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export const decodeUser = (accessToken: string): UserType => {
  const user: UserType = jwtDecode(accessToken);

  user.access_token = accessToken;
  sessionStorage.setItem("bh_access_token", accessToken);

  return user;
};

export function jwtDecode(token: string): UserType {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
}
