import { COLORS } from './pallete';

export const FONT_FAMILY = {
  CHROMATIC_PRO_REGULAR: 'chromatic-pro-regular',
};

export const TYPOGRAPHY = {
  title1: {
    fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
    fontSize: '38px',
    color: COLORS.BLACK_DARK_1,
  },
  title2: {
    fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
    fontSize: '28px',
    color: COLORS.BLACK_DARK_1,
  },
  title3: {
    fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
    fontSize: '24px',
    color: COLORS.BLACK_DARK_1,
  },
  title4: {
    fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
    fontSize: '20px',
    color: COLORS.BLACK_DARK_1,
  },
  subtitle1: {
    fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
    fontSize: '18px',
    color: COLORS.BLACK_DARK_1,
    lineHeight: '28px;',
  },
  subtitle2: {
    fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
    fontSize: '14px',
    color: COLORS.GREY_MEDIUM_4,
  },
  mainButton: {
    fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
    fontSize: '16px',
    color: 'white',
  },
  formField: {
    fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
    fontsize: '16px',
  },
  paragraph: {
    fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
    fontsize: '16px',
    color: COLORS.BLACK_DARK_1,
  },
  tinyText: {
    fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
    fontSize: '12px',
    color: COLORS.BLACK_DARK_1,
  },
};
