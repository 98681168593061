import { Box, useMediaQuery } from '@mui/material';
import { FC, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import AppTopBar from './AppTopBar';
import AppFooter from './AppFooter';
import { useAuthContext } from '../../auth/useAuthContext';
import { useNavigate } from 'react-router-dom';
import { LoadingComponent } from './LoadingComponent';

const layoutStyles = {
  width: '100%',
  maxWidth: '1230px',
  margin: '0 auto',
  padding: {
    xs: '0 16px',
    md: '0 25px',
  },
};

const Layout: FC<PropsWithChildren> = ({ children }): ReactElement => {
  const isMobile = useMediaQuery('(max-width:982px)');
  const [visible, setVisible] = useState(false);

  const AppTopBarHeight = isMobile ? 124 : 90;

  const { user, isLoading } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !user.email_verified) {
      navigate('/email-validation');
    } else {
      setVisible(true);
    }
  }, [navigate, user]);

  if (isLoading || (user && !user.email_verified)) return <LoadingComponent />;

  return (
    <>
      {visible && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            overflow: 'hidden',
            position: 'absolute',
            width: '100%',
          }}
        >
          <AppTopBar sx={layoutStyles} height={AppTopBarHeight} />
          <Box sx={{ ...layoutStyles, pt: AppTopBarHeight + 18 + 'px!important' }}>{children}</Box>
          <Box
            sx={{
              mt: 'auto',
              height: '30px',
            }}
          />
          <AppFooter sx={layoutStyles} />
        </Box>
      )}
    </>
  );
};

export default Layout;
