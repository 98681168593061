import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import { TYPOGRAPHY } from '../theme/typography';

const NotFound404Page = (): ReactElement => {
  return (
    <Typography
      sx={{
        ...TYPOGRAPHY.title1,
      }}
    >
      Page not found
    </Typography>
  );
};

export default NotFound404Page;
