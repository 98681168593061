import React from 'react';
import { Box, InputAdornment, Typography } from '@mui/material';
import SvgIcon from '../../../../../../components/shared/SvgIcon';
import RHFTextField from '../../../../../../components/shared/formFieldRelated/RHFTextField';
import TabSystem from '../../../../../../components/shared/TabSystem';
import { COLORS } from '../../../../../../theme/pallete';

interface Props {
  value: {
    tabValue: string;
    percentage: string | null;
  };
  tabs: { label: string; value: string | 'custom'; dataCy?: string }[];
  allowCustomValue?: boolean;
  allowDeselection?: boolean;
  onChange: (position: string) => void;
  onClear: () => void;
}
const TabbedTips: React.FC<Props> = ({
  value,
  tabs,
  onChange,
  onClear,
  allowCustomValue = true,
  allowDeselection = false,
}) => {
  const theTabs = [...tabs];

  const content: ([] | React.ReactNode)[] = [...Array.from({ length: tabs.length }, () => [])];

  if (allowCustomValue) {
    theTabs.push({ label: 'Custom', value: 'custom', dataCy: 'custom' });
    content.push(
      <Box
        key="customContent"
        sx={{
          mt: '24px',
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          Custom percentage
        </Typography>
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <RHFTextField
            name="EXTRA_GRATUITY.percentage"
            additionalSx={{
              'input:focus': {
                outline: 'none',
                border: `2px solid${COLORS.BLACK_DARK_2}`,

                borderRadius: '8px',
              },
            }}
            // type="number"
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>): void => {
              if (
                !/[0-9]/.test(event.key) &&
                event.key !== 'Backspace' &&
                event.key !== 'Delete' &&
                event.key !== 'ArrowRight' &&
                event.key !== 'ArrowLeft' &&
                event.key !== 'Tab'
              ) {
                event.preventDefault();
              }
            }}
            onWheel={(e: React.WheelEvent<HTMLDivElement>): void => {
              const targetElement = e.target as HTMLElement; // Type casting
              targetElement.blur();
            }}
            inputProps={{
              maxLength: 3,
            }}
          />
          {(value.percentage?.length ?? 0) > 0 && (
            <InputAdornment
              position="start"
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                right: '16px',
                top: '0',
                bottom: '0',
                margin: 'auto',
              }}
            >
              <SvgIcon
                src="/assets/icons/icon_outlinedRoundedX.svg"
                onClick={onClear}
                width="20px"
              />
            </InputAdornment>
          )}
        </Box>
      </Box>,
    );
  }

  return (
    <>
      <TabSystem
        value={value.tabValue}
        tabs={theTabs}
        content={content}
        onChange={(event, position): void => {
          onChange(position as string);
        }}
        allowDeselection={allowDeselection}
      />
    </>
  );
};

export default TabbedTips;
