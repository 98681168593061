import { Box, Typography } from '@mui/material';
import { FONT_FAMILY, TYPOGRAPHY } from '../../theme/typography';
import { FC, ReactElement, useMemo } from 'react';
import { COLORS } from '../../theme/pallete';

interface Props {
  isPass: boolean;
  imageUrl?: string;
  title?: string;
  description?: string;
  informationMessage?: string;
}

const BookRideHeader: FC<Props> = ({
  isPass,
  imageUrl,
  title,
  description,
  informationMessage,
}): ReactElement => {
  const headerImgPath = useMemo(() => {
    let imgPath = '';
    if (isPass) {
      if (imageUrl) {
        imgPath = imageUrl;
      } else {
        imgPath = '/assets/images/pass-default-banner.jpg';
      }
    } else {
      imgPath = '/assets/images/banner.jpg';
    }

    return imgPath;
  }, [imageUrl, isPass]);

  return (
    <Box data-cy="home-image">
      {!isPass && (
        <Box
          sx={{
            height: '504px',
            background: `url('${headerImgPath}') no-repeat`,
            backgroundSize: 'cover',
            backgroundPositionX: 'center',
            position: 'relative',
            width: '100vw',
            left: '50%',
            right: '50%',
            marginLeft: '-50vw',
            marginRight: '-50vw',
            marginTop: '-18px',
            padding: '25px',
          }}
        >
          <Box
            sx={{
              maxWidth: '1180px',
              width: '100%',
              margin: '0 auto',
              display: 'flex',
              alignItems: 'flex-end',
              height: '100%',
            }}
          >
            <Typography
              sx={{
                fontSize: '76px',
                color: 'white',
                fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
                textTransform: 'uppercase',
                width: '100%',
                maxWidth: '750px',
              }}
            >
              When the ride is the best part
            </Typography>
          </Box>
        </Box>
      )}
      {isPass && (
        <>
          <Box
            sx={{
              backgroundColor: '#F5F5F2',
              backgroundImage: `url('${headerImgPath}')`,
              width: '100%',
              height: '391px',
              backgroundPosition: 'right center',
              backgroundSize: 'cover',
              padding: '25px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              borderRadius: '16px',
            }}
          >
            <Typography
              sx={{
                ...TYPOGRAPHY.title1,
                width: '100%',
                fontSize: '35px',
                maxWidth: '100%',
                color: 'white',
              }}
              data-cy="home-img-text1"
            >
              {`${title ?? 'Complete your journey with scheduled roundtrips'}`}
            </Typography>
            <Typography
              sx={{
                ...TYPOGRAPHY.subtitle1,
                width: '100%',
                maxWidth: '100%',
                color: 'white',
                fontSize: '14px',
              }}
              data-cy="home-img-text2"
            >
              {`${
                description ??
                '   Introducing stress-free roundtrip reservations for airports, events and\n' +
                  '        everyday journeys'
              }`}
            </Typography>
          </Box>
          {informationMessage && (
            <Box
              sx={{
                backgroundColor: COLORS.PURPLE_LIGHT_1,
                padding: '16px',
                borderRadius: '16px',
                mt: '25px',
              }}
              data-cy="info-msg"
            >
              <Typography
                sx={{
                  ...TYPOGRAPHY.paragraph,
                  display: 'flex',
                  alignItems: 'center',
                  color: COLORS.BLACK_DARK_2,
                }}
              >
                <img
                  src="/assets/icons/icon_info.svg"
                  alt="icon info"
                  style={{
                    marginRight: '16px',
                  }}
                />
                {informationMessage}
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default BookRideHeader;
