export enum CheckoutSections {
    User,
    Flight,
    BusinessTravel,
    Payment,
    SpecialRequests,
}

export type CheckoutSectionType = {
    identifier: CheckoutSections;
    isEditing: boolean;
    isEnabled: boolean;
    isCompleted: boolean;
};