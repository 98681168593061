import { SnackbarContent } from 'notistack';
import { forwardRef, useCallback, useMemo } from 'react';
import { Alert, Typography, AlertTitle } from '@mui/material';
import { useSnackbar } from './index';

interface DescSnackbarProps {
  message?: string;
  variant: 'success' | 'error' | 'warning';
  closeSnackbar: () => void;
}

const DescriptionSnackbar = forwardRef<HTMLDivElement, DescSnackbarProps>(
  (props, ref) => {
    const variantStyles = useMemo(() => {
      let bgColor = '';
      let textColor = '';
      let leadIconColor = '';
      switch (props.variant) {
        case 'success':
          bgColor = '#0a5554';
          textColor = '#d8fbde';
          leadIconColor = '#86e8ab';
          break;
        case 'error':
          bgColor = '#7a0916';
          textColor = '#ffe9d5';
          leadIconColor = '#ffac82';
          break;
        case 'warning':
          bgColor = '#ffcb63';
          textColor = '#000000';
          leadIconColor = '#ffaa00';
          break;

        default:
          bgColor = '#7a0916';
          textColor = '#ffe9d5';
          leadIconColor = '#ffac82';
          break;
      }

      return {
        textColor,
        bgColor,
        leadIconColor,
      };
    }, [props.variant]);

    const messageToPrint = { __html: props.message ?? '' };

    return (
      <SnackbarContent
        ref={ref}
        style={{
          padding: 0,
          width: '100%',
        }}
        data-cy="description-snackbar"
      >
        <Alert
          severity={props.variant}
          sx={{
            width: '100%',
            backgroundColor: variantStyles.bgColor,
            '.MuiAlert-icon svg': {
              fill: variantStyles.leadIconColor,
            },
            ' .MuiButtonBase-root svg': {
              fill: '#fff5cc',
            },
            '.MuiAlert-message': {
              wordWrap: 'break-word',
            },
          }}
          color="success"
          onClose={(): void => props.closeSnackbar()}
        >
          <AlertTitle color={variantStyles.textColor}>
            {props.variant === 'success' && 'Success'}
            {props.variant === 'error' && 'Error'}
            {props.variant === 'warning' && 'Warning'}
          </AlertTitle>
          <Typography color={variantStyles.textColor}>
            <p dangerouslySetInnerHTML={messageToPrint} />
          </Typography>
        </Alert>
      </SnackbarContent>
    );
  },
);
DescriptionSnackbar.displayName = 'DescriptionSnackbar';

export default DescriptionSnackbar;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCustomSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showSnackbar = useCallback(
    (message: string, variant: 'success' | 'error' | 'warning') => {
      enqueueSnackbar(message, {
        content: (id, msg) => (
          <DescriptionSnackbar
            message={(msg as string) ?? ''}
            variant={variant}
            closeSnackbar={closeSnackbar}
          />
        ),
      });
    },
    [enqueueSnackbar, closeSnackbar],
  );

  return {
    showSnackbar,
  };
};
