import { Box, Stack, Typography } from '@mui/material';
import { TYPOGRAPHY } from '../../theme/typography';
import React, { ReactElement, useState } from 'react';
import TabSystem from '../../components/shared/TabSystem';
import MyTripItem from './MyTripItem';

type TabOptionsTypes = 'upcomming' | 'past';

const TabOptions: { label: string; value: TabOptionsTypes }[] = [
  {
    label: 'Upcomming',
    value: 'upcomming',
  },
  {
    label: 'Past',
    value: 'past',
  },
];

const MyTripsPage = (): ReactElement => {
  const [currentTab, setCurrentTab] = useState<TabOptionsTypes>('upcomming');

  const upcommingTripsItems = [...Array(5)].map((value, index) => {
    return <MyTripItem key={index} />;
  });

  const pastTripsItems = [...Array(2)].map((value, index) => {
    return <MyTripItem key={index} />;
  });

  return (
    <Box>
      <Typography
        sx={{
          ...TYPOGRAPHY.title1,
        }}
      >
        My trips
      </Typography>

      <Box
        sx={{
          mt: '40px',
        }}
      >
        <TabSystem
          value={currentTab}
          tabs={TabOptions}
          content={[
            <Stack key="tab 1" spacing="15px">
              {upcommingTripsItems}
            </Stack>,
            <Stack key="tab 2" spacing="15px">
              {pastTripsItems}
            </Stack>,
          ]}
          onChange={(event, position): void => {
            setCurrentTab(position as TabOptionsTypes);
          }}
        />
      </Box>
    </Box>
  );
};

export default MyTripsPage;
