import { UserType } from 'shared.types';

export const removeDomainAndPort = (url: string): string => {
  const parsedUrl = new URL(url);
  const path = parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
  return path;
};

export const getNameInitials = (user: UserType): string => {
  return (
    (user?.first_name ?? '')[0].toUpperCase() +
    (user?.last_name ?? '')[0].toUpperCase()
  );
};

export function buildQueryString(
  params: Record<string, string | number | Date>,
  prefix = '',
): string {
  const pairs = Object.entries(params).flatMap(([key, value]) => {
    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        return value.map((v, i) =>
          buildQueryString({ [`${key}[${i}]`]: v }, prefix),
        );
      }
      if (value instanceof Date) {
        return `${encodeURIComponent(prefix + key)}=${encodeURIComponent(value.toISOString())}`;
      }
      return buildQueryString(value, `${prefix}${key}.`);
    }
    if (value === undefined || value === null) {
      return `${encodeURIComponent(prefix + key)}=`;
    }
    return `${encodeURIComponent(prefix + key)}=${encodeURIComponent(value.toString())}`;
  });

  return pairs.join('&');
}
