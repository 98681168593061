// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  Autocomplete,
  AutocompleteProps,
  InputAdornment,
  TextField,
} from '@mui/material';
import { ReactElement, ReactNode } from 'react';

// ----------------------------------------------------------------------

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  label?: string;
  placeholder?: string;
  leadingIcon?: ReactNode;
}

export default function RHFAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  name,
  label,
  placeholder,
  leadingIcon,
  ...other
}: Omit<
  Props<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput'
>): ReactElement {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }): ReactElement => (
        <Autocomplete
          {...field}
          onChange={(event, newValue): void =>
            setValue(name, newValue, { shouldValidate: true })
          }
          renderInput={(params): ReactElement => (
            <TextField
              label={label}
              placeholder={placeholder}
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: leadingIcon && (
                  <InputAdornment position="start">
                    {leadingIcon}
                  </InputAdornment>
                ),
              }}
            />
          )}
          {...other}
        />
      )}
    />
  );
}
