import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { FC, ReactElement, useState } from 'react';
import AppButton from '../../../../../../components/shared/AppButton';
import { useFormContext } from 'react-hook-form';
import { BookRideFieldNames } from '../../../../types';
import {
  ServiceTypes,
  useBaseService,
} from '../../../../../../services/useBaseService';
import { ENDPOINTS } from '../../../../../../services/Endpoints';
import { useCustomSnackbar } from '../../../../../../components/snackbar/DescriptionSnackbar';

const AnonymousPaymentCheckoutForm: FC<{
  clientSecret: string;
  onContinue: () => void;
}> = ({ clientSecret, onContinue }): ReactElement => {
  const stripe = useStripe();
  const elements = useElements();
  const { post } = useBaseService({ type: ServiceTypes.PaymentAPI });
  const [isLoading, setIsLoading] = useState(false);

  const { setValue } = useFormContext();

  const { showSnackbar } = useCustomSnackbar();

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true);
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    });

    if (error) {
      if (error.code === 'card_declined') {
        showSnackbar(error.message ?? '', 'error');
      }
      setIsLoading(false);
    } else if (clientSecret) {
      const { setupIntent, error: afterCreationError } =
        await stripe.retrieveSetupIntent(clientSecret);

      if (afterCreationError) {
        console.error(afterCreationError);
      } else if (
        setupIntent?.payment_method &&
        typeof setupIntent?.payment_method === 'string'
      ) {
        const payment_method_by_kaptyn = await post(
          ENDPOINTS.SaveAnonymousPaymentMethod(setupIntent?.payment_method),
          false,
        );
        setValue(BookRideFieldNames.PAYMENT, {
          ...setupIntent,
          payment_method_by_kaptyn: payment_method_by_kaptyn,
        });

        onContinue();
      }
    }
  };
  return (
    <>
      <PaymentElement
        id="payment-element"
        options={{
          layout: 'tabs',
        }}
      />
      <AddressElement options={{ mode: 'billing', allowedCountries: ['US'] }} />
      <AppButton
        title={isLoading ? 'Loading...' : 'Continue'}
        type="button"
        onClick={handleSubmit}
        disabled={isLoading}
        data-cy="paymentBtn"
      />
    </>
  );
};

export default AnonymousPaymentCheckoutForm;
