import { forwardRef, ReactElement } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export interface SvgColorProps extends BoxProps {
  src: string;
}

const SvgIcon = forwardRef<HTMLSpanElement, SvgColorProps>(
  ({ src, width = 24, height = 24, sx, ...other }, ref): ReactElement => (
    <Box
      component="span"
      className="svg-color"
      ref={ref}
      sx={{
        width,
        height,
        display: 'inline-block',
        bgcolor: 'currentColor',
        mask: `url(${src}) no-repeat center / contain`,
        WebkitMask: `url(${src}) no-repeat center / contain`,
        ...sx,
      }}
      {...other}
    />
  ),
);

SvgIcon.displayName = 'SvgIcon';

export default SvgIcon;
