import { ReactElement, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AppFormErrorMgs } from '../constans/ErrorMessages';
import { Box, Grid, Typography } from '@mui/material';
import { TYPOGRAPHY } from '../theme/typography';
import RHFTextField from '../components/shared/formFieldRelated/RHFTextField';
import FormFieldLayout from '../components/shared/formFieldRelated/FormFieldLayout';
import RHFPhoneNumber from '../components/shared/formFieldRelated/RHFPhoneNumber';
import AppButton from '../components/shared/AppButton';
import OrDivider from '../components/shared/OrDivider';
import { ServiceTypes, useBaseService } from '../services/useBaseService';
import { ENDPOINTS } from '../services/Endpoints';
import { useCustomSnackbar } from '../components/snackbar/DescriptionSnackbar';
import { useNavigate } from 'react-router-dom';
import Layout from 'components/shared/Layout';
import { LoadingComponent } from 'components/shared/LoadingComponent';
export interface SignUpFormFields {
  FIRST_NAME: string;
  LAST_NAME: string;
  EMAIL: string;
  PHONENUMBER: string;
  PASSWORD: string;
}

const signUpFieldNames: Record<keyof SignUpFormFields, string> = {
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  EMAIL: 'EMAIL',
  PHONENUMBER: 'PHONENUMBER',
  PASSWORD: 'PASSWORD',
};

const schema: yup.ObjectSchema<SignUpFormFields> = yup.object().shape({
  FIRST_NAME: yup.string().required(AppFormErrorMgs.required),
  LAST_NAME: yup.string().required(AppFormErrorMgs.required),
  EMAIL: yup.string().required(AppFormErrorMgs.required),
  PHONENUMBER: yup
    .string()
    .required(AppFormErrorMgs.required)
    .test(
      'test agaist E164 standard',
      AppFormErrorMgs.invalidPhoneNumber,
      (phonenumber) =>
        // It tests against the E164 phone number international standard
        /^\+[1-9]\d{10,14}$/.test(phonenumber),
    ),
  PASSWORD: yup.string().required(AppFormErrorMgs.required),
});

const SignUpPage = (): ReactElement | null => {
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const { post } = useBaseService({ type: ServiceTypes.API });

  const { showSnackbar } = useCustomSnackbar();

  const navigate = useNavigate();

  const submit = async (formData: SignUpFormFields): Promise<void> => {
    setIsLoading(true);
    post(
      ENDPOINTS.CreateUser,
      false,
      {
        firstName: formData.FIRST_NAME,
        lastName: formData.LAST_NAME,
        email: formData.EMAIL,
        password: formData.PASSWORD,
        phonenumber: formData.PHONENUMBER,
      },
      true,
    )
      .then((data) => {
        setIsLoading(false);
        if (data) {
          showSnackbar('User created succesfully', 'success');
          navigate('/book-ride/step1');
        }
      })
      .catch((e) => {
        setIsLoading(false);
        showSnackbar(e.response?.data.Detail, 'error');
      });
  };

  if(isLoading) return <LoadingComponent />;

  return (
    <Layout>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submit)}>
          <Box
            sx={{
              width: '100%',
              maxWidth: '500px',
              margin: '0 auto',
            }}
          >
            <Typography
              sx={{
                ...TYPOGRAPHY.title1,
              }}
            >
              Sign up
            </Typography>
            <Grid container spacing="10px" mt="20px">
              <Grid item xs={12} md={6}>
                <FormFieldLayout
                  name={signUpFieldNames.FIRST_NAME}
                  label="First name"
                >
                  <RHFTextField
                    name={signUpFieldNames.FIRST_NAME}
                    placeholder="John"
                  />
                </FormFieldLayout>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormFieldLayout
                  name={signUpFieldNames.LAST_NAME}
                  label="Last name"
                >
                  <RHFTextField
                    name={signUpFieldNames.LAST_NAME}
                    placeholder="Doe"
                  />
                </FormFieldLayout>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormFieldLayout name={signUpFieldNames.EMAIL} label="Email">
                  <RHFTextField
                    name={signUpFieldNames.EMAIL}
                    placeholder="doe@email.com"
                  />
                </FormFieldLayout>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormFieldLayout
                  name={signUpFieldNames.PHONENUMBER}
                  label="Phonenumber"
                >
                  <RHFPhoneNumber name={signUpFieldNames.PHONENUMBER} />
                </FormFieldLayout>
              </Grid>
              <Grid item xs={12}>
                <FormFieldLayout
                  name={signUpFieldNames.PASSWORD}
                  label="Password"
                >
                  <RHFTextField
                    name={signUpFieldNames.PASSWORD}
                    type="password"
                    placeholder="e.g. Kappy103!#"
                  />
                </FormFieldLayout>
              </Grid>
            </Grid>
            <Typography
              sx={{
                ...TYPOGRAPHY.tinyText,
                mt: '20px',
              }}
            >
              By selecting Agree and continue, I agree to Kaptyn’s Terms of
              Service and Privacy Policy.
            </Typography>

            <AppButton
              title="Agree and continue"
              additionalSx={{
                mt: '20px',
              }}
              type="submit"
            />
            <OrDivider />
            <AppButton title="Back to homepage" variant="outlined" />
          </Box>
        </form>
      </FormProvider>
    </Layout>
  );
};

export default SignUpPage;
