import { Box, Stack, Typography } from '@mui/material';
import { TYPOGRAPHY } from '../theme/typography';
import { BookRideFieldNames, TripTypes } from '../pages/bookRide/types';
import TabSystem from './shared/TabSystem';
import React, { FC, ReactElement, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { COLORS } from '../theme/pallete';
import { formatToUSD } from '../utils/MoneyConvertion';
import { LegData } from '../shared.types';

type FareTabsTypes = 'leg1' | 'leg2';

interface FareModalItemProps {
  paramsList: LegData[];
}

const FareModalItem: FC<FareModalItemProps> = ({ paramsList }): ReactElement => {
  return (
    <Box>
      {paramsList
        .filter(({ isVisible }) => isVisible || isVisible === undefined)
        .map(({ label, value, isTitle, valueDataCy }) => (
          <FareEstimateModalItem
            key={label + value + valueDataCy}
            label={label}
            value={value}
            valueDataCy={valueDataCy}
            isTitle={isTitle}
          />
        ))}
    </Box>
  );
};

const FareEstimateModalItem: FC<{
  label: string;
  value: string | number;
  isTitle?: boolean;
  valueDataCy?: string;
}> = ({ label, value, isTitle }) => {
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: 'space-between',
        mt: '15px',
        ...((): object | null => {
          return isTitle
            ? {
              marginTop: '20px',
              paddingBottom: '10px',
              borderBottom: `1px solid ${COLORS.GREY_LIGHT_5}`,
            }
            : null;
        })(),
      }}
    >
      <Typography
        sx={{
          ...TYPOGRAPHY.subtitle2,
          ...((): object | null => {
            return isTitle
              ? {
                fontWeight: '600',
              }
              : null;
          })(),
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          ...TYPOGRAPHY.subtitle2,
        }}
      >
        {value && formatToUSD(parseFloat(value as string))}
      </Typography>
    </Stack>
  );
};

interface Props {
  leg1Data: LegData[];
  leg2Data?: LegData[];
}

const CostBreakdown: FC<Props> = ({ leg1Data, leg2Data }): React.ReactElement => {
  const { watch } = useFormContext();

  const [currentFareModalTab, setCurrentFareModalTab] = useState<FareTabsTypes>('leg1');

  const [tripType] = watch([BookRideFieldNames.TRIP_TYPE]);

  const costSummaryLeg1 = <FareModalItem paramsList={leg1Data} />;

  const costSummaryLeg2 = leg2Data ? <FareModalItem paramsList={leg2Data} /> : null;

  return (
    <div>
      {tripType === TripTypes.RoundTrip && (
        <TabSystem
          value={currentFareModalTab}
          tabs={[
            {
              label: 'Trip 1',
              value: 'leg1',
            },
            {
              label: 'Trip 2',
              value: 'leg2',
            },
          ]}
          content={[costSummaryLeg1, costSummaryLeg2]}
          onChange={(event, position): void => {
            setCurrentFareModalTab(position as FareTabsTypes);
          }}
          tabsSx={{
            mt: '20px',
            mb: '10px',
          }}
        />
      )}

      {tripType !== TripTypes.RoundTrip && costSummaryLeg1}
    </div>
  );
};

export default CostBreakdown;
