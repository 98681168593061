export const ENDPOINTS = {
  GetFromLocation: 'Region/FromLocation',
  GetQuote: (serviceProviderVehicleClassId: string): string =>
    `VehicleClass/Quote/${serviceProviderVehicleClassId}`,
  CreatePaymentIntentForAnonymousUser: (riderId: string): string =>
    `Stripe/SetupIntent/Rider/${riderId}`,
  CreatePaymentIntentForLoggedInUser: `Stripe/SetupIntent`,
  CreateRider: 'Rider',
  GetAirlines: 'Flight/Airlines',
  BookRide: 'Reservation',
  BookProgramRide: (businessProgramId: string): string =>
    `Reservation/BusinessProgram/${businessProgramId}`,
  SaveAnonymousPaymentMethod: (stripePaymentMethodId: string): string =>
    `Stripe/PaymentMethod/${stripePaymentMethodId}`,
  CreateUser: 'User',
  SaveLoggedInUserPaymentMethod: (paymentMethodId: string): string =>
    `User/PaymentMethod/${paymentMethodId}`,

  ValidateEmail: (verificationCode: string): string =>
    `User/EmailVerification?verificationCode=${verificationCode}`,
  ResendValidationEmail: `User/EmailVerificationResend?clientApp=reserve`,
  GetProgramPassDetails: (passId: string): string =>
    `BusinessProgram/Pass/${passId}`,
  GetLoggedInUserPaymentMethods: 'User/All',
  GetRiderInvitationInfo: (programId: string, invitationCode: string): string =>
    `BusinessProgram/${programId}/People/Riders/${invitationCode}`,
  AcceptRiderInvitation: (programId: string, invitationCode: string): string =>
    `BusinessProgram/${programId}/People/Riders/${invitationCode}/Accept`,
  GetAllBusinessAccountFromCurrentUser: 'BusinessAccount/Rider/All',
  GetAllBusinessTravelProgramsFromAccount: (accountId: string): string =>
    `BusinessProgram/Rider/All/${accountId}`,
  GetServiceProviderAndVehicles: 'Region/ServiceProviderAndVehicles',
  GetVehicleClassesByProviderId: (serviceProviderId: string): string =>
    `VehicleClass/${serviceProviderId}`,
};
