import dayjs from 'dayjs';
import { TIME_24HRS_FORMAT } from '../../constans/DateRelatedContants';

export const manageTime = ({
  dayJsObj,
  hoursToAdd,
  minutesToSet,
}: {
  dayJsObj: dayjs.Dayjs;
  hoursToAdd?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  minutesToSet?: 0 | 15 | 30 | 45;
}): string => {
  const currentMinutes = dayJsObj.get('minutes');
  let minutesChosen = 0;
  if (currentMinutes >= 0 && currentMinutes < 15) {
    minutesChosen = 15;
  } else if (currentMinutes >= 15 && currentMinutes < 30) {
    minutesChosen = 30;
  } else if (currentMinutes >= 30 && currentMinutes < 45) {
    minutesChosen = 45;
  } else if (currentMinutes >= 45) {
    dayJsObj = dayJsObj.add(1, 'hour');
    minutesChosen = 0;
  }

  dayJsObj = dayJsObj.set('minute', minutesChosen);

  if (hoursToAdd) {
    dayJsObj = dayJsObj.add(2, 'hour');
  }
  if (minutesToSet) {
    dayJsObj = dayJsObj.set('minute', minutesToSet);
  }

  return dayJsObj.format(TIME_24HRS_FORMAT);
};
