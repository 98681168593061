const USE_REMOTE = process.env.REACT_APP_USE_REMOTE === 'true';
export const IS_LOCAL = process.env.REACT_APP_IS_LOCAL === 'true';

// IDENTITY SERVER RELATED
export const IDENTITY_SERVER_URL =
  (USE_REMOTE
    ? process.env.REACT_APP_REMOTE_IDENTITY_SERVER_URL
    : process.env.REACT_APP_IDENTITY_SERVER_URL) || '';
export const IDENTITY_SERVER_LOGIN_REDIRECT =
  process.env.REACT_APP_IDENTITY_SERVER_LOGIN_REDIRECT || '';
export const IDENTITY_SERVER_POST_LOGOUT_REDIRECT_URI =
  process.env.REACT_APP_IDENTITY_SERVER_POST_LOGOUT_REDIRECT_URI || '';
export const AUTH_SERVER_SCOPES =
  'openid profile offline_access account_api.access reserve_api.access payment_api.access user.rider_data user.basic_data ride_api.access';
export const AUTH_SERVER_CLIENT_ID = 'reserve';

export const ACCOUNT_API_URL = USE_REMOTE
  ? process.env.REACT_APP_REMOTE_ACCOUNT_API_BASE_URL
  : process.env.REACT_APP_ACCOUNT_API_BASE_URL;
export const ACCOUNT_API_VERSION =
  process.env.REACT_APP_ACCOUNT_API_API_VERSION;

export const RESERVE_API_URL = USE_REMOTE
  ? process.env.REACT_APP_REMOTE_RESERVE_API_URL
  : process.env.REACT_APP_RESERVE_API_URL;
export const RESERVE_API_VERSION =
  process.env.REACT_APP_RESERVE_API_API_VERSION;

export const RIDE_API_URL = USE_REMOTE
  ? process.env.REACT_APP_REMOTE_RIDE_API_URL
  : process.env.REACT_APP_RIDE_API_URL;
export const RIDE_API_VERSION = process.env.REACT_APP_RIDE_API_API_VERSION;

export const PAYMENT_API_URL = USE_REMOTE
  ? process.env.REACT_APP_REMOTE_PAYMENT_API_URL
  : process.env.REACT_APP_PAYMENT_API_URL;
export const PAYMENT_API_VERSION =
  process.env.REACT_APP_PAYMENT_API_API_VERSION;

export const LOGO_IMAGE =
  process.env.REACT_APP_LOGO === 'Presidential'
    ? '/assets/images/presidential-dark-logo.svg'
    : '/assets/images/kaptyn_logo.svg';

export const LOGO_ALT =
  process.env.REACT_APP_LOGO === 'Presidential'
    ? 'Presidential logo'
    : 'Kaptyn logo';

export const HELP_URL = process.env.REACT_APP_HELP_PAGE_URL || '';
export const SUPPORT_NUMBER =
  process.env.REACT_APP_CALL_SUPPORT_PHONE_NUMBER || '';
export const TERM_PAGE_URL = process.env.REACT_APP_TERM_PAGE_URL || '';
export const PRIVACY_PAGE_URL =
  process.env.REACT_APP_PRIVACY_POLICY_PAGE_URL || '';

export const SESSION_STORAGE_KEYS = {
  RIDER_INVITATION_PROGRAM_ID: 'riderInvitationProgramId',
  RIDER_INVITATION_CODE: 'riderInvitationCode',
};

export const LOCAL_STORAGE_KEYS = {
  LAST_PROGRAM_ID_USED: 'lastProgramIdUsed',
  LAST_ACCOUNT_ID_USED: 'lastBookingAccountId',
  LAST_LOGGED_IN_USER_ID: 'lastLoggedInUserId',
};

export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const CALL_SUPPORT_PHONE_NUMBER =
  process.env.REACT_APP_CALL_SUPPORT_PHONE_NUMBER;
