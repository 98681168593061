import { Typography } from '@mui/material';
import { TYPOGRAPHY } from '../../../../theme/typography';
import FormFieldLayout from '../../../../components/shared/formFieldRelated/FormFieldLayout';
import { BookRideFieldNames, RiderRequest, RiderResponse } from '../../types';
import RHFTextField from '../../../../components/shared/formFieldRelated/RHFTextField';
import { FC, ReactElement, useState } from 'react';
import RHFPhoneNumber from '../../../../components/shared/formFieldRelated/RHFPhoneNumber';
import CardStepItem from './CardStepItem';
import { useFormContext } from 'react-hook-form';
import { ServiceTypes, useBaseService } from 'services/useBaseService';
import { ENDPOINTS } from 'services/Endpoints';

interface Props {
  number: number;
  onContinue: () => void;
  onEdit: () => void;
  isEditing: boolean;
  displayEditButton: boolean;
}

const userInfoFieldNames = [
  BookRideFieldNames.FIRST_NAME,
  BookRideFieldNames.LAST_NAME,
  BookRideFieldNames.EMAIL,
  BookRideFieldNames.PHONENUMBER,
];

const UserInfo: FC<Props> = ({
  onContinue,
  onEdit,
  number,
  isEditing,
  displayEditButton,
}): ReactElement => {
  const { post } = useBaseService({ type: ServiceTypes.AccountAPI });
  const { trigger, watch, setValue } = useFormContext();

  const [firstName, lastName, email, phoneNumber] = watch(userInfoFieldNames);
  const [isLoading, setLoading] = useState(false);

  const validateAndCreateRider = async (): Promise<void> => {
    setLoading(true);
    const fieldsValid = await trigger(userInfoFieldNames);
    if (fieldsValid) {
      try {
        const riderObj = await post<RiderResponse, RiderRequest>(
          ENDPOINTS.CreateRider,
          false,
          {
            firstName,
            lastName,
            email,
            phoneNumber,
          },
        );
        setValue(BookRideFieldNames.RIDER, riderObj);
        onContinue();
      } catch (e) {
        console.error(e);
      }
    }
    setLoading(false);
  };

  return (
    <CardStepItem
      title={`${number}. Your info`}
      onContinue={async (): Promise<void> => await validateAndCreateRider()}
      onEdit={onEdit}
      isEditing={isEditing}
      isLoading={isLoading}
      displayEditButton={displayEditButton}
      containerDataCy="passenger-card"
      titleDataCy="passenger-card-text"
      buttonDataCy="passenger-button"
      editButtonDataCy="passenger-details-edit-btn"
    >
      {!isEditing && firstName && lastName && email && phoneNumber && (
        <>
          <Typography
            sx={{
              ...TYPOGRAPHY.paragraph,
            }}
            data-cy="passenger-card-name"
          >
            {firstName} {lastName}
          </Typography>
          <Typography
            sx={{
              ...TYPOGRAPHY.paragraph,
              mt: '5px!important',
            }}
            data-cy="passenger-card-email"
          >
            {email}
          </Typography>
          <Typography
            sx={{
              ...TYPOGRAPHY.paragraph,
              mt: '5px!important',
            }}
            data-cy="passenger-card-phone"
          >
            {phoneNumber}
          </Typography>
        </>
      )}
      {isEditing && (
        <>
          <FormFieldLayout
            name={BookRideFieldNames.FIRST_NAME}
            label="First name"
            dataCy="first-name"
          >
            <RHFTextField
              name={BookRideFieldNames.FIRST_NAME}
              placeholder="John"
            />
          </FormFieldLayout>

          <FormFieldLayout
            name={BookRideFieldNames.LAST_NAME}
            label="Last name"
            dataCy="last-name"
          >
            <RHFTextField
              name={BookRideFieldNames.LAST_NAME}
              placeholder="Doe"
            />
            <Typography
              sx={{
                ...TYPOGRAPHY.tinyText,
                mt: '5px',
                opacity: 0.6,
              }}
            >
              We’ll send your confirmation email to this address.
            </Typography>
          </FormFieldLayout>

          <FormFieldLayout
            name={BookRideFieldNames.EMAIL}
            label="Email"
            dataCy="email"
          >
            <RHFTextField
              name={BookRideFieldNames.EMAIL}
              placeholder="jdoe@email.com"
            />
          </FormFieldLayout>

          <FormFieldLayout
            name={BookRideFieldNames.PHONENUMBER}
            label="Phonenumber"
            dataCy="phone-number"
          >
            <RHFPhoneNumber name={BookRideFieldNames.PHONENUMBER} />
            <Typography
              sx={{
                ...TYPOGRAPHY.tinyText,
                mt: '5px',
                opacity: 0.6,
              }}
            >
              We’ll send you notifications when your driver is en route to pick
              you up.
            </Typography>
          </FormFieldLayout>
        </>
      )}
    </CardStepItem>
  );
};

export default UserInfo;
