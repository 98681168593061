export const COLORS = {
  BLACK_DARK_1: '#01020f',
  BLACK_DARK_2: '#1A1B1D',

  GREY_LIGHT_1: '#DCDCDC',
  GREY_LIGHT_2: '#dadfdd',
  GREY_LIGHT_3: '#f8f9f8',
  GREY_LIGHT_4: '#F7F7F8',
  GREY_LIGHT_5: '#DCDCDC',
  GREY_LIGHT_6: '#e0e0e0',

  GREY_MEDIUM_1: '#67676f',
  GREY_MEDIUM_2: '#F3F3F3',
  GREY_MEDIUM_3: '#4f5050',
  GREY_MEDIUM_4: '$5f6161',
  GREY_MEDIUM_5: '#F0F1F3',
  GREY_DARK_5: '#313236',

  GREEN_MEDIUM_1: 'rgb(151, 186, 160)',
  GREEN_MEDIUM_2: '#D1F6D9',
  PURPLE_LIGHT_1: '#EDECFC',
  PURPLE_MEDIUM_1: 'rgb(172, 113, 255)',
};
