import { LinearProgress } from '@mui/material';
import { ReactNode } from 'react';

export const LoadingComponent = ({
  display = true,
  verticalMargin = 150,
}: {
  display?: boolean;
  verticalMargin?: number;
}): ReactNode => (
  <LinearProgress
    color="inherit"
    data-cy="loading-component"
    sx={{
      width: 1,
      maxWidth: 360,
      margin: `${verticalMargin}px auto ${verticalMargin}px auto!important`,
      display: display ? 'block' : 'none',
    }}
  />
);
