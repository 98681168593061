import React, { ReactElement } from 'react';
import { Box, SxProps, Tab, Tabs } from '@mui/material';
import { FONT_FAMILY } from '../../theme/typography';
import { COLORS } from '../../theme/pallete';

interface Props {
  value: number | string | false;
  tabs: { label: string; value: number | string; dataCy?: string }[];
  content: React.ReactNode[];
  onChange: (event: React.SyntheticEvent, position: unknown) => void;
  allowDeselection?: boolean;
  tabsSx?: SxProps;
}

interface TabPanelProps {
  children?: React.ReactNode;
  currValue: number | string;
  optionValue: number | string;
}

function CustomTabPanel(props: TabPanelProps): ReactElement {
  const { children, optionValue, currValue } = props;

  return <>{optionValue === currValue && <Box>{children}</Box>}</>;
}

const TabSystem: React.FC<Props> = ({
  value,
  tabs,
  content,
  onChange,
  tabsSx,
  allowDeselection = false,
}) => {
  const deselect = (
    event: React.SyntheticEvent,
    buttonClicked: string,
  ): void => {
    if (allowDeselection && buttonClicked === value.toString()) {
      onChange(event, false);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 'none', ...tabsSx }} data-cy="ride-type-tabs">
        <Tabs
          value={value === false ? value : value.toString()}
          onChange={onChange}
          aria-label="basic tabs example"
          sx={{
            '.MuiTabs-flexContainer': {
              position: 'relative',
              zIndex: 1,
              border: `1px solid ${COLORS.GREY_LIGHT_1}`,
              borderRadius: '12px',
              padding: '4px',
              '.MuiTab-root': {
                textTransform: 'capitalize',
                height: '40px',
                minHeight: 'unset',
                px: '28px',
                fontSize: '16px',
                color: COLORS.BLACK_DARK_1,

                '&.Mui-selected': {
                  color: 'white',
                },
              },
            },

            '.MuiTabs-indicator': {
              backgroundColor: COLORS.BLACK_DARK_2,
              height: '40px',
              borderRadius: '12px',
              minHeight: 'unset',
              top: '0',
              bottom: '0',
              margin: 'auto',
            },
          }}
          variant="fullWidth"
        >
          {tabs.map(({ label, value: val, dataCy }) => (
            <Tab
              key={val}
              data-cy={dataCy}
              label={label}
              value={val.toString()}
              onClick={(event): void => {
                deselect(event, val.toString());
              }}
              sx={{
                fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
              }}
            />
          ))}
        </Tabs>
      </Box>

      {value !== false &&
        content.map((c, index) => (
          <CustomTabPanel
            key={tabs[index].value}
            optionValue={tabs[index].value.toString()}
            currValue={value.toString()}
          >
            {c}
          </CustomTabPanel>
        ))}
    </Box>
  );
};

export default TabSystem;
