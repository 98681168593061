import { Box, Typography } from '@mui/material';
import { TYPOGRAPHY } from '../theme/typography';
import { ReactElement } from 'react';

const MyAccount = (): ReactElement => {
  return (
    <Box>
      <Typography
        sx={{
          ...TYPOGRAPHY.title1,
        }}
      >
        My account
      </Typography>

      <Typography
        sx={{
          ...TYPOGRAPHY.paragraph,
          mt: '30px',
          fontWeight: 'bold',
        }}
      >
        Account details
      </Typography>

      <Typography
        sx={{
          ...TYPOGRAPHY.subtitle2,
        }}
      >
        First name
      </Typography>
      <Typography
        sx={{
          ...TYPOGRAPHY.paragraph,
        }}
      >
        John
      </Typography>

      <Typography
        sx={{
          ...TYPOGRAPHY.subtitle2,
          mt: '20px',
        }}
      >
        Last name
      </Typography>
      <Typography
        sx={{
          ...TYPOGRAPHY.paragraph,
        }}
      >
        Doe
      </Typography>

      <Typography
        sx={{
          ...TYPOGRAPHY.subtitle2,
          mt: '20px',
        }}
      >
        Last name
      </Typography>
      <Typography
        sx={{
          ...TYPOGRAPHY.paragraph,
        }}
      >
        Doe@email.com
      </Typography>

      <Typography
        sx={{
          ...TYPOGRAPHY.subtitle2,
          mt: '20px',
        }}
      >
        Phonenumber
      </Typography>
      <Typography
        sx={{
          ...TYPOGRAPHY.paragraph,
        }}
      >
        555-555-5555
      </Typography>
    </Box>
  );
};

export default MyAccount;
