import React, { Ref, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  SxProps,
  TextField,
  TextFieldProps,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { FONT_FAMILY, TYPOGRAPHY } from '../../../theme/typography';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type Props = TextFieldProps & {
  name: string;
  label?: string;
  type?: 'text' | 'textarea' | 'password' | 'number' | 'email' | 'tel';
  additionalSx?: SxProps;
};

const RHFTextField = React.forwardRef(
  (
    { name, label, type, additionalSx = {}, ...other }: Props,
    ref: Ref<HTMLInputElement>,
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = (): void =>
      setShowPassword((prev) => !prev);
    const { register, trigger } = useFormContext();

    return (
      <TextField
        {...register(name)}
        fullWidth
        onBlur={async (): Promise<void> => {
          await trigger(name);
        }}
        variant="outlined"
        type={type === 'password' ? (showPassword ? 'text' : type) : type}
        multiline={type === 'textarea'}
        label={
          label && (
            <Typography
              sx={{
                typography: 'input',
              }}
              style={{
                color: '#919eab',
              }}
            >
              {label}
            </Typography>
          )
        }
        {...other}
        sx={{
          ...additionalSx,
          '& input::placeholder': {
            color: '#919eab',
          },
          '& .MuiFormHelperText-root': {
            marginLeft: '0',
          },
          fieldset: {
            backgroundColor: '#F7F7F8!important',
            border: 'none',
            zIndex: '-1',
          },
          '& input[type=number]': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            MozAppearance: 'textfield',
          },
        }}
        inputProps={{
          ref,
          style: {
            padding: '3px 13px 0',
            height: type === 'textarea' ? '128px' : '56px',
            boxSizing: 'border-box',
            ...TYPOGRAPHY.formField,
            fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
          },
          ...other.inputProps,
        }}
        InputProps={
          type === 'password'
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : {}
        }
      />
    );
  },
);

RHFTextField.displayName = 'RHFTextField';

export default RHFTextField;
