import App from 'App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';
import { IS_LOCAL } from 'shared.config';
import { AppWhiteLabelingConfiguration } from 'shared.types';
import './generalStyles.css';

let appWhiteLabelingConfiguration: AppWhiteLabelingConfiguration;
if (IS_LOCAL) {
  appWhiteLabelingConfiguration = {
    logoUrl: 'https://via.placeholder.com/150',
    accountId: '123456',
    primaryColor: '#000000',
  };
} else {
  appWhiteLabelingConfiguration = (
    window as unknown as Window & {
      whiteLabelingConfig: AppWhiteLabelingConfiguration;
    }
  ).whiteLabelingConfig;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App whiteLabelingConfig={appWhiteLabelingConfiguration} />,
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
