import { Box, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FC, useEffect, useState } from 'react';
import SvgIcon from '../../../../../components/shared/SvgIcon';
import { COLORS } from '../../../../../theme/pallete';
import AppButton from '../../../../../components/shared/AppButton';
import { BookRideFieldNames } from '../../../types';
import WrapperRHFTabbedTips from './TabbedTips/WrapperRHFTabbedTips';
import { FONT_FAMILY, TYPOGRAPHY } from '../../../../../theme/typography';

interface Props {
  autoGratuityPercentage?: number | null;
}

const PageNewRideAutoGratuity: FC<Props> = () => {
  const { watch, setValue, trigger } = useFormContext();

  const [businessTravelProgram, businessTravelAccount] = watch([
    BookRideFieldNames.BUSINESS_TRAVEL_PROGRAM,
    BookRideFieldNames.BUSINESS_TRAVEL_ACCOUNT,
  ]);

  const [additionalGratuityBtnClicked, setAdditionalGratuityBtnClicked] = useState(
    !businessTravelProgram?.autoGratuityPercentage,
  );

  useEffect(() => {
    if (!businessTravelAccount) {
      setAdditionalGratuityBtnClicked(true);
    } else if (businessTravelAccount && !businessTravelProgram) {
      setAdditionalGratuityBtnClicked(false);
    } else if (businessTravelAccount && businessTravelProgram?.autoGratuityPercentage) {
      setAdditionalGratuityBtnClicked(false);
    } else if (businessTravelAccount && !businessTravelProgram?.autoGratuityPercentage) {
      setAdditionalGratuityBtnClicked(true);
    }
  }, [businessTravelAccount, businessTravelProgram]);

  useEffect(() => {
    if (!additionalGratuityBtnClicked || (businessTravelAccount && !businessTravelProgram)) {
      setValue('EXTRA_GRATUITY', {
        tabValue: false,
        percentage: false,
      });

      trigger(BookRideFieldNames.EXTRA_GRATUITY);
    }
  }, [
    additionalGratuityBtnClicked,
    setValue,
    trigger,
    businessTravelAccount,
    businessTravelProgram,
  ]);

  return (
    <>
      <Box
        sx={{
          p: '20px',
        }}
      >
        <Typography
          sx={{
            ...TYPOGRAPHY.title4,
          }}
          data-cy="gratuity-label"
        >
          Gratuity
        </Typography>
        {(businessTravelProgram?.autoGratuityPercentage) && (
          <Stack
            direction="row"
            sx={{
              backgroundColor: COLORS.GREEN_MEDIUM_2,

              borderRadius: '16px;',
              p: '16px',

              mt: '15px',
            }}
          >
            <SvgIcon
              src="/assets/icons/icon_greenCheckMark.svg"
              color="#058421"
              width="35px"
              height="35px"
            />
            <Typography
              sx={{
                ml: '16px;',
                fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
              }}
            >
              A{' '}
              {businessTravelProgram.autoGratuityPercentage}
              % automatic gratuity has been applied to your ride. Thank you for supporting your
              driver!
            </Typography>
          </Stack>
        )}

        {!additionalGratuityBtnClicked && (
          <Box
            sx={{
              display: 'inline-flex',
            }}
          >
            <AppButton
              title="Additional gratuity"
              variant="outlined"
              startIcon={<SvgIcon src="/assets/icons/icon_plus.svg" width="18px" />}
              onClick={(): void => {
                setAdditionalGratuityBtnClicked(true);
              }}
              additionalSx={{
                mt: '15px',
              }}
            />
          </Box>
        )}

        {additionalGratuityBtnClicked && (
          <Box
            sx={{
              mt: '15px',
            }}
          >
            <WrapperRHFTabbedTips
              allowDeselection
              autoGratuityPercentage={businessTravelProgram?.autoGratuityPercentage}
            />
          </Box>
        )}
        <Typography
          sx={{
            fontSize: '14px',
            mt: '12px',
            color: COLORS.GREY_MEDIUM_4,
            fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
          }}
        >
          100% of the tip goes to your driver.
        </Typography>
      </Box>
    </>
  );
};

export default PageNewRideAutoGratuity;
