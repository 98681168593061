import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { FC, ReactElement, useState } from 'react';
import AppButton from '../../../../../../components/shared/AppButton';

import {
  ServiceTypes,
  useBaseService,
} from '../../../../../../services/useBaseService';
import { ENDPOINTS } from '../../../../../../services/Endpoints';
import { useCustomSnackbar } from '../../../../../../components/snackbar/DescriptionSnackbar';
import { Stack } from '@mui/material';
import { PaymentMethodResponseByKaptyn } from 'pages/bookRide/types';

const LoggedInUserPaymentCheckoutForm: FC<{
  clientSecret: string;
  closeModal: () => void;
  addAndSelectPaymentMethod: (
    paymentMethod: PaymentMethodResponseByKaptyn,
  ) => void;
}> = ({
  clientSecret,
  closeModal,
  addAndSelectPaymentMethod,
}): ReactElement => {
  const stripe = useStripe();
  const elements = useElements();
  const { post } = useBaseService({ type: ServiceTypes.PaymentAPI });
  const [isLoading, setIsLoading] = useState(false);

  const { showSnackbar } = useCustomSnackbar();

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true);
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    });

    if (error) {
      if (error.code === 'card_declined') {
        showSnackbar(error.message ?? '', 'error');
      }
      setIsLoading(false);
    } else if (clientSecret) {
      const { setupIntent, error: afterCreationError } =
        await stripe.retrieveSetupIntent(clientSecret);

      if (afterCreationError) {
        console.error(afterCreationError);
      } else if (
        setupIntent?.payment_method &&
        typeof setupIntent?.payment_method === 'string'
      ) {
        try {
          const paymentMethod: PaymentMethodResponseByKaptyn | null =
            await post(
              ENDPOINTS.SaveLoggedInUserPaymentMethod(
                setupIntent?.payment_method,
              ),
              true,
            );
          if (paymentMethod === null) throw new Error('Payment method is null');

          addAndSelectPaymentMethod(paymentMethod);
          closeModal();
        } catch {
          setIsLoading(false);
        }
      }
    }
  };
  return (
    <>
      <PaymentElement
        id="payment-element"
        options={{
          layout: 'tabs',
        }}
      />
      <AddressElement options={{ mode: 'billing', allowedCountries: ['US'] }} />
      <Stack
        direction="row"
        sx={{
          display: 'inline-flex',
          mt: '20px',
        }}
        spacing="10px"
      >
        <AppButton
          title={isLoading ? 'Loading...' : 'Save card'}
          type="button"
          onClick={handleSubmit}
          disabled={isLoading}
          additionalSx={{
            minWidth: '100px',
            borderRadius: '20px',
            whiteSpace: 'nowrap',
          }}
          data-cy="paymentBtn"
        />
        <AppButton
          title="Close"
          type="button"
          onClick={closeModal}
          disabled={isLoading}
          additionalSx={{
            borderRadius: '20px',
          }}
        />
      </Stack>
    </>
  );
};

export default LoggedInUserPaymentCheckoutForm;
