import {
  BookReservationResponseType,
  BookRideResponseType,
  TripTypes,
} from 'pages/bookRide/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

type PayloadFromRide = {
  id: string;
  trip_pickup: string;
  trip_dropoff: string;
  market: string;
  revenue: number;
  tax: number;
  trip_fees: number;
  discount: number;
  trip_type: string;
  trip_hourly: string;
  trip_passengers: string;
  trip_luggage: string;
  flight_airline: string;
  flight_origin: string;
  flight_terminal: string;
  cc_type: string;
  is_registered: string;
  service: string;
  service_id: string;
  price: number;
  country_of_origin: string;
  affiliation: string;
  currency: string;
  shipping: number;
  trip_booked: string;
  trip_pickup_year: string;
  trip_pickup_month: string;
  trip_pickup_day: string;
  trip_pickup_time: string;
  trip_booked_year: string;
  trip_booked_month: string;
  trip_booked_day: string;
  trip_booked_time: string;
  is_first_trip: boolean;
};

export function trackPurchase(
  ride: BookRideResponseType,
  reservation: BookReservationResponseType,
): void {
  const payload = mapRidePurchase(ride, reservation);

  const items = [];

  // track products: ride
  items.push({
    item_name: payload.service,
    item_id: payload.service_id,
    item_coupon: '', // coupon codes used for rides
    price: payload.price,
    quantity: 1,
    item_brand: 'Kaptyn',
    item_variant: '',
    item_category: 'Ride',
    discount: payload.discount,
    affiliation: '',
    item_category2: '',
    item_category3: '',
    item_category4: '',
  });

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: payload.id,
      value: payload.revenue,
      affiliation: payload.affiliation,
      coupon: '',
      tax: payload.tax,
      currency: payload.currency,
      shipping: payload.shipping,

      // Custom Dimension
      trip_fees: payload.trip_fees,
      trip_type: payload.trip_type,
      trip_booked: payload.trip_booked,
      trip_booked_year: payload.trip_booked_year,
      trip_booked_month: payload.trip_booked_month,
      trip_booked_day: payload.trip_booked_day,
      trip_booked_time: payload.trip_booked_time,
      trip_pickup: payload.trip_pickup,
      trip_pickup_year: payload.trip_pickup_year,
      trip_pickup_month: payload.trip_pickup_month,
      trip_pickup_day: payload.trip_pickup_day,
      trip_pickup_time: payload.trip_pickup_time,
      trip_dropoff:
        reservation.tripType === TripTypes.Hourly
          ? 'As Directed'
          : payload.trip_dropoff,
      trip_hourly: payload.trip_hourly,
      trip_passengers: payload.trip_passengers,
      trip_destinations: reservation.tripType === TripTypes.Hourly ? '' : 0,
      trip_luggage: payload.trip_luggage,
      flight_airline: payload.flight_airline,
      flight_origin: payload.flight_origin,
      flight_terminal: payload.flight_terminal,

      // Custom User Dimension
      country_of_origin: payload.country_of_origin,
      cc_type: payload.cc_type,
      is_registered: payload.is_registered,
      // Items
      items: items,
      market: ride.region,
      brand: process.env.REACT_APP_BRAND,
    },
  });
}

function mapRidePurchase(
  ride: BookRideResponseType,
  reservation: BookReservationResponseType,
): PayloadFromRide {
  const currentDateTime = new Date();
  const tripBookedDateTime = {
    date: currentDateTime.toISOString().split('T')[0],
    time: currentDateTime
      .toISOString()
      .split('T')[1]
      .split('.')[0]
      .substring(0, 5),
  };

  const totalSurcharges = ride.rideSurcharges.reduce(
    (acc, surcharge) => acc + surcharge.amount,
    0,
  );

  const stringTripType =
    reservation.tripType === TripTypes.OneWay
      ? 'D'
      : reservation.tripType === TripTypes.RoundTrip
        ? 'R'
        : 'H';

  return {
    id: ride.rideNumber,
    trip_pickup: ride.startLocationDescription,
    trip_dropoff: ride.destLocationDescription || '',
    market: ride.region,
    revenue: ride.total,
    tax: ride.tax,
    trip_fees: totalSurcharges,
    discount: 0,
    trip_type: stringTripType,
    trip_hourly: ride.estimatedTimeInHours
      ? (ride.estimatedTimeInHours * 60).toString()
      : '',
    trip_passengers: '1',
    trip_luggage: '0',
    flight_airline: ride.airlineId || '',
    flight_origin: '',
    flight_terminal: '',
    cc_type: '',
    is_registered: reservation.userId ? 'true' : 'false',
    service: ride.vehicleClassName,
    service_id: ride.serviceProvider_vehicleClassId,
    price: ride.total,
    country_of_origin: '',
    affiliation: '',
    currency: 'USD',
    shipping: 0,
    trip_booked: ride.startLocationDescription,
    trip_pickup_year: ride.scheduledTime.split('-')[0],
    trip_pickup_month: ride.scheduledTime.split('-')[1],
    trip_pickup_day: ride.scheduledTime.split('-')[2],
    trip_pickup_time: ride.scheduledTime.split('T')[1].split('.')[0],
    trip_booked_year: tripBookedDateTime.date.split('-')[0],
    trip_booked_month: tripBookedDateTime.date.split('-')[1],
    trip_booked_day: tripBookedDateTime.date.split('-')[2],
    trip_booked_time: tripBookedDateTime.time,
    is_first_trip: false,
  };
}
