import React, { FC, ReactElement } from 'react';
import {
  CountryIso2,
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import 'react-international-phone/style.css';
import { Controller, useFormContext } from 'react-hook-form';
import {
  BaseTextFieldProps,
  Box,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

interface Props {
  name: string;
}

export interface MUIPhoneProps extends BaseTextFieldProps {
  value: string;
  onChange: (phone: string) => void;
}
const MuiPhone: React.FC<MUIPhoneProps> = ({
  value,
  onChange,
  ...restProps
}): ReactElement => {
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: 'us',
      value,
      countries: defaultCountries,
      disableDialCodeAndPrefix: true,
      onChange: (data) => {
        onChange(data.phone);
      },
    });

  return (
    <TextField
      variant="filled"
      // label="Phone number"
      color="primary"
      placeholder="Phone number"
      value={inputValue}
      onChange={handlePhoneValueChange}
      type="tel"
      inputRef={inputRef}
      sx={{
        height: '56px',
        input: {
          height: '56px',
          padding: '0!important',
          fontSize: '14px!important',
        },
        '.MuiInputAdornment-root': {
          py: 0,
          my: '0!important',
          height: '100%',
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ marginRight: '2px', marginLeft: '-8px' }}
          >
            <Select
              MenuProps={{
                style: {
                  height: '300px',
                  width: '360px',
                  top: '10px',
                  left: '-34px',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              sx={{
                width: 'max-content',

                // Remove default outline (display only on focus)
                fieldset: {
                  display: 'none',
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: 'block',
                  },
                },
                // Update default spacing
                '.MuiSelect-select': {
                  padding: '8px',
                  paddingRight: '24px !important',
                },
                svg: {
                  right: 0,
                },
              }}
              value={country.iso2}
              onChange={(e): void => setCountry(e.target.value as CountryIso2)}
              renderValue={(value_v): ReactElement => (
                <FlagImage iso2={value_v} style={{ display: 'flex' }} />
              )}
            >
              {defaultCountries.map((c) => {
                const country_V = parseCountry(c);
                return (
                  <MenuItem key={country_V.iso2} value={country_V.iso2}>
                    <FlagImage
                      iso2={country_V.iso2}
                      style={{ marginRight: '8px' }}
                    />
                    <Typography marginRight="8px">{country_V.name}</Typography>
                    <Typography color="gray">+{country_V.dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
};
const RHFPhoneNumber: FC<Props> = ({ name }): ReactElement => {
  const { control } = useFormContext();

  return (
    <Box
      sx={{
        input: {
          height: '56px!important',
          border: 'none!important',
          backgroundColor: '#F7F7F8!important',
          borderRadius: '0 8px 8px 0px!important',
        },
        '.react-international-phone-country-selector-button': {
          height: '100%',
          backgroundColor: '#F7F7F8!important',
          padding: '0 5px',
          border: 'none!important',
          borderRadius: '8px 0 0 8px!important',
          zIndex: 100,
        },
        '.MuiFormControl-root': {
          width: '100%',
        },
        '.MuiInputBase-root:after, .MuiInputBase-root:before': {
          display: 'none',
        },
      }}
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }): ReactElement => (
          <MuiPhone value={value} onChange={onChange} />
        )}
      />
    </Box>
  );
};

export default RHFPhoneNumber;
