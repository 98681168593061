import { Box, SxProps } from '@mui/material';
import FieldLabel from './FieldLabel';
import ErrorMessage from './ErrorMessage';
import React, { FC, PropsWithChildren, ReactElement } from 'react';

interface Props {
  name: string;
  label: string;
  sx?: SxProps;
  dataCy?: string;
}
const FormFieldLayout: FC<PropsWithChildren<Props>> = ({
  name,
  label,
  children,
  sx,
  dataCy,
}): ReactElement => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        ...sx,
      }}
      data-cy={dataCy}
    >
      <FieldLabel label={label} />
      {children}
      <ErrorMessage name={name} />
    </Box>
  );
};

export default FormFieldLayout;
