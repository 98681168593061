import React, { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import { LinearProgress } from '@mui/material';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 9998,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function LoadingScreen(): ReactElement {
  return (
    <StyledRoot>
      <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
    </StyledRoot>
  );
}
