import { Stack, Typography } from '@mui/material';
import { TYPOGRAPHY } from '../../../../theme/typography';
import AppButton from '../../../../components/shared/AppButton';
import React, { FC, PropsWithChildren, ReactElement } from 'react';
import EditButton from './EditButton';

interface Props {
  title: string;
  onContinue: () => void;
  onEdit: () => void;
  isEditing: boolean;
  displayEditButton?: boolean;
  displayConfirmButton?: boolean;
  isLoading?: boolean;
  isValid?: Promise<boolean> | boolean;
  containerDataCy?: string;
  titleDataCy?: string;
  buttonDataCy?: string;
  editButtonDataCy?: string;
}

const CardStepItem: FC<PropsWithChildren<Props>> = ({
  title,
  children,
  onContinue,
  onEdit,
  isEditing,
  displayEditButton = true,
  displayConfirmButton: displayButton = true,
  isLoading = false,
  containerDataCy,
  titleDataCy,
  buttonDataCy,
  editButtonDataCy,
}): ReactElement => {
  return (
    <Stack spacing="10px" data-cy={containerDataCy}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          sx={{
            ...TYPOGRAPHY.title2,
          }}
          data-cy={titleDataCy}
        >
          {title}
        </Typography>

        {displayEditButton && !isEditing && (
          <EditButton onClick={onEdit} dataCy={editButtonDataCy} />
        )}
      </Stack>

      {children}
      {isEditing && displayButton && (
        <AppButton
          title={isLoading ? 'Loading...' : 'Continue'}
          additionalSx={{
            marginTop: '35px!important',
          }}
          type="button"
          onClick={onContinue}
          disabled={isLoading}
          data-cy={buttonDataCy}
        />
      )}
    </Stack>
  );
};

export default CardStepItem;
