import { useAuthContext } from "auth/useAuthContext";
import { LoadingComponent } from "components/shared/LoadingComponent";
import { ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ENDPOINTS } from "services/Endpoints";
import { ServiceTypes, useBaseService } from "services/useBaseService";
import { LOCAL_STORAGE_KEYS } from "shared.config";
import { RiderInvitationAcceptedType } from "./types";
import { Box, Button, Typography } from "@mui/material";

export default function RiderInvitationAccept(): ReactElement | null {
    const { programId, invitationCode } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { user, updateUserClaims } = useAuthContext();
    const { put } = useBaseService({ type: ServiceTypes.AccountAPI });
    const [invitationAccepted, setInvitationAccepted] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        async function init(): Promise<void> {
            if (programId && invitationCode && user && !isLoading && !invitationAccepted) {
                setIsLoading(true);
                try {
                    const invitationAccepted = await put<RiderInvitationAcceptedType, null>(ENDPOINTS.AcceptRiderInvitation(programId, invitationCode), true);
                    if (invitationAccepted) {
                        await updateUserClaims();
                        localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_PROGRAM_ID_USED, invitationAccepted?.businessProgramId);
                        localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_ACCOUNT_ID_USED, invitationAccepted?.businessAccountId);
                        localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_LOGGED_IN_USER_ID, user.user_id);
                        setInvitationAccepted(true);
                    }
                    setIsLoading(false);
                } catch (err) {
                    console.error(err);
                    setTimeout(() => {
                        navigate('/');
                    }, 5000);
                }
            }
        }
        init();
    }, [invitationCode, programId, user, put, updateUserClaims, isLoading, invitationAccepted, navigate]);

    if (isLoading)
        return <LoadingComponent />;

    if (!isLoading && invitationAccepted) {
        return (
            <Box
                sx={{
                    marginTop: "16px",
                    margin: "0 auto",
                    maxWidth: "500px",
                    width: "100%",
                    padding: "16px",
                }}
            >
                <Typography
                    style={{
                        fontSize: "42px",
                    }}
                    data-cy="rider-invitation-accepted-text"
                >
                    You’re all set!
                </Typography>
                <Typography
                    style={{
                        fontSize: "16px",
                        marginTop: "10px",
                    }}
                >
                    When riding for work, remember to sign in with your business account,
                    and select the program you want to book for in checkout.
                </Typography>

                <img
                    alt="you are all set"
                    src="/assets/images/youAllSet.jpg"
                    style={{
                        maxWidth: "544px",
                        width: "100%",
                        marginTop: "32px",
                    }}
                />

                <Button
                    onClick={(): void => {
                        navigate('/');
                    }}
                    style={{
                        marginTop: "16px",
                        borderRadius: "16px",
                        padding: "10px",
                        width: "unset",
                        backgroundColor: "#1A1B1D",
                        color: "#fff",
                        fontSize: "16px",
                        textTransform: "none",
                    }}
                    data-cy="rider-invitation-accepted-button"
                >
                    Reserve a ride
                </Button>
            </Box>
        );
    }

    return null;
}