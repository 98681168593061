import React, { ReactElement, useCallback, useContext, useState } from "react";
import { ProgramPassType } from "./bookRide/types";
import { ErrorData, ServiceTypes, useBaseService } from "services/useBaseService";
import { InvalidPassModal } from "./bookRide/invalidPassModal";
import { ENDPOINTS } from "services/Endpoints";
import { AxiosError } from "axios";
import { useNavigate } from "react-router";

type ProgramPassTypeContext = {
    programPass?: ProgramPassType;
    isLoading: boolean;
    loadPassDetails: (code: string) => Promise<void>;
};

const ProgramPassContext: React.Context<ProgramPassTypeContext> =
    React.createContext({ programPass: undefined } as ProgramPassTypeContext);

export const useProgramPassContext = (): ProgramPassTypeContext =>
    useContext(ProgramPassContext);

export const ProgramPassProvider = ({ children }: { children: React.ReactNode }): ReactElement => {
    const { get } = useBaseService({ type: ServiceTypes.API });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [invalidPassCodeMsg, setInvalidPassCodeMsg] = useState<string>();
    const [invalidPassCodeTitle, setInvalidPassCodeTitle] = useState<string>();
    const [programPass, setProgramPass] = useState<ProgramPassType>();
    const navigate = useNavigate();

    const loadPassDetails = useCallback(async (code: string) => {
        setIsLoading(true);

        try {
            const programPass: ProgramPassType = await get(
                ENDPOINTS.GetProgramPassDetails(code),
                false,
                false,
                true,
            );

            setProgramPass(programPass);
        } catch (error) {
            const mappedError = error as AxiosError<ErrorData>;
            const title =
                mappedError.response?.data?.Title || mappedError.response?.data?.title;
            const detail =
                mappedError.response?.data?.Detail ||
                mappedError.response?.data?.detail;
            if (mappedError.response?.status === 400 && title && detail) {
                setInvalidPassCodeTitle(title);
                setInvalidPassCodeMsg(detail);
            } else {
                navigate('/book-ride/step1');
            }
        } finally {
            setIsLoading(false);
        }
    }, [get, navigate]);

    if (invalidPassCodeMsg && invalidPassCodeTitle)
        return (
            <InvalidPassModal
                invalidPassTitle={invalidPassCodeTitle}
                invalidPassCodeMsg={invalidPassCodeMsg}
            />
        );

    return (
        <ProgramPassContext.Provider
            value={{
                programPass,
                isLoading,
                loadPassDetails,
            }}
        >
            {children}
        </ProgramPassContext.Provider>
    )
}