import React, { FC, useCallback, useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';

import AnonymousPaymentCheckoutForm from './AnonymousPaymentCheckoutForm';
import {
  ServiceTypes,
  useBaseService,
} from '../../../../../../services/useBaseService';
import { ENDPOINTS } from '../../../../../../services/Endpoints';
import { useFormContext } from 'react-hook-form';
import { BookRideFieldNames } from '../../../../types';
import { useStripeContext } from 'pages/StripeContext';

interface Props {
  onContinue: () => void;
}

const AnonymousUserPayment: FC<Props> = ({ onContinue }) => {
  const { post } = useBaseService({ type: ServiceTypes.PaymentAPI });
  const { watch } = useFormContext();
  const [clientSecret, setClientSecret] = useState('');
  const { stripePromise } = useStripeContext();

  const [rider] = watch([BookRideFieldNames.RIDER]);

  const setClientSecretFromIntent = useCallback(async () => {
    if (rider?.id) {
      const gottenClientSecret = await post(
        ENDPOINTS.CreatePaymentIntentForAnonymousUser(rider.id),
        false,
      );
      setClientSecret((gottenClientSecret as string) ?? '');
    }
  }, [post, rider?.id]);

  useEffect(() => {
    if (!clientSecret && rider) {
      setClientSecretFromIntent();
    }
  }, [setClientSecretFromIntent, clientSecret, rider]);

  return (
    <>
      {clientSecret && (
        <Elements
          stripe={stripePromise}
          key={clientSecret}
          options={{
            clientSecret,
            appearance: {
              theme: 'stripe',
              variables: {
                colorPrimary: '#0570de',
                colorBackground: '#F7F7F8',
                colorText: 'black',
                colorDanger: '#df1b41',
                // fontFamily: 'chromatic-pro-regular, sans-serif',
                spacingUnit: '2px',
                borderRadius: '4px',
                // See all possible variables below
              },
              rules: {
                '.Label': {
                  opacity: '1',
                  color: '#67676f',
                  fontSize: '12px',
                  marginTop: '10px',
                  marginBottom: '5px',
                },
                '.Input': {
                  padding: '19px 10px',
                  border: 'none',
                  outline: 'none',
                  boxShadow: 'none',
                },
                '.Input::placeholder': {
                  color: 'rgba(165,165,165,0.87)',
                },
              },
            },
          }}
        >
          <AnonymousPaymentCheckoutForm
            clientSecret={clientSecret}
            onContinue={onContinue}
          />
        </Elements>
      )}
    </>
  );
};

export default AnonymousUserPayment;
