import { Box, MenuItem } from '@mui/material';
import React, { ReactElement, useState } from 'react';

import { BookRideFieldNames } from '../../types';

import PlacesAutocomplete from '../../../../components/shared/formFieldRelated/PlacesAutocomplete';
import AppButton from '../../../../components/shared/AppButton';
import AppDatePicker from '../../../../components/shared/formFieldRelated/AppDatePicker/AppDatePicker';
import { CustomTimePicker } from '../../../../components/shared/formFieldRelated/CustomTimePicker';
import { RHFSelect } from '../../../../components/shared/formFieldRelated/RHFSelect';
import { COLORS } from '../../../../theme/pallete';
import { TYPOGRAPHY } from '../../../../theme/typography';
import FormFieldLayout from '../../../../components/shared/formFieldRelated/FormFieldLayout';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { convertLocationTypes } from 'utils/locations';
import { AddressTypes, LocationDetailsType } from 'shared.types';
import dayjs from 'dayjs';
import { useProgramPassContext } from 'pages/ProgramPassContext';

const regularCellsStyles = {
  flex: {
    xs: 'none',
    md: '300px 1 1',
  },
};

const HourlyForm = ({
  isGettingServiceProvider,
}: {
  isGettingServiceProvider: boolean;
}): ReactElement => {
  const navigate = useNavigate();
  const { trigger, watch, setValue, getValues } = useFormContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { programPass } = useProgramPassContext();

  const allowedPickUpAddresses =
    programPass &&
    programPass.businessProgram.allowedAddresses &&
    programPass.businessProgram.allowedAddresses.filter(
      (address) => address.allowPickUp,
    );
  const mappedAllowedPickUpAddresses =
    allowedPickUpAddresses &&
    convertLocationTypes(allowedPickUpAddresses, AddressTypes.PickUp);

  const rideValidFrom =
    programPass && dayjs(programPass?.businessProgram.passRideValidFrom);
  const rideValidTo =
    programPass && dayjs(programPass?.businessProgram.passRideValidTo);

  const selectedPickUpAddress: LocationDetailsType = watch(
    BookRideFieldNames.PICKUP_ADDRESS,
  );
  let referenceEqualityPickUpAddress: LocationDetailsType | string | undefined =
    '';
  if (selectedPickUpAddress)
    referenceEqualityPickUpAddress = mappedAllowedPickUpAddresses?.find(
      (address) =>
        address.googlePlacesId === selectedPickUpAddress.googlePlacesId,
    );

  const next = async (): Promise<void> => {
    setIsSubmitting(true);
    const targetFields = [
      BookRideFieldNames.PICKUP_ADDRESS,
      BookRideFieldNames.ESTIMATED_TIME_IN_HOURS,
      BookRideFieldNames.PICKUP_DATE,
      BookRideFieldNames.PICKUP_TIME,
    ];
    const isValid = await trigger(targetFields);

    if (isValid) {
      const queryParams = new URLSearchParams({
        rideType: getValues(BookRideFieldNames.TRIP_TYPE),
        fromAddressPlaceId: getValues(BookRideFieldNames.PICKUP_ADDRESS)
          .googlePlacesId,
        fromAddressDescription: getValues(BookRideFieldNames.PICKUP_ADDRESS)
          .address,
        duration: getValues(BookRideFieldNames.ESTIMATED_TIME_IN_HOURS),
        pickUpDate: getValues(BookRideFieldNames.PICKUP_DATE),
        pickUpTime: getValues(BookRideFieldNames.PICKUP_TIME),
      });

      const step2PassUrl =
        `/pass/${programPass?.id}/step2` + '?' + queryParams.toString();
      const step2NormalUrl = `/book-ride/step2` + '?' + queryParams.toString();

      if (programPass) {
        navigate(step2PassUrl);
      } else {
        navigate(step2NormalUrl);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'stretch',
          gap: '8px',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <FormFieldLayout
          name={BookRideFieldNames.PICKUP_ADDRESS}
          label="Pickup location"
          sx={{
            ...regularCellsStyles,
            overflow:
              mappedAllowedPickUpAddresses &&
              mappedAllowedPickUpAddresses.length > 0
                ? 'hidden'
                : 'visible',
          }}
          dataCy="hourly-pickup-location"
        >
          {mappedAllowedPickUpAddresses &&
            mappedAllowedPickUpAddresses.length > 0 && (
              <RHFSelect
                name={BookRideFieldNames.PICKUP_ADDRESS}
                sx={{
                  '.MuiSelect-select': {
                    height: '48px!important',
                    backgroundColor: COLORS.GREY_MEDIUM_2,
                    borderRadius: '10px',
                    ...TYPOGRAPHY.formField,
                  },
                }}
                value={referenceEqualityPickUpAddress}
                onChange={(e): void => {
                  setValue(BookRideFieldNames.PICKUP_ADDRESS, e.target.value);
                }}
                rendervalue={(obj: unknown): string => {
                  return (
                    (obj as { address: string })?.address ?? (
                      <div
                        style={{
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        Select
                      </div>
                    )
                  );
                }}
                dataCy="pickup-address-select"
              >
                {mappedAllowedPickUpAddresses.map((address, i) => (
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  <MenuItem key={i} value={address as any}>
                    {address.address}
                  </MenuItem>
                ))}
              </RHFSelect>
            )}
          {(!mappedAllowedPickUpAddresses ||
            mappedAllowedPickUpAddresses.length === 0) && (
            <PlacesAutocomplete
              name={BookRideFieldNames.PICKUP_ADDRESS}
              placeholder="Pickup location"
              addressType={AddressTypes.PickUp}
              dataCy="pickup-address-autocomplete"
            />
          )}
        </FormFieldLayout>

        <FormFieldLayout
          name={BookRideFieldNames.ESTIMATED_TIME_IN_HOURS}
          label="Duration"
          sx={regularCellsStyles}
          dataCy="hourly-duration"
        >
          <RHFSelect
            name={BookRideFieldNames.ESTIMATED_TIME_IN_HOURS}
            sx={{
              '.MuiSelect-select': {
                height: '48px!important',
                backgroundColor: COLORS.GREY_MEDIUM_2,
                borderRadius: '10px',
                ...TYPOGRAPHY.formField,
              },
            }}
          >
            <MenuItem value="1">1 Hour</MenuItem>
            <MenuItem value="2">2 Hours</MenuItem>
            <MenuItem value="3">3 Hours</MenuItem>
            <MenuItem value="4">4 Hours</MenuItem>
            <MenuItem value="5">5 Hours</MenuItem>
            <MenuItem value="6">6 Hours</MenuItem>
            <MenuItem value="7">7 Hours</MenuItem>
            <MenuItem value="8">8 Hours</MenuItem>
          </RHFSelect>
        </FormFieldLayout>

        <FormFieldLayout
          name={BookRideFieldNames.PICKUP_DATE}
          label="Pickup date"
          sx={regularCellsStyles}
          dataCy="hourly-pickup-date"
        >
          <AppDatePicker
            name={BookRideFieldNames.PICKUP_DATE}
            minDate={rideValidFrom}
            maxDate={rideValidTo}
          />
        </FormFieldLayout>

        <FormFieldLayout
          name={BookRideFieldNames.PICKUP_TIME}
          label="Pickup time"
          sx={regularCellsStyles}
          dataCy="hourly-pickup-time"
        >
          <CustomTimePicker name={BookRideFieldNames.PICKUP_TIME} />
        </FormFieldLayout>

        <Box
          sx={{
            ...regularCellsStyles,
            flex: 'auto 0 1',
            mt: '21px',
          }}
        >
          <AppButton
            title={
              isSubmitting || isGettingServiceProvider
                ? 'Searching...'
                : 'Show rides'
            }
            additionalSx={{
              width: '120px',
              borderRadius: '20px',
              mt: '2px',
            }}
            type="button"
            onClick={next}
            data-cy="showRidesBtn"
            disabled={isSubmitting || isGettingServiceProvider}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HourlyForm;
