import { Button, Modal } from "@mui/material";
import SvgIcon from "components/shared/SvgIcon";
import { ReactElement } from "react";

export const InvalidPassModal =
    ({ invalidPassCodeMsg,
        invalidPassTitle }:
        {
            invalidPassCodeMsg: string;
            invalidPassTitle: string;
        }): ReactElement => (
        <Modal
            open={invalidPassCodeMsg !== ""}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
            disableAutoFocus
            disableEnforceFocus
            disableRestoreFocus
        >
            <div
                style={{
                    background: "white",
                    maxWidth: "534px",
                    width: "100%",
                    borderRadius: "10px",
                    margin: "0 auto",
                    padding: "24px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexShrink: 0,
                    }}
                >
                    <h3
                        style={{
                            fontSize: "20px",
                            fontFamily: "chromatic-pro-regular!important",
                        }}
                    >
                        {invalidPassTitle}
                    </h3>

                    <div
                        style={{
                            border: "1px solid #E0E2E5",
                            borderRadius: "8px",
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "26px",
                            height: "26px",
                            cursor: "pointer",
                        }}
                        onClick={(): void => {
                            window.location.href = "/";
                        }}
                    >
                        <SvgIcon
                            src="/assets/icons/icon_x.svg"
                            color="black"
                            width="14px"
                            height="14px"
                        />
                    </div>
                </div>

                <p
                    style={{
                        fontSize: "16px",
                        marginTop: "20px",
                        fontFamily: "chromatic-pro-regular!important",
                    }}
                >
                    {invalidPassCodeMsg}
                </p>

                <Button
                    style={{
                        background: "rgba(26, 27, 29, 1)", //: '#F8F9F8',
                        color: "white",
                        height: "48px",
                        border: "none",
                        appearance: "none",
                        borderRadius: "16px",
                        marginTop: "24px",
                        display: "block",
                        marginLeft: "auto",
                    }}
                    onClick={(): void => {
                        window.location.href = "/";
                    }}
                >
                    OK
                </Button>
            </div>
        </Modal>
    );
