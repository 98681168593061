import { useMediaQuery, Box, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { TripTypes } from 'pages/bookRide/types';
import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { COLORS } from 'theme/pallete';
import { TYPOGRAPHY } from 'theme/typography';
import SvgIcon from './SvgIcon';
import { useProgramPassContext } from 'pages/ProgramPassContext';

export const TripSummary = (): ReactElement => {
  const isMobile = useMediaQuery('(max-width:982px)');
  const { programPass } = useProgramPassContext();

  const navigate = useNavigate();
  const { watch } = useFormContext();

  const [
    tripType,
    pickupAddress,
    dropoffAddress,
    pickupDate,
    returnPickupDate,
    estimatedTimeInHours,
  ] = watch([
    'TRIP_TYPE',
    'PICKUP_ADDRESS',
    'DROPOFF_ADDRESS',
    'PICKUP_DATE',
    'RETURN_PICKUP_DATE',
    'ESTIMATED_TIME_IN_HOURS',
  ]);

  const oneWayOrRoundtrip =
    tripType === TripTypes.OneWay || tripType === TripTypes.RoundTrip;

  return (
    <Box
      sx={{
        ...(isMobile
          ? {
            width: '100%',
            order: 1,
            display: 'flex',
            justifyContent: 'center',
          }
          : {}),
      }}
    >
      <Stack
        direction="row"
        sx={{
          border: `1px solid ${COLORS.GREY_LIGHT_1}`,
          borderRadius: '10px',
          maxWidth: '470px',
          width: '100%',
          overflow: 'hidden',
          alignItems: 'center',
          p: '5px',
          justifyContent: 'space-between',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            // width: '30%',
            flex: '30% 1 1',
            overflow: 'hidden',
          }}
          data-cy="trip-summary-pickup"
        >
          <SvgIcon
            src="/assets/icons/icon_arrowUpBlackCircle.svg"
            sx={{
              flexShrink: 0,
            }}
            width="18px"
          />
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              ...TYPOGRAPHY.subtitle2,
            }}
          >
            {pickupAddress?.address}
          </Typography>
        </Stack>
        {oneWayOrRoundtrip && (
          <>
            <Box
              sx={{
                backgroundColor: COLORS.GREY_LIGHT_1,
                mx: '5px',
                width: '1px',
                height: '20px',
              }}
            />
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                flex: '30% 1 1',
                overflow: 'hidden',
              }}
              data-cy="trip-summary-dropoff"
            >
              <SvgIcon
                src="/assets/icons/icon_arrowDownBlackCircle.svg"
                sx={{
                  flexShrink: 0,
                  width: '18px',
                }}
              />
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  ...TYPOGRAPHY.subtitle2,
                }}
              >
                {dropoffAddress?.address}
              </Typography>
            </Stack>
          </>
        )}
        <Box
          sx={{
            backgroundColor: COLORS.GREY_LIGHT_1,
            mx: '5px',
            width: '1px',
            height: '20px',
          }}
        />{' '}
        <Typography
          sx={{
            ...TYPOGRAPHY.subtitle2,
            whiteSpace: 'noWrap',
          }}
          data-cy="trip-summary-date"
        >
          {dayjs(pickupDate).format('MMM DD')}
          {tripType === TripTypes.RoundTrip &&
            ` - ${dayjs(returnPickupDate).format('MMM DD')}`}

          {tripType === TripTypes.Hourly && ` - ${estimatedTimeInHours}hrs`}
        </Typography>
        <Box
          sx={{
            backgroundColor: COLORS.GREY_LIGHT_1,
            mx: '5px',
            width: '1px',
            height: '20px',
          }}
        />
        <SvgIcon
          src="/assets/icons/icon_pencilOnOutlinedSquare.svg"
          sx={{
            flexShrink: 0,
            width: '18px',
            cursor: 'pointer',
          }}
          onClick={(): void => {
            const programPassId = programPass?.id;
            if (programPassId)
              navigate(`/pass/${programPassId}/step1${location.search}`);
            else navigate(`/book-ride/step1${location.search}`);
          }}
          data-cy="trip-summary-edit"
        />
      </Stack>
    </Box>
  );
};
