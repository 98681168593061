import React, { ReactElement } from 'react';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { User, UserManager } from 'oidc-client-ts';
import { ActionMapType, UserType } from 'shared.types';
import { AUTH_SERVER_CLIENT_ID, AUTH_SERVER_SCOPES, IDENTITY_SERVER_LOGIN_REDIRECT, IDENTITY_SERVER_POST_LOGOUT_REDIRECT_URI, IDENTITY_SERVER_URL, LOCAL_STORAGE_KEYS } from 'shared.config';
import { decodeUser, isValidToken } from 'utils/session';
import { JWTContextType } from './types';

enum Types {
    INITIAL = 'INITIAL',
    LOGIN = 'LOGIN',
    REGISTER = 'REGISTER',
    LOGOUT = 'LOGOUT',
    LOGGING_IN = 'LOGGING_IN',
}

export type AuthStateType = {
    isAuthenticated: boolean;
    isInitialized: boolean;
    user: UserType | null;
    isLoading: boolean;
};

type Payload = {
    [Types.INITIAL]: {
        isLoggingIn?: boolean;
        isAuthenticated: boolean;
        user: UserType | null;
    };
    [Types.LOGIN]: {
        user: UserType;
    };
    [Types.REGISTER]: {
        user: UserType;
    };
    [Types.LOGOUT]: undefined;
    [Types.LOGGING_IN]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    isLoading: false,
};

const reducer = (state: AuthStateType, action: ActionsType): AuthStateType => {
    if (action.type === Types.INITIAL) {
        return {
            ...state,
            isInitialized: true,
            isAuthenticated: action.payload.isAuthenticated,
            user: action.payload.user,
        };
    }
    if (action.type === Types.LOGIN) {
        return {
            ...state,
            isInitialized: true,
            isAuthenticated: true,
            isLoading: false,
            user: action.payload.user,
        };
    }
    if (action.type === Types.REGISTER) {
        return {
            ...state,
            isAuthenticated: true,
            user: action.payload.user,
        };
    }
    if (action.type === Types.LOGOUT) {
        return {
            ...state,
            isInitialized: false,
            isLoading: true,
            isAuthenticated: false,
            user: null,
        };
    }
    if (action.type === Types.LOGGING_IN) {
        return {
            ...state,
            isLoading: true,
            isAuthenticated: false,
            user: null,
        };
    }
    return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
    children: React.ReactNode;
};

const userManagerSettings = {
    authority: IDENTITY_SERVER_URL,
    client_id: AUTH_SERVER_CLIENT_ID,
    redirect_uri: IDENTITY_SERVER_LOGIN_REDIRECT,
    response_type: 'code',
    scope: AUTH_SERVER_SCOPES,
    post_logout_redirect_uri: IDENTITY_SERVER_POST_LOGOUT_REDIRECT_URI,
};

const usrMgr = new UserManager(userManagerSettings);

export function AuthProvider({ children }: AuthProviderProps): ReactElement {
    const [state, dispatch] = useReducer(reducer, initialState);

    const logout = useCallback(async () => {
        dispatch({
            type: Types.LOGOUT,
        });
        const user = await usrMgr.getUser();
        sessionStorage.clear();
        usrMgr.removeUser();
        await usrMgr.signoutRedirect({ id_token_hint: user?.id_token });
    }, []);

    const handleGetUser = useCallback(async (user?: User | null) => {
        try {
            if (!user) user = await usrMgr.getUser();
            if (user && user.access_token && isValidToken(user.access_token)) {
                const userDecoded: UserType | null = decodeUser(user.access_token);
                localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_LOGGED_IN_USER_ID, userDecoded.user_id);
                if (userDecoded) {
                    dispatch({
                        type: Types.LOGIN,
                        payload: {
                            user: userDecoded,
                        },
                    });
                }
            } else {
                dispatch({
                    type: Types.INITIAL,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        } catch (error) {
            console.error(error);
            dispatch({
                type: Types.INITIAL,
                payload: {
                    isLoggingIn: false,
                    isAuthenticated: false,
                    user: null,
                },
            });
        }
    }, []);

    usrMgr.events.addSilentRenewError(async () => {
        await usrMgr.signoutRedirect();
    });

    useEffect(() => {
        async function init(): Promise<void> {
            await handleGetUser();
        }
        init();
    }, [handleGetUser]);

    const login = useCallback(async () => {
        try {
            dispatch({
                type: Types.LOGGING_IN,
            });
            await usrMgr.signinRedirect({
                state: { returnUrl: window.location.pathname },
            });
        } catch (err) {
            console.error(err);
        }
    }, []);

    const updateUserClaims = useCallback(async () => {
        try {
            const userUpdated = await usrMgr.signinSilent();
            await handleGetUser(userUpdated);
        } catch (err) {
            console.error(err);
        }
    }, [handleGetUser]);

    const memoizedValue = useMemo(
        () => ({
            isInitialized: state.isInitialized,
            isAuthenticated: state.isAuthenticated,
            isLoading: state.isLoading,
            user: state.user,
            method: 'jwt',
            login,
            logout,
            updateUserClaims,
            usrMgr,
            handleGetUser,
        }),
        [
            state.isAuthenticated,
            state.isInitialized,
            state.user,
            login,
            logout,
            state.isLoading,
            updateUserClaims,
            handleGetUser,
        ]
    );

    return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
