import { LoadingComponent } from "components/shared/LoadingComponent";
import { ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ENDPOINTS } from "services/Endpoints";
import { ServiceTypes, useBaseService } from "services/useBaseService";
import { RiderInvitationInfoType } from "./types";
import { useAuthContext } from "auth/useAuthContext";
import { SESSION_STORAGE_KEYS } from "shared.config";

export default function RiderInvitationInfo(): ReactElement | null {
    const { programId, invitationCode } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { get } = useBaseService({ type: ServiceTypes.AccountAPI });
    const navigate = useNavigate();
    const { user, usrMgr } = useAuthContext();

    useEffect(() => {
        getInvitationInfo();
        async function getInvitationInfo(): Promise<void> {
            try {
                if (programId && invitationCode) {
                    setIsLoading(true);
                    const codeInfo = await get<RiderInvitationInfoType>(ENDPOINTS.GetRiderInvitationInfo(programId, invitationCode), false);

                    if (codeInfo.isRegistered) {
                        if (user?.email === codeInfo.email) {
                            navigate(`/program/${programId}/riders/accept_invitation/${invitationCode}`);
                        } else {
                            await usrMgr.signinRedirect({
                                state: {
                                    returnUrl: `/program/${programId}/riders/accept_invitation/${invitationCode}`,
                                },
                                extraQueryParams: {
                                    prefilledUsername: codeInfo.email,
                                }
                            });
                        }
                    } else {
                        sessionStorage.setItem(SESSION_STORAGE_KEYS.RIDER_INVITATION_PROGRAM_ID, programId);
                        sessionStorage.setItem(SESSION_STORAGE_KEYS.RIDER_INVITATION_CODE, invitationCode);
                        navigate(`/signup?email=${encodeURIComponent(codeInfo.email)}`);
                    }
                    setIsLoading(false);
                }
            } catch (err) {
                console.error(err);
                setTimeout(() => {
                    navigate('/');
                }, 5000);
            }
        }
    }, [programId, invitationCode, get, user, navigate, usrMgr]);

    if (isLoading)
        return <LoadingComponent />;

    return null;
}