import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Controller, useFormContext } from 'react-hook-form';
import React, { ReactElement, useEffect } from 'react';
import { Box } from '@mui/material';
import './AppDatePicker.css';
import { DATE_FORMAT } from '../../../../constans/DateRelatedContants';
import { TYPOGRAPHY } from '../../../../theme/typography';
import { COLORS } from '../../../../theme/pallete';

interface Props {
  name: string;
  placeHolder?: string;
  disablePast?: boolean;
  disableDayBeforeDay?: dayjs.Dayjs;
  disableHighlightToday?: boolean;
  minDate?: dayjs.Dayjs;
  maxDate?: dayjs.Dayjs;
}

const AppDatePicker: React.FC<Props> = ({
  name,
  placeHolder,
  disablePast = true,
  disableDayBeforeDay,
  disableHighlightToday,
  minDate,
  maxDate,
}) => {
  const { control, watch, setValue } = useFormContext();

  const selectedValue = watch(name);
  
  useEffect(() => {
    if (disableDayBeforeDay && dayjs(selectedValue, DATE_FORMAT).isBefore(disableDayBeforeDay)) {
      setValue(name, disableDayBeforeDay.format(DATE_FORMAT));
    }
  }, [disableDayBeforeDay, name, selectedValue, setValue])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }): ReactElement => (
        <Box
          sx={{
            backgroundColor: COLORS.GREY_MEDIUM_5,
            borderRadius: '10px',
          }}
        >
          <DatePicker
            className="c-datePicker"
            minDate={minDate}
            maxDate={maxDate}
            disablePast={disablePast}
            value={dayjs(value, DATE_FORMAT)}
            onChange={(e): void => onChange(e?.format(DATE_FORMAT))}
            format="ddd, MMM DD"
            label={placeHolder}
            sx={{
              width: '100%',
              fieldset: {
                borderColor: 'transparent!important',
              },
              input: {
                ...TYPOGRAPHY.formField,
                // backgroundColor: COLORS.GREY_MEDIUM_2,

                paddingTop: '0',
                paddingBottom: 0,
                height: '48px',
              },
            }}
            shouldDisableDate={(day): boolean => {
              if (disableDayBeforeDay) {
                return day.isBefore(disableDayBeforeDay);
              }
              return false;
            }}
            disableHighlightToday={disableHighlightToday}
          />
        </Box>
      )}
    />
  );
};
export default AppDatePicker;
