import { Box, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { ReactElement, useCallback, useState } from 'react';
import { useAuthContext } from '../auth/useAuthContext';
import AppButton from '../components/shared/AppButton';
import RHFCodes from '../components/shared/formFieldRelated/RHFCodes';
import SvgIcon from '../components/shared/SvgIcon';
import { FormProvider, useForm } from 'react-hook-form';
import { ServiceTypes, useBaseService } from '../services/useBaseService';
import { ENDPOINTS } from '../services/Endpoints';
import { useCustomSnackbar } from '../components/snackbar/DescriptionSnackbar';
import { TYPOGRAPHY } from '../theme/typography';
import { COLORS } from '../theme/pallete';
import { LoadingComponent } from 'components/shared/LoadingComponent';

type EmailVerificationType = {
  verificationCode1: string;
  verificationCode2: string;
  verificationCode3: string;
  verificationCode4: string;
  verificationCode5: string;
  verificationCode6: string;
};
const ConfirmEmailPage = (): ReactElement => {
  const { logout, updateUserClaims } = useAuthContext();
  const methods = useForm<EmailVerificationType>();

  const [error] = useState<{
    detail?: string;
    status: number;
    title?: string;
    type?: string;
  }>();
  const [responseMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const { get } = useBaseService({ type: ServiceTypes.AccountAPI });
  const { showSnackbar } = useCustomSnackbar();

  const validateEmail = useCallback(
    async (verificationCode: string) => {
      setIsLoading(true);
      try {
        const wasEmailValidated = await get(ENDPOINTS.ValidateEmail(verificationCode), true, false, false);
        if (wasEmailValidated) {
          showSnackbar('Email was validated', 'success');
          await updateUserClaims();
          navigate('/');
        }
      }
      catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }, [get, navigate, showSnackbar, updateUserClaims]);

  const resendValidationEmail = useCallback(async () => {
    setIsLoading(true);
    try {
      const wasEmailResent = await get(ENDPOINTS.ResendValidationEmail, true, false, false);
      if (wasEmailResent) {
        showSnackbar('The validation was sent again', 'success');
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
}, [get, showSnackbar]);

const submit = (fields: EmailVerificationType): void => {

  validateEmail(
    fields.verificationCode1 +
    fields.verificationCode2 +
    fields.verificationCode3 +
    fields.verificationCode4 +
    fields.verificationCode5 +
    fields.verificationCode6,
  );
};

if (isLoading)
  return <LoadingComponent />;

return (
  <Box
    sx={{
      width: '100%',
      mt: '40px',
    }}
  >
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)}>
        <Box
          sx={{
            width: '100%',
            maxWidth: '404px',

            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            // flex: '100% 1 1',
            margin: '0 auto 0',
          }}
        >
          <Box
            sx={{
              width: '96px',
              height: '96px',
              backgroundColor: 'rgba(148, 187, 160, 0.2);',
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <SvgIcon
              src="/assets/icons/icon_greenEnvelope.svg"
              width="60px"
              height="60px"
              color={COLORS.GREEN_MEDIUM_1}
            />
          </Box>
          <Typography
            sx={{
              marginTop: '20px',

              ...TYPOGRAPHY.title2,
            }}
          >
            Please check your email!
          </Typography>

          <Typography
            sx={{
              marginTop: '20px',
              textAlign: 'center',
              marginBottom: '40px',
              ...TYPOGRAPHY.paragraph,
            }}
          >
            We&#39;ve emailed a 6-digit confirmation code to
            {/*{user?.email}*/}, please enter the code in below box to verify
            your email.
          </Typography>
          <RHFCodes name="verificationCode" />
          <Box width="100%" mt="24px">
            <AppButton
              title="Verify"
              type="submit"
              data-cy="verify-email-button"
            />
          </Box>
          {error?.detail && (
            <Typography
              sx={{
                marginTop: '24px',
                fontSize: '14px',
              }}
              style={{
                color: 'red',
              }}
            >
              <>{error?.detail}</>
            </Typography>
          )}
          {responseMessage && (
            <Typography
              sx={{
                marginTop: '24px',
                fontWeight: 'bold',
              }}
            >
              <>{responseMessage}</>
            </Typography>
          )}
          <Link
            style={{ textDecoration: 'none' }}
            onClick={resendValidationEmail}
            data-cy="resend-validation-email"
            to="#"
          >
            <Typography
              sx={{
                marginTop: '24px',
                textAlign: 'center',
                ...TYPOGRAPHY.paragraph,
              }}
            >
              Don’t have a code? Resend code
            </Typography>
          </Link>
          <Link style={{ textDecoration: 'none' }} onClick={logout} to="">
            <Typography
              sx={{
                marginTop: '24px',
                textAlign: 'center',
                fontWeight: 'bold',
                ...TYPOGRAPHY.paragraph,
              }}
            >
              Return to sign in
            </Typography>
          </Link>
        </Box>
      </form>
    </FormProvider>
  </Box>
);
};

export default ConfirmEmailPage;
