// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  Box,
  Chip,
  Select,
  Checkbox,
  MenuItem,
  TextField,
  InputLabel,
  SelectProps,
  FormControl,
  OutlinedInput,
  TextFieldProps,
  Typography,
} from '@mui/material';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { COLORS } from 'theme/pallete';
import { FONT_FAMILY } from 'theme/typography';

// ----------------------------------------------------------------------

type RHFSelectProps = TextFieldProps & {
  name: string;
  label?: string;
  children: React.ReactNode;
  defaultValue?: string;
  valueMiddleware?: (valueToIntervene: string) => string;
  onChangeMiddleware?: (
    newValueToIntervene: string,
    onChangeFn: (newVal: string) => void,
  ) => void;
  rendervalue?: (value: unknown) => ReactNode;
  dataCy?: string;
};

export function RHFSelect({
  name,
  label,
  children,
  defaultValue,
  valueMiddleware = (val): string => val,
  onChangeMiddleware = (newVal, fn): void => {
    fn(newVal);
  },
  rendervalue,
  dataCy,
  ...other
}: RHFSelectProps): ReactElement | null {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useFormContext();

  useEffect(() => {
    if (!getValues(name) && defaultValue) {
      setValue(name, defaultValue);
      setDynamicDefaultVal(defaultValue);
    }
  }, [name, defaultValue, setValue, getValues]);

  const valueInRHF = watch(name);

  const [dynamicDefaultVal, setDynamicDefaultVal] = useState<
    string | undefined
  >('');

  return (defaultValue && valueInRHF) || !defaultValue ? (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }): ReactElement => {
        const valueToSet = valueMiddleware(value || dynamicDefaultVal);

        return (
          <Box>
            <TextField
              data-cy={dataCy}
              value={valueToSet}
              onChange={(event): void =>
                onChangeMiddleware(event.target.value, onChange)
              }
              select
              fullWidth
              {...other}
              sx={{
                height: '48px',
                '.MuiSelect-select': {
                  background: `${COLORS.GREY_LIGHT_4}!important`,
                  border: 'none!important',
                  height: '56px',
                  fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
                },

                '.MuiInputBase-root': {
                  borderRadius: '4px',

                  '&.MuiFilledInput-root': {
                    border: errors[name] ? '1px solid red' : '',
                  },
                },

                typography: 'input',
                '.MuiInputLabel-asterisk': {
                  display: 'none',
                },
                fieldset: {
                  border: 'none',
                },
                ...other.sx,
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      px: 1,
                      '& .MuiMenuItem-root': {
                        px: '15px',
                        my: '5px',
                        borderRadius: 0.75,
                        typography: 'input',
                        fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
                      },
                    },
                  },
                },
                sx: {
                  '& .MuiSelect-select': { paddingY: !label ? '15px' : '' },
                },
                renderValue: rendervalue,
                displayEmpty: Boolean(rendervalue),
              }}
              {...(label
                ? {
                    label: (
                      <Typography
                        sx={{ typography: 'input' }}
                        style={{
                          color: '#919eab',
                        }}
                      >
                        {label}
                      </Typography>
                    ),
                  }
                : {})}
            >
              {children}
            </TextField>
          </Box>
        );
      }}
    />
  ) : null;
}

// ----------------------------------------------------------------------

type RHFMultiSelectProps = SelectProps & {
  name: string;
  label?: string;
  chip?: boolean;
  checkbox?: boolean;
  placeholder?: string;
  options: {
    label: string;
    value: string;
  }[];
};

export function RHFMultiSelect({
  name,
  chip,
  label,
  options,
  checkbox,
  placeholder,
  sx,
  ...other
}: RHFMultiSelectProps): ReactElement {
  const { control } = useFormContext();

  const renderValues = (selectedIds: string[]): string | ReactElement => {
    const selectedItems = options.filter((item) =>
      selectedIds.includes(item.value),
    );

    if (!selectedItems.length && placeholder) {
      return (
        <Box component="em" sx={{ color: 'text.disabled' }}>
          {placeholder}
        </Box>
      );
    }

    if (chip) {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selectedItems.map((item) => (
            <Chip key={item.value} size="small" label={item.label} />
          ))}
        </Box>
      );
    }

    return selectedItems.map((item) => item.label).join(', ');
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }): ReactElement => (
        <FormControl
          sx={{
            ...sx,
            width: '100%',
          }}
        >
          {label && <InputLabel id={name}> {label} </InputLabel>}

          <Select
            {...field}
            onChange={(event): void => {
              field.onChange(event);
            }}
            multiple
            displayEmpty={!!placeholder}
            labelId={name}
            input={<OutlinedInput fullWidth label={label} error={!!error} />}
            renderValue={renderValues}
            MenuProps={{
              PaperProps: {
                sx: { px: 1, maxHeight: 280 },
              },
            }}
            sx={{
              // display: 'none',
              width: '100%',
              height: '56px',
              '.MuiSelect-select': {
                background: `${COLORS.GREY_LIGHT_4}!important`,
                border: 'none!important',
                height: '26px',
                fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
              },

              '.MuiInputBase-root': {
                borderRadius: '4px',

                // '&.MuiFilledInput-root': {
                //   border: errors[name] ? '1px solid red' : '',
                // },
              },

              typography: 'input',
              '.MuiInputLabel-asterisk': {
                display: 'none',
              },
              fieldset: {
                border: 'none',
              },
            }}
            {...other}
          >
            {placeholder && (
              <MenuItem
                disabled
                value=""
                sx={{
                  py: 1,
                  px: 2,
                  borderRadius: 0.75,
                  typography: 'body2',
                }}
              >
                <em> {placeholder} </em>
              </MenuItem>
            )}

            {options.map((option) => {
              const selected = field.value.includes(option.value);

              return (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    py: 1,
                    px: 2,
                    borderRadius: 0.75,
                    typography: 'body2',
                    ...(selected && {
                      fontWeight: 'fontWeightMedium',
                    }),
                    ...(checkbox && {
                      p: 0.25,
                    }),
                  }}
                >
                  {checkbox && (
                    <Checkbox disableRipple size="small" checked={selected} />
                  )}

                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    />
  );
}
