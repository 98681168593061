import { Box, Stack, Typography } from '@mui/material';
import { TYPOGRAPHY } from '../../theme/typography';
import { ReactElement, useEffect } from 'react';
import SvgIcon from '../../components/shared/SvgIcon';
import { useLocation } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import AppButton from '../../components/shared/AppButton';
import { COLORS } from '../../theme/pallete';
import { BookRideFieldNames } from './types';

const BookedRideConfirmationPage = (): ReactElement => {
  const location = useLocation();
  const { reset, getValues, setValue } = useFormContext();

  useEffect(() => {
    const businessTravelAccount = getValues(
      BookRideFieldNames.BUSINESS_TRAVEL_ACCOUNT,
    );
    reset();
    setValue(BookRideFieldNames.BUSINESS_TRAVEL_ACCOUNT, businessTravelAccount);
  }, [reset, setValue, getValues]);

  const passLink = window.location.origin + '/pass/' + location?.state?.passId;

  const handleCopy = (): void => {
    navigator.clipboard
      .writeText(passLink)
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .then(() => {
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    <Box
      sx={{
        margin: '0 auto',
        width: '100%',
        maxWidth: '760px',
      }}
    >
      <Typography
        sx={{
          ...TYPOGRAPHY.title1,
        }}
        data-cy="confirmation-msg-title"
      >
        Thanks! Your reservation is confirmed
      </Typography>
      <Typography
        sx={{
          ...TYPOGRAPHY.paragraph,
          mt: '20px',
        }}
        data-cy="reservation-msg"
      >
        Kaptyn reservation number:&nbsp;
        <span
          style={{
            fontWeight: 'bold',
          }}
          data-cy="reservation-number"
        >
          {location?.state?.reservationNumber}
        </span>
      </Typography>
      <Typography
        sx={{
          ...TYPOGRAPHY.paragraph,
          display: 'flex',
          alignItems: 'center',
          mt: '20px',
        }}
        data-cy="email-msg"
      >
        <SvgIcon
          src="/assets/icons/icon_greenCheckMark.svg"
          sx={{
            mr: '10px',
          }}
        />
        You’ll receive a confirmation email shortly at&nbsp;
        <span
          style={{
            fontWeight: 'bold',
          }}
          data-cy="email-validation"
        >
          {location?.state?.riderEmail}
        </span>
      </Typography>

      {location?.state?.passId && (
        <>
          <Box
            sx={{
              display: 'inline-flex',
              mt: '30px',
            }}
          >
            <AppButton
              title="Book my next trip"
              onClick={(): void => {
                window.location.href = passLink;
              }}
              data-cy="book-next-trip"
            />
          </Box>
          <Box
            sx={{
              border: '1px solid #0000001f',
              borderRadius: '10px',
              padding: '16px',
              mt: '30px',
            }}
            data-cy="share-pass-card"
          >
            <Typography
              sx={{
                ...TYPOGRAPHY.title4,
              }}
            >
              Share your Kaptyn pass
            </Typography>
            <Typography
              sx={{
                ...TYPOGRAPHY.subtitle2,
                mt: '10px',
              }}
            >
              Need others to book their rides? Send this link to them so they
              can book their Kaptyn transportation.
            </Typography>

            <Typography
              sx={{
                ...TYPOGRAPHY.tinyText,
                opacity: '0.8',
                mt: '20px',
              }}
            >
              Pass link
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              spacing="15px"
              data-cy="pass-link"
            >
              <Box
                sx={{
                  backgroundColor: COLORS.GREY_LIGHT_4,
                  padding: '0 15px',
                  borderRadius: '8px',
                  height: '48px',
                  display: 'flex',
                  alignItems: 'center',
                  flex: '100% 1 1',
                  overflow: 'hidden',
                }}
              >
                <Typography
                  sx={{
                    ...TYPOGRAPHY.paragraph,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {passLink}
                </Typography>
              </Box>

              <AppButton
                title="Copy link"
                onClick={handleCopy}
                additionalSx={{
                  // backgroundColor: 'transparent',
                  // color: 'black',
                  maxWidth: '100px',
                }}
              />
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );
};

export default BookedRideConfirmationPage;
