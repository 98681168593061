import { Box, Grid, MenuItem } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';

import { BookRideFieldNames } from '../../types';

import PlacesAutocomplete from '../../../../components/shared/formFieldRelated/PlacesAutocomplete';
import AppDatePicker from '../../../../components/shared/formFieldRelated/AppDatePicker/AppDatePicker';
import { CustomTimePicker } from '../../../../components/shared/formFieldRelated/CustomTimePicker';
import AppButton from '../../../../components/shared/AppButton';
import FormFieldLayout from '../../../../components/shared/formFieldRelated/FormFieldLayout';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { TIME_12HRS_FORMAT } from '../../../../constans/DateRelatedContants';
import { convertLocationTypes } from 'utils/locations';
import { AddressTypes, LocationDetailsType } from 'shared.types';
import { RHFSelect } from 'components/shared/formFieldRelated/RHFSelect';
import { COLORS } from 'theme/pallete';
import { TYPOGRAPHY } from 'theme/typography';
import { useProgramPassContext } from 'pages/ProgramPassContext';

const RoundtripForm = ({
  isGettingServiceProvider,
}: {
  isGettingServiceProvider: boolean;
}): ReactElement => {
  const { watch, setValue, trigger, getValues } = useFormContext();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [minPickupTime, setMinPickupTime] = useState<string>();
  const [maxPickupTime, setMaxPickupTime] = useState<string>();
  const [minReturnPickupTime, setMinReturnPickupTime] = useState<string>();
  const [maxReturnPickupTime, setMaxReturnPickupTime] = useState<string>();
  const [minReturnPickupDate, setMinReturnPickupDate] = useState<dayjs.Dayjs>();
  const { programPass } = useProgramPassContext();

  const [pickupDate, returnPickupDate] = watch([
    BookRideFieldNames.PICKUP_DATE,
    BookRideFieldNames.RETURN_PICKUP_DATE,
  ]);

  const pickupDateDayJs = dayjs(pickupDate);

  const allowedPickUpAddresses =
    programPass &&
    programPass.businessProgram.allowedAddresses &&
    programPass.businessProgram.allowedAddresses.filter(
      (address) => address.allowPickUp,
    );
  const mappedAllowedPickUpAddresses =
    allowedPickUpAddresses &&
    convertLocationTypes(allowedPickUpAddresses, AddressTypes.PickUp);

  const allowedDropOffAddresses =
    programPass &&
    programPass.businessProgram.allowedAddresses &&
    programPass.businessProgram.allowedAddresses.filter(
      (address) => address.allowDropOff,
    );
  const mappedAllowedDropOffAddresses =
    allowedDropOffAddresses &&
    convertLocationTypes(allowedDropOffAddresses, AddressTypes.DropOff);

  const rideValidFrom =
    programPass && dayjs(programPass?.businessProgram.passRideValidFrom);
  const rideValidTo =
    programPass && dayjs(programPass?.businessProgram.passRideValidTo);

  const selectedPickUpAddress: LocationDetailsType = watch(
    BookRideFieldNames.PICKUP_ADDRESS,
  );
  let referenceEqualityPickUpAddress: LocationDetailsType | undefined;
  if (selectedPickUpAddress)
    referenceEqualityPickUpAddress = mappedAllowedPickUpAddresses?.find(
      (address) =>
        address.googlePlacesId === selectedPickUpAddress.googlePlacesId,
    );

  const selectedDropOffAddress: LocationDetailsType = watch(
    BookRideFieldNames.DROPOFF_ADDRESS,
  );
  let referenceEqualityDropOffAddress: LocationDetailsType | undefined;
  if (selectedDropOffAddress)
    referenceEqualityDropOffAddress = mappedAllowedDropOffAddresses?.find(
      (address) =>
        address.googlePlacesId === selectedDropOffAddress.googlePlacesId,
    );

  useEffect(() => {
    if (programPass) {
      const rideValidFrom = dayjs(
        programPass.businessProgram.passRideValidFrom,
      );
      const rideValidTo = dayjs(programPass.businessProgram.passRideValidTo);

      if (
        pickupDate === rideValidFrom.format('YYYY-MM-DD') &&
        minPickupTime !== rideValidFrom.format(TIME_12HRS_FORMAT)
      ) {
        setMinPickupTime(rideValidFrom.format(TIME_12HRS_FORMAT));
      } else if (
        minPickupTime !== undefined &&
        pickupDate !== rideValidFrom.format('YYYY-MM-DD')
      ) {
        setMinPickupTime(undefined);
      }

      if (
        pickupDate === rideValidTo.format('YYYY-MM-DD') &&
        maxPickupTime !== rideValidTo.format(TIME_12HRS_FORMAT)
      ) {
        setMaxPickupTime(rideValidTo.format(TIME_12HRS_FORMAT));
      } else if (
        maxPickupTime !== undefined &&
        pickupDate !== rideValidTo.format('YYYY-MM-DD')
      ) {
        setMaxPickupTime(undefined);
      }
    }
  }, [maxPickupTime, minPickupTime, programPass, watch, pickupDate]);

  useEffect(() => {
    if (programPass) {
      const rideValidFrom = dayjs(
        programPass.businessProgram.passRideValidFrom,
      );
      const rideValidTo = dayjs(programPass.businessProgram.passRideValidTo);

      if (
        returnPickupDate === rideValidFrom.format('YYYY-MM-DD') &&
        minReturnPickupTime !== rideValidFrom.format(TIME_12HRS_FORMAT)
      ) {
        setMinReturnPickupTime(rideValidFrom.format(TIME_12HRS_FORMAT));
      } else if (
        minReturnPickupTime !== undefined &&
        returnPickupDate !== rideValidFrom.format('YYYY-MM-DD')
      ) {
        setMinReturnPickupTime(undefined);
      }

      if (
        returnPickupDate === rideValidTo.format('YYYY-MM-DD') &&
        maxReturnPickupTime !== rideValidTo.format(TIME_12HRS_FORMAT)
      ) {
        setMaxReturnPickupTime(rideValidTo.format(TIME_12HRS_FORMAT));
      } else if (
        maxReturnPickupTime !== undefined &&
        returnPickupDate !== rideValidTo.format('YYYY-MM-DD')
      ) {
        setMaxReturnPickupTime(undefined);
      }

      if (minReturnPickupDate === undefined) {
        setMinReturnPickupDate(dayjs(pickupDate));
      }
    }
  }, [
    maxReturnPickupTime,
    minReturnPickupTime,
    minReturnPickupDate,
    programPass,
    pickupDate,
    returnPickupDate,
  ]);

  const next = async (): Promise<void> => {
    setIsSubmitting(true);
    const targetFields = [
      BookRideFieldNames.PICKUP_ADDRESS,
      BookRideFieldNames.DROPOFF_ADDRESS,
      BookRideFieldNames.PICKUP_DATE,
      BookRideFieldNames.PICKUP_TIME,
      BookRideFieldNames.RETURN_PICKUP_DATE,
      BookRideFieldNames.RETURN_PICKUP_TIME,
    ];
    const isValid = await trigger(targetFields);

    if (isValid) {
      const queryParams = new URLSearchParams({
        rideType: getValues(BookRideFieldNames.TRIP_TYPE),
        fromAddressPlaceId: getValues(BookRideFieldNames.PICKUP_ADDRESS)
          .googlePlacesId,
        fromAddressDescription: getValues(BookRideFieldNames.PICKUP_ADDRESS)
          .address,
        toAddressPlaceId: getValues(BookRideFieldNames.DROPOFF_ADDRESS)
          .googlePlacesId,
        toAddressDescription: getValues(BookRideFieldNames.DROPOFF_ADDRESS)
          .address,
        pickUpDate: getValues(BookRideFieldNames.PICKUP_DATE),
        pickUpTime: getValues(BookRideFieldNames.PICKUP_TIME),
        returnPickUpDate: getValues(BookRideFieldNames.RETURN_PICKUP_DATE),
        returnPickUpTime: getValues(BookRideFieldNames.RETURN_PICKUP_TIME),
      });

      const step2PassUrl =
        `/pass/${programPass?.id}/step2` + '?' + queryParams.toString();
      const step2NormalUrl = `/book-ride/step2` + '?' + queryParams.toString();

      if (programPass) {
        navigate(step2PassUrl);
      } else {
        navigate(step2NormalUrl);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <Box
          sx={{
            flex: '50% 1 1',
            overflow:
              (mappedAllowedPickUpAddresses &&
                mappedAllowedPickUpAddresses.length > 0) ||
              (mappedAllowedDropOffAddresses &&
                mappedAllowedDropOffAddresses.length > 0)
                ? 'hidden'
                : 'visible',
          }}
        >
          <Grid
            container
            item
            xs={12}
            spacing="8px"
            rowSpacing={{
              xs: '8px',
              md: '30px',
            }}
            width="100%"
          >
            <Grid item xs={12} md={6}>
              <FormFieldLayout
                name={BookRideFieldNames.PICKUP_ADDRESS}
                label="Pickup location"
                dataCy="roundtrip-pickup-location"
              >
                {mappedAllowedPickUpAddresses &&
                  mappedAllowedPickUpAddresses.length > 0 && (
                    <RHFSelect
                      name={BookRideFieldNames.PICKUP_ADDRESS}
                      sx={{
                        '.MuiSelect-select': {
                          height: '48px!important',
                          backgroundColor: COLORS.GREY_MEDIUM_2,
                          borderRadius: '10px',
                          ...TYPOGRAPHY.formField,
                        },
                      }}
                      value={referenceEqualityPickUpAddress ?? ''}
                      onChange={(e): void => {
                        setValue(
                          BookRideFieldNames.PICKUP_ADDRESS,
                          e.target.value,
                        );
                      }}
                      rendervalue={(obj: unknown): string => {
                        return (
                          (obj as { address: string })?.address ?? (
                            <div
                              style={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              Select
                            </div>
                          )
                        );
                      }}
                      dataCy="pickup-address-select"
                    >
                      {mappedAllowedPickUpAddresses.map((address, i) => (
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        <MenuItem key={i} value={address as any}>
                          {address.address}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  )}
                {(!mappedAllowedPickUpAddresses ||
                  mappedAllowedPickUpAddresses.length === 0) && (
                  <PlacesAutocomplete
                    name={BookRideFieldNames.PICKUP_ADDRESS}
                    placeholder="Pickup location"
                    addressType={AddressTypes.PickUp}
                    dataCy="pickup-address-autocomplete"
                  />
                )}
              </FormFieldLayout>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormFieldLayout
                name={BookRideFieldNames.DROPOFF_ADDRESS}
                label="Dropoff location"
                dataCy="roundtrip-dropoff-location"
              >
                {mappedAllowedDropOffAddresses &&
                  mappedAllowedDropOffAddresses.length > 0 && (
                    <RHFSelect
                      name={BookRideFieldNames.DROPOFF_ADDRESS}
                      sx={{
                        '.MuiSelect-select': {
                          height: '48px!important',
                          backgroundColor: COLORS.GREY_MEDIUM_2,
                          borderRadius: '10px',
                          ...TYPOGRAPHY.formField,
                        },
                      }}
                      value={referenceEqualityDropOffAddress ?? ''}
                      onChange={(e): void => {
                        setValue(
                          BookRideFieldNames.DROPOFF_ADDRESS,
                          e.target.value,
                        );
                      }}
                      rendervalue={(obj: unknown): string => {
                        return (
                          (obj as { address: string })?.address ?? (
                            <div
                              style={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              Select
                            </div>
                          )
                        );
                      }}
                      dataCy="dropoff-address-select"
                    >
                      {mappedAllowedDropOffAddresses.map((address, i) => (
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        <MenuItem key={i} value={address as any}>
                          {address.address}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  )}
                {(!mappedAllowedDropOffAddresses ||
                  mappedAllowedDropOffAddresses.length === 0) && (
                  <PlacesAutocomplete
                    name={BookRideFieldNames.DROPOFF_ADDRESS}
                    placeholder="Dropoff location"
                    addressType={AddressTypes.DropOff}
                    dataCy="dropoff-address-autocomplete"
                    locationBias={{
                      lat: selectedPickUpAddress?.latitude,
                      lng: selectedPickUpAddress?.longitude,
                    }}
                  />
                )}
              </FormFieldLayout>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormFieldLayout
                name={BookRideFieldNames.PICKUP_DATE}
                label="Pickup date"
                dataCy="roundtrip-pickup-date"
              >
                <AppDatePicker
                  name={BookRideFieldNames.PICKUP_DATE}
                  minDate={rideValidFrom}
                  maxDate={rideValidTo}
                />
              </FormFieldLayout>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormFieldLayout
                name={BookRideFieldNames.PICKUP_TIME}
                label="Pickup time"
                dataCy="field-roundtrip-pickup-time"
              >
                <CustomTimePicker
                  dataCy="roundtrip-pickup-time"
                  name={BookRideFieldNames.PICKUP_TIME}
                  minTime={minPickupTime}
                  maxTime={maxPickupTime}
                />
              </FormFieldLayout>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            flex: '20% 1 1',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '40px 0 ',
          }}
        >
          <img src="/assets/images/cycleArrows.png" alt="circle arrows" />
        </Box>

        <Box
          sx={{
            flex: '50% 1 1',
          }}
        >
          <Grid
            container
            item
            xs={12}
            spacing="8px"
            rowSpacing={{
              xs: '8px',
              md: '30px',
            }}
          >
            <Grid item xs={12} md={6}>
              <FormFieldLayout
                name={BookRideFieldNames.DROPOFF_ADDRESS}
                label="Return Pickup location"
                dataCy="roundtrip-return-pickup-location"
              >
                <Box
                  sx={{
                    pointerEvents: 'none',
                  }}
                >
                  <PlacesAutocomplete
                    name={BookRideFieldNames.DROPOFF_ADDRESS}
                    placeholder="Return pickup location"
                    addressType={1}
                  />
                </Box>
              </FormFieldLayout>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormFieldLayout
                name={BookRideFieldNames.PICKUP_ADDRESS}
                label="Return dropoff location"
                dataCy="roundtrip-return-dropoff-location"
              >
                <Box
                  sx={{
                    pointerEvents: 'none',
                  }}
                >
                  <PlacesAutocomplete
                    name={BookRideFieldNames.PICKUP_ADDRESS}
                    placeholder="Return dropoff location"
                    addressType={0}
                  />
                </Box>
              </FormFieldLayout>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormFieldLayout
                name={BookRideFieldNames.RETURN_PICKUP_DATE}
                label="Return pickup date"
                dataCy="roundtrip-return-pickup-date"
              >
                <Box sx={{}}>
                  <AppDatePicker
                    name={BookRideFieldNames.RETURN_PICKUP_DATE}
                    disableDayBeforeDay={pickupDateDayJs}
                    disableHighlightToday={true}
                    minDate={minReturnPickupDate}
                    maxDate={rideValidTo}
                  />
                </Box>
              </FormFieldLayout>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormFieldLayout
                name={BookRideFieldNames.RETURN_PICKUP_TIME}
                label="Return pickup time"
                dataCy="roundtrip-return-pickup-time"
              >
                <CustomTimePicker
                  name={BookRideFieldNames.RETURN_PICKUP_TIME}
                  maxTime={maxReturnPickupTime}
                  minTime={minReturnPickupTime}
                />
              </FormFieldLayout>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'flex-end',
          mt: '20px',
        }}
      >
        <AppButton
          title={
            isSubmitting || isGettingServiceProvider
              ? 'Searching...'
              : 'Show rides'
          }
          additionalSx={{
            width: '120px',
            borderRadius: '20px',
          }}
          type="button"
          onClick={next}
          data-cy="showRidesBtn"
          disabled={isSubmitting || isGettingServiceProvider}
        />
      </Box>
    </Box>
  );
};

export default RoundtripForm;
