import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import TabbedTips from './TabbedTips';
import { BookRideFieldNames } from '../../../../types';
import ErrorMessage from '../../../../../../components/shared/formFieldRelated/ErrorMessage';

interface Props {
  allowDeselection?: boolean;
  autoGratuityPercentage?: number | string;
}

const WrapperRHFTabbedTips: React.FC<Props> = ({ allowDeselection, autoGratuityPercentage }) => {
  const { control, trigger, setValue, watch } = useFormContext();
  const watchedAutoGratuity = watch(BookRideFieldNames.EXTRA_GRATUITY);

  const TabbedTipsOptions = autoGratuityPercentage
    ? [
      {
        label: '10%',
        value: '10',
        dataCy: '10%',
      },
      {
        label: '15%',
        value: '15',
        dataCy: '15%',
      },
      {
        label: '20%',
        value: '20',
        dataCy: '20%',
      },
    ]
    : [
      {
        label: '15%',
        value: '15',
        dataCy: '15%',
      },
      {
        label: '20%',
        value: '20',
        dataCy: '20%',
      },
      {
        label: '25%',
        value: '25',
        dataCy: '25%',
      },
    ];
  return (
    <>
      <Controller
        name={BookRideFieldNames.EXTRA_GRATUITY}
        control={control}
        render={({ field: { onChange } }): React.ReactElement => (
          <Box>
            <TabbedTips
              value={watchedAutoGratuity}
              tabs={TabbedTipsOptions}
              onChange={(position: string | boolean): void => {
                const parsedPosition = position;

                onChange({
                  tabValue: parsedPosition,
                  percentage: position === 'custom' ? null : parsedPosition,
                });

                if (position !== 'custom') {
                  trigger(BookRideFieldNames.EXTRA_GRATUITY);
                }
              }}
              onClear={(): void => {
                setValue('EXTRA_GRATUITY.percentage', null);

                trigger(BookRideFieldNames.EXTRA_GRATUITY);
              }}
              allowCustomValue
              allowDeselection={allowDeselection}
            />
            <ErrorMessage name="EXTRA_GRATUITY.percentage" />
          </Box>
        )}
      />
    </>
  );
};

export default WrapperRHFTabbedTips;
