import { useAuthContext } from "auth/useAuthContext";
import LoadingScreen from "components/LoadingScreen";
import { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router";

export default function LoginRedirect(): ReactElement {
    const { usrMgr, handleGetUser } = useAuthContext();
    const navigate = useNavigate();

    useEffect(() => {
        init();
        async function init(): Promise<void> {
            try {
                const userInfo = await usrMgr.signinRedirectCallback();
                await handleGetUser(userInfo);
                const returnUrl = (userInfo.state as { returnUrl: string }).returnUrl || '/';
                navigate(returnUrl);
            } catch (error) {
                console.error(error);
            }
        }
    }, [handleGetUser, navigate, usrMgr]);

    return <LoadingScreen />;
}