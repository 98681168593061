import { BookRideFieldNames, TripTypes } from '../../types';
import { useFormContext } from 'react-hook-form';
import { FieldsByCurrTripTypeType } from '../../../../shared.types';
import { useCallback } from 'react';

export const useGetFieldsByCurrTripType = (): (() =>
  | FieldsByCurrTripTypeType
  | undefined) => {
  const { watch } = useFormContext();

  const [
    tripType,
    pickupAddress,
    dropoffAddress,
    pickupDate,
    pickupTime,
    returnPickupDate,
    returnPickupTime,
    estimatedTimeInHours,
  ] = watch([
    BookRideFieldNames.TRIP_TYPE,
    BookRideFieldNames.PICKUP_ADDRESS,
    BookRideFieldNames.DROPOFF_ADDRESS,
    BookRideFieldNames.PICKUP_DATE,
    BookRideFieldNames.PICKUP_TIME,
    BookRideFieldNames.RETURN_PICKUP_DATE,
    BookRideFieldNames.RETURN_PICKUP_TIME,
    BookRideFieldNames.ESTIMATED_TIME_IN_HOURS,
  ]);
  const toReturn = useCallback(() => {
    switch (tripType) {
      case TripTypes.OneWay:
        return {
          tripType,
          pickupAddress,
          dropoffAddress,
          pickupDate,
          pickupTime,
        };
        break;

      case TripTypes.RoundTrip:
        return {
          tripType,
          pickupAddress,
          dropoffAddress,
          pickupDate,
          pickupTime,
          returnPickupDate,
          returnPickupTime,
        };

        break;
      case TripTypes.Hourly:
        return {
          tripType,
          pickupAddress,
          estimatedTimeInHours,
          pickupDate,
          pickupTime,
        };
        break;
    }
  }, [
    dropoffAddress,
    estimatedTimeInHours,
    pickupAddress,
    pickupDate,
    pickupTime,
    returnPickupDate,
    returnPickupTime,
    tripType,
  ]);
  return toReturn;
};
