import axios, { AxiosError } from 'axios';
import { useCallback, useRef } from 'react';
import { OptionsObject, useSnackbar } from 'notistack';
import { useAuthContext } from 'auth/useAuthContext';
import {
  ACCOUNT_API_URL,
  ACCOUNT_API_VERSION,
  PAYMENT_API_URL,
  PAYMENT_API_VERSION,
  RESERVE_API_URL,
  RESERVE_API_VERSION,
  RIDE_API_URL,
  RIDE_API_VERSION,
} from 'shared.config';
import { jwtDecode } from 'utils/session';
import { UserType } from 'shared.types';

type ReturnUseBaseServiceType = {
  post: <TResult, TDataType>(
    url: string,
    requireAuthentication: boolean,
    data?: TDataType,
    failSilently?: boolean,
    isCancellable?: boolean,
  ) => Promise<TResult | null>;
  patch: <TResult, TDataType>(
    url: string,
    requireAuthentication: boolean,
    data?: TDataType,
  ) => Promise<TResult | null>;
  put: <TResult, TDataType>(
    url: string,
    requireAuthentication: boolean,
    data?: TDataType,
  ) => Promise<TResult | null>;
  get: <TResult>(
    url: string,
    requireAuthentication: boolean,
    isCancellable?: boolean,
    failSilently?: boolean,
  ) => Promise<TResult>;
  deleteMethod: (
    url: string,
    requireAuthentication: boolean,
    data?: unknown,
  ) => Promise<void>;
  abortQueries: (startingUrl: string) => void;
};

export type ErrorData = {
  title?: string;
  Title?: string;
  detail?: string;
  Detail?: string;
  type?: 'error' | 'success' | 'warning';
  Type?: 'error' | 'success' | 'warning';
};

export const enum ServiceTypes {
  API,
  PaymentAPI,
  RideAPI,
  AccountAPI,
}

type BaseServiceType = {
  type: ServiceTypes;
};

type QueryInProgress = {
  url: string;
  controller: AbortController;
};

export const useBaseService = (
  { type }: BaseServiceType = { type: ServiceTypes.API },
): ReturnUseBaseServiceType => {
  const { enqueueSnackbar } = useSnackbar();
  const { updateUserClaims, logout } = useAuthContext();
  const { current: queriesInProgress } = useRef<QueryInProgress[]>([]);

  let baseUrl = RESERVE_API_URL;
  let apiVersion = RESERVE_API_VERSION;
  switch (type) {
    case ServiceTypes.PaymentAPI:
      baseUrl = PAYMENT_API_URL;
      apiVersion = PAYMENT_API_VERSION;
      break;
    case ServiceTypes.RideAPI:
      baseUrl = RIDE_API_URL;
      apiVersion = RIDE_API_VERSION;
      break;
    case ServiceTypes.AccountAPI:
      baseUrl = ACCOUNT_API_URL;
      apiVersion = ACCOUNT_API_VERSION;
      break;
    default:
      break;
  }

  const getValidAccessToken = useCallback(
    async (retries = 0): Promise<string | void | null> => {
      if (retries === 5) return logout();
      const currentTimestamp: number = Math.floor(Date.now() / 1000) + 60; // one minute after current datetime
      let accessToken = sessionStorage.getItem('bh_access_token');
      if (accessToken) {
        const user: UserType = jwtDecode(accessToken);
        if (user?.exp && user?.exp <= currentTimestamp) {
          sessionStorage.removeItem('bh_access_token');
          await updateUserClaims();
          accessToken = sessionStorage.getItem('bh_access_token');
          if (accessToken) {
            return accessToken;
          }
        }

        return accessToken;
      }

      retries += 1;
      await new Promise((f) => setTimeout(f, 2500));
      const result: string | void | null = await getValidAccessToken(retries);
      return result;
    },
    [updateUserClaims, logout],
  );

  const getServerErrorMessage = useCallback((err: AxiosError<ErrorData>) => {
    if (!err?.response?.data) return null;
    const { title, detail } = err.response.data;
    return detail || title;
  }, []);

  const handleServerError = useCallback(
    (err: AxiosError<ErrorData>, genericErrorMessage?: string) => {
      console.error(err);
      let errorText =
        genericErrorMessage || 'There was an error. Please try again later';
      if (
        err.response?.status === 400 &&
        (err.response.data?.title || err.response.data?.detail)
      ) {
        const parsedErrorText = getServerErrorMessage(err);
        if (parsedErrorText) errorText = parsedErrorText;
      }

      let snackBarType: OptionsObject<'error' | 'success' | 'warning'> = {
        variant: 'error',
      };

      if (
        err.response?.data.type &&
        ['success', 'error', 'warning'].includes(err.response.data.type)
      )
        snackBarType = { variant: err.response.data.type };

      enqueueSnackbar(errorText, snackBarType);
    },
    [getServerErrorMessage, enqueueSnackbar],
  );

  const deleteMethod = useCallback(
    async (
      url: string,
      requireAuthentication = true,
      data?: unknown,
    ): Promise<void> => {
      try {
        let accessToken;
        if (requireAuthentication) accessToken = await getValidAccessToken();

        await axios({
          method: 'delete',
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : '',
            'x-api-version': apiVersion,
          },
          url: `${baseUrl ?? ''}/${url}`,
          data,
        });
      } catch (err) {
        handleServerError(err as AxiosError<ErrorData>);

        throw err;
      }
    },
    [apiVersion, baseUrl, handleServerError, getValidAccessToken],
  );

  const get = useCallback(
    async <TResult>(
      url: string,
      requireAuthentication = true,
      isCancellable = false,
      failSilently = false,
    ): Promise<TResult> => {
      try {
        let accessToken;
        if (requireAuthentication) accessToken = await getValidAccessToken();

        let newQueryInProgress: QueryInProgress | undefined;
        if (isCancellable) {
          newQueryInProgress = {
            url,
            controller: new AbortController(),
          };
          queriesInProgress?.push(newQueryInProgress);
        }

        const response = await axios<TResult>({
          method: 'get',
          signal: newQueryInProgress?.controller.signal,
          headers: {
            'x-api-version': apiVersion,
            ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
          },
          url: `${baseUrl ?? ''}/${url}`,
        });

        return response.data;
      } catch (err) {
        if (!failSilently) handleServerError(err as AxiosError<ErrorData>);

        throw err;
      }
    },
    [
      apiVersion,
      baseUrl,
      getValidAccessToken,
      queriesInProgress,
      handleServerError,
    ],
  );

  const dataPost = useCallback(
    async <TResult, TDataType>(
      url: string,
      method: string,
      data: TDataType | null = null,
      requireAuthentication = true,
      failSilently = false,
      isCancellable = false,
    ) => {
      try {
        let accessToken;
        if (requireAuthentication) accessToken = await getValidAccessToken();

        let newQueryInProgress: QueryInProgress | undefined;
        if (isCancellable) {
          newQueryInProgress = {
            url,
            controller: new AbortController(),
          };
          queriesInProgress?.push(newQueryInProgress);
        }

        const response = await axios<TResult>({
          method,
          signal: newQueryInProgress?.controller.signal,
          data,
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : '',
            'x-api-version': apiVersion,
          },
          url: `${baseUrl ?? ''}/${url}`,
        });

        return response.data;
      } catch (err) {
        if (!failSilently) handleServerError(err as AxiosError<ErrorData>);

        throw err;
      }
      // return null;
    },
    [
      apiVersion,
      baseUrl,
      getValidAccessToken,
      handleServerError,
      queriesInProgress,
    ],
  );

  const patch = async <TResult, TDataType>(
    url: string,
    requireAuthentication = true,
    data: TDataType,
  ): Promise<TResult | null> =>
    dataPost<TResult, TDataType>(url, 'patch', data, requireAuthentication);

  const put = async <TResult, TDataType>(
    url: string,
    requireAuthentication = true,
    data: TDataType,
  ): Promise<TResult | null> =>
    dataPost<TResult, TDataType>(url, 'put', data, requireAuthentication);

  const post = useCallback(
    async <TResult, TDataType>(
      url: string,
      requireAuthentication = true,
      data: TDataType,
      failSilently?: boolean,
      isCancellable?: boolean,
    ): Promise<TResult | null> =>
      dataPost<TResult, TDataType>(
        url,
        'post',
        data,
        requireAuthentication,
        failSilently,
        isCancellable,
      ),
    [dataPost],
  );

  const abortQueries = useCallback(
    (startingUrl: string) => {
      if (queriesInProgress) {
        queriesInProgress
          ?.filter((q) => q.url.startsWith(startingUrl))
          .forEach((q, index) => {
            q.controller.abort();
            queriesInProgress.splice(index, 1); // remove the item from the array
          });
      }
    },
    [queriesInProgress],
  );

  return {
    post,
    patch,
    put,
    get,
    deleteMethod,
    abortQueries,
  };
};
