import { ProgramAllowedAddressType } from 'pages/bookRide/types';
import { AddressTypes, LocationDetailsType } from 'shared.types';

export const convertLocationTypes = (
  locations: ProgramAllowedAddressType[],
  addressType: AddressTypes,
): LocationDetailsType[] => {
  return locations.map((location) => ({
    addressType: addressType,
    address: location.address,
    city: location.city,
    state: location.state,
    postalCode: location.postalCode,
    countryCode: location.countryCode,
    airportCode: location.airportCode,
    latitude: location.location[0],
    longitude: location.location[1],
    googlePlacesId: location.googlePlacesId,
  }));
};
