import React, { ReactElement, useRef } from 'react';
// form
import { useFormContext } from 'react-hook-form';
// @mui
import { Stack, TextField, TextFieldProps } from '@mui/material';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
};

export default function RHFCodes({ name, ...other }: Props): ReactElement {
  const codesRef = useRef<HTMLDivElement>(null);

  const { setValue, register } = useFormContext();

  const onKeyUpHandler = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    const target = event.target as HTMLInputElement;
    const { maxLength, value, name: nameAttr } = target as HTMLInputElement;

    const fieldIndex = nameAttr.replace(name, '');

    const fieldIntIndex = Number(fieldIndex);

    if (event.code === 'Backspace') {
      const previousField: HTMLElement | null = document.querySelector(
        `input[name=${name}${fieldIntIndex - 1}]`,
      );
      if (value.length === 0 && fieldIntIndex > 0 && previousField !== null) {
        (previousField as HTMLElement).focus();
      }
    } else if (
      event.code !== 'ControlLeft' &&
      event.code !== 'ControlRight' &&
      event.code !== 'KeyV'
    ) {
      const nextField: HTMLElement | null = document.querySelector(
        `input[name=${name}${fieldIntIndex + 1}]`,
      );

      if (
        value.length >= maxLength &&
        fieldIntIndex < 6 &&
        nextField !== null
      ) {
        (nextField as HTMLElement).focus();
      }
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');

    const splitText = pastedText.split('');

    ['.', '.', '.', '.', '.', '.'].forEach((_, index) =>
      setValue(`${name}${index + 1}`, splitText[index]),
    );

    const indexToFocus = splitText.length >= 6 ? 6 : splitText.length + 1;
    const elementToFocus = document.querySelector(
      `[name=verificationCode${indexToFocus}]`,
    );
    if (indexToFocus) (elementToFocus as HTMLElement).focus();
  };

  const validations = {
    required: true,
  };

  return (
    <Stack direction="row" spacing={2} justifyContent="center" ref={codesRef}>
      {['.', '.', '.', '.', '.', '.'].map((_, index) => (
        <TextField
          autoFocus={index === 0}
          key={`${name}${index}`}
          {...register(`${name}${index + 1}`, { ...validations })}
          placeholder="-"
          onKeyUp={onKeyUpHandler}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>): void => {
            if (
              event.code.includes('Digit') ||
              event.code.includes('Numpad0') ||
              event.code.includes('Numpad1') ||
              event.code.includes('Numpad2') ||
              event.code.includes('Numpad3') ||
              event.code.includes('Numpad4') ||
              event.code.includes('Numpad5') ||
              event.code.includes('Numpad6') ||
              event.code.includes('Numpad7') ||
              event.code.includes('Numpad8') ||
              event.code.includes('Numpad9') ||
              event.code.includes('Backspace')
            ) {
              (event.target as HTMLInputElement).value = '';
            } else if (
              event.code !== 'ControlLeft' &&
              event.code !== 'ControlRight' &&
              event.code !== 'KeyV'
            ) {
              event.preventDefault();
            }
          }}
          InputProps={{
            sx: {
              width: { xs: 36, sm: 56 },
              height: { xs: 36, sm: 56 },
              '& input': { p: 0, textAlign: 'center' },
              'input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button':
                {
                  '-webkit-appearance': 'none',
                  margin: 0,
                  outline: 'none',
                },
            },
          }}
          inputProps={{
            maxLength: 1,
            type: 'number',
          }}
          {...other}
          onPaste={handlePaste}
        />
      ))}
    </Stack>
  );
}
