import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useState,
} from 'react';
import { Box, Dialog, Stack, SxProps, Typography } from '@mui/material';
import { FONT_FAMILY } from '../theme/typography';
import { COLORS } from '../theme/pallete';
import SvgIcon from '../components/shared/SvgIcon';

interface RawModalProps {
  title: string;
  titleStyles?: SxProps;
  dialogStyles?: SxProps;
}

interface ModalProps extends RawModalProps {
  isOpen: boolean;
  onClose: () => void;
  dataCy?: string;
}
const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
  title,
  isOpen = false,
  onClose,
  children,
  titleStyles,
  dialogStyles,
  dataCy,
}): ReactElement => (
  <Dialog
    maxWidth="sm"
    fullWidth
    open={isOpen}
    onClose={onClose}
    sx={{ ...dialogStyles }}
  >
    <Box
      sx={{
        padding: '24px',
      }}
      data-cy={dataCy}
    >
      <Stack direction="row" justifyContent="space-between" mb="24px">
        <Typography
          sx={{
            fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
            fontSize: '20px',
            color: COLORS.BLACK_DARK_2,
            ...titleStyles,
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            width: '26px',
            height: '26px',
            border: `1px solid ${COLORS.GREY_LIGHT_1}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px',
            ml: '15px',
            flexShrink: 0,
            cursor: 'pointer',
          }}
          onClick={onClose}
          data-cy="closeFareEstimateModal"
        >
          <SvgIcon
            src="/assets/icons/icon_x.svg"
            color="black"
            width="14px"
            height="14px"
          />
        </Box>
      </Stack>
      {children}
    </Box>
  </Dialog>
);

const useModal = (): {
  Modal: React.FC<PropsWithChildren<ModalProps>>; // Replace 'any' with proper component props type
  open: boolean;
  openModal: () => void;
  closeModal: () => void;
} => {
  const [open, setOpen] = useState(false);
  const openModal = useCallback(() => {
    setOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    Modal,
    open,
    openModal,
    closeModal,
  };
};

export default useModal;
