import { Box, Stack, Typography } from '@mui/material';
import { TYPOGRAPHY } from '../../../../theme/typography';
import EditButton from './EditButton';
import { COLORS } from '../../../../theme/pallete';
import SvgIcon from '../../../../components/shared/SvgIcon';
import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { BookRideFieldNames } from '../../types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProgramPassContext } from 'pages/ProgramPassContext';

const VehicleClassInfo = (): ReactElement => {
  const { watch } = useFormContext();

  const [vehicleClass, luggageAmount, passengersAmount] = watch([
    BookRideFieldNames.SERVICE_PROVIDER_VEHICLE_CLASS,
    BookRideFieldNames.LUGGAGE_AMOUNT,
    BookRideFieldNames.PASSENGERS_AMOUNT,
  ]);

  const navigate = useNavigate();
  const location = useLocation();

  const { programPass } = useProgramPassContext();

  return (
    <Box
      sx={{
        p: '20px',
      }}
      data-cy="service-details-summary"
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography
          sx={{
            ...TYPOGRAPHY.title4,
          }}
          data-cy="service-label"
        >
          Service
        </Typography>
        <EditButton
          onClick={(): void =>
            navigate(
              `${programPass ? `/pass/${programPass.id}/step2` : '/book-ride/step2'}` +
              location.search,
            )
          }
          dataCy="edit-service-button"
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between" mt="25px">
        <Box>
          <Typography
            sx={{
              ...TYPOGRAPHY.paragraph,
            }}
            data-cy="service-level-name"
          >
            {vehicleClass?.name}
          </Typography>
          <Typography
            sx={{
              ...TYPOGRAPHY.paragraph,
              color: COLORS.GREY_MEDIUM_1,
            }}
            data-cy="service-level-description"
          >
            {vehicleClass?.description}
          </Typography>
          <Stack direction="row" spacing="10px">
            <Stack direction="row" alignItems="center">
              <SvgIcon
                src="/assets/icons/icon_user.svg"
                width="18px"
                color={COLORS.GREY_MEDIUM_1}
                data-cy="passengers-icon"
              />
              <Typography
                sx={{
                  ...TYPOGRAPHY.tinyText,
                  color: COLORS.GREY_MEDIUM_1,
                  ml: '4px',
                }}
              >
                {passengersAmount}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <SvgIcon
                src="/assets/icons/icon_luggage.svg"
                width="18px"
                color={COLORS.GREY_MEDIUM_1}
                data-cy="luggage-icon"
              />
              <Typography
                sx={{
                  ...TYPOGRAPHY.tinyText,
                  color: COLORS.GREY_MEDIUM_1,
                  ml: '4px',
                }}
              >
                {luggageAmount}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <img
          src={vehicleClass?.image}
          alt="image"
          style={{
            width: '140px',
          }}
          data-cy="service-level-image"
        />
      </Stack>
    </Box>
  );
};

export default VehicleClassInfo;
